import React, { useState, useEffect, useMemo, useCallback, Suspense, useRef } from 'react';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Breadcrumbs from "components/common/Breadcrumps";
import { MuiThemedBox } from "components/common/MuiThemedComponents";
import SearchInputField from "components/common/SearchInputField";
import { useSelector, useDispatch } from "react-redux";
import TableComponent from 'components/common/TableComponent/TableComponent';
import {fetchSitesData, setDeviceDetails} from 'store/devices';
import { fetchPhoneData } from 'store/phones';
import { toast } from "react-toastify";
import { statusTooltipText } from '../link/LinkMonitoring';
import TableHeader from "components/common/TableHeader/TableHeader";
import FilterStatusAndService from "components/common/FilterStatusAndService";
import { filterStatusList, StatusFilterList } from '../devices/Main';
import { useLocation, useSearchParams } from 'react-router-dom';
import ColumnHeaderWithTooltip from 'components/common/TableComponent/ColumnHeaderWithTooltip';
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel';
import CopyTextCell from 'components/common/CopyTextCell/CopyTextCell';
import Timer from 'components/component/link/Timer';
import PhoneFlappingHistoryPopup from './PhoneFlappingHistoryPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useInterval from 'components/common/useInterval';
import GlobalExportButton from 'components/common/GlobalExportButton';

export default function Phones() {
    const user = useSelector((state) => state.user);
		// const navigate = useNavigate();
	const location = useLocation();
	const tenants = useSelector((state) => state.devices?.tenants)?.filter(i => (i.name !== 'Sangoma' && i.custom_fields?.endpoint_monitoring_enabled));
	const dispatch = useDispatch();
	const [tableColumns, setTableColumns] = useState([]);
	const [shouldHeaderLoader, setShouldHeaderLoader] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	// const [filter, setFilter] = useState([]);
	const filter = useRef([]);
	const [totalCount, setTotalCount] = useState(0)
    const [phoneStatus, setPhoneStatus] = useState({});
    const [searchTextState, setSearchTextState] = useState(null);
	const [highlightFilter, setHighLightFilter] = useState(false);
	const [statusFilter, setstatusFilter] = useState([]);
	const [tenantFilter, setTenantFilter] = useState([]);
	const [filterText, setFilterText] = useState(null);
	const [rows, setRows] = useState([]);
	const [sites, setSites] = useState([]);
	const [selectedSite, setSelectedSite] = useState(null);
	const [flappingHistoryPopupData, setFlappingHistoryPopupData] = useState({
		openPopup: false, 
		data: null
	});
	// eslint-disable-next-line no-unused-vars
	const [searchParemsCheck, setSearchParemsCheck] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const selectedFilterData = Object.fromEntries(searchParams.entries());
	const selectedFilter = searchParams.get("filter") || "";

	const [deviceStatusFilterValue, setdeviceStatusFilterValue]=useState([]);
	let filterStatusListData = user?.tenant?.name !== "Sangoma"? filterStatusList?.filter(data => data?.value !== 'decommissioning'): filterStatusList;
	const [paginationState, setPaginationState] = useState({
		offset: 1, limit: 20, sortField: 'mpd.event_time', sortOrder: 'desc'
	});
	const [tableRefreshKey, setTableRefreshKey] = useState(null);

	const setPaginationData = (newState) => {
		setPaginationState(prevState => ({ ...prevState, ...newState }))
	}

	const getFilterRequest = useCallback((fltr, pagination, searchText) => {
		let filterReq = 
		fltr ? fltr : searchTextState  ? [] : filter.current?.length > 0 ? { filter: searchText ? '' : JSON.stringify(filter.current) } : null;
		let searchTextValue = searchText == '' ? searchText : searchText ? searchText.trim() : searchTextState?.trim() || '';
		let req = pagination ? { ...pagination, 
			searchText: searchTextValue, ...filterReq } :
			{ ...paginationState, searchText: searchTextValue, ...filterReq}

		let request = { tenantId: user?.tenant?.id, tenantName: user.tenant?.name, ...req }

		return request || {}
	},[filter.current, paginationState, searchTextState, user.tenant?.id, user.tenant?.name])

const siteDataCall = async(id) =>{
	const res = await dispatch(fetchSitesData({tenantId: id? id : tenantFilter[0]?.value? tenantFilter[0]?.value : user?.tenant?.id, offset: 1, limit: 2000, sortField: 'name', sortOrder: 'asc'})).unwrap();
                let result = res?.data?.map(i => { 
                    return { value: i.id, label: i.custom_fields?.display_name ? i?.custom_fields?.display_name : i.name }; 
                });
                setSites(result);
}
	
	const getDevicesData = async (pagination, searchText, fltr, pageChange) => {
		setShouldHeaderLoader(!pageChange);
		setIsLoading(true);
		if (pagination) {
			setPaginationData({
				offset: pagination?.offset, limit: pagination?.limit, sortField: pagination?.sortField,
				sortOrder: pagination?.sortOrder
			})
		}
		let request = getFilterRequest(fltr, pagination, searchText)

		let deviceData = [];
		let data = [];
		try {
			if(location?.state?.deviceData == undefined){
				deviceData = await dispatch(fetchPhoneData(request)).unwrap();
				data = await deviceData?.data;
				setRows(data);

			setTotalCount(deviceData?.count);
			!pageChange && setPhoneStatus(deviceData.headerData || {});
			}
			if(location?.state?.deviceData !== undefined){
				setRows(location?.state?.deviceData);
				setTotalCount(location?.state?.deviceData.length);
				setFilterText(location?.state?.site_name);
				!pageChange && setPhoneStatus(location?.state?.headerData || {});
			}
		} catch (error) {
			toast.error("Oops! Something went wrong");
			console.error("Error fetching tenants data:", error);
		} finally {
			setTimeout(() => {
				setIsLoading(false);
			}, 100);
		}
	}


	const columns= [
		{
			cell: (row) => { return <>{row.tenant_name} </> },
			name: "Tenant",
			title: "Tenant",
			sortable: location?.state?.siteId ? false :true,
			grow:"0 !important",
			minWidth: 170,
			isEnabled: true,
			sortField: "ndd.tenant_name"
		},
		{
			name: "Site Display Name",
			title: "Site Display Name",
			wrap: true,
			weight: 2,
			minWidth: 200,
			sortable: false,
			sortField: 'site.display_name',
			cell: (row) => <CopyTextCell showWithTooltip={true} text = {row.site_display_name || '--'}/>,
			isEnabled:true,
		},

		{
			name: "Site Name",
			title: "Site Name",
			wrap: true,
			weight: 2,
			minWidth: 200,
			sortable: false,
			sortField: 'site.name',
			cell: (row) => <CopyTextCell showWithTooltip={true} text = {row.site_name || '--'}/>,
			isEnabled:false,
		},
		{
			name: "Site Group",
			title: "Site Group",
			wrap: true,
			weight: 2,
			minWidth: 150,
			sortable: false,
			cell: (row) => row.site_group_name ? <CopyTextCell showWithTooltip={true} text={row.site_group_name} /> : '--',
			isEnabled: false,
		},
		{
			sortField: "ndd.name",
			name: "Extension Name",
			title: "Extension Name",
			dataType: "String",
			sortable: location?.state?.siteId ? false :true,
			minWidth: 200,
			weight: 2,
			description: "",
			cell: (row) => 
				<CopyTextCell showWithTooltip={true} text = {row?.name}/>,
			isEnabled:true,
		},
		{
			name: "Extension Number",
			title: "Extension Number",
			wrap: true,
			weight: 1,
			sortable:  location?.state?.siteId ? false :true,
			sortField: 'pi.extension_number::TEXT',
            minWidth: 200,
			cell: (row) => <CopyTextCell text = {row.extension_number}/>,		
			isEnabled:true,
		},
		{
						name: "Call Quality",
						title: "Call Quality",
						sortable: false,
						wrap: true,
						width: 130,
						cell: (row) => {
							const color = row?.score >= 4 ? 'green' : row?.score < 4 && row?.score >= 3.1 ? 'orange' : 'red';
							const text =  row?.score >= 4 ? 'Good' : row?.score < 4 && row?.score >= 3.1 ? 'Fair' : 'Bad';
							return (
								<>
								{row?.score ? <div onClick={(e)=>{
									e?.stopPropagation();
									handleRowClicked(row)

								}} style={{alignItems: 'center', display: 'flex'}}>
									<FontAwesomeIcon icon="fa-solid fa-check" color ={color} style={{marginRight: '7px', height: '20px'}}/> 
									<TooltipLabel text={text}/>
								</div> : '--'}
								</>
							)
						},
						isEnabled: process.env.REACT_APP_VOICE_SCORE_ENABLED
		
		},
		{
			name: "Phone Brand",
			title: "Phone Brand",
			wrap: true,
			minWidth: 150,
			weight: 1,
			sortField: 'serial',
			cell: (row) => {
			return <TooltipLabel text= {row?.manufacturer|| '--'}/>},		
			isEnabled:true,
		},
		{
			name: "Phone Model",
			title: "Phone Model",
			wrap: true,
			minWidth: 150,
			weight: 1,
			sortField: 'serial',
			cell: (row) => {
			return <TooltipLabel text= {row?.model|| '--'}/>},		
			isEnabled:true,
		},
		{
			name: "Firmware",
			title: "Firmware",
			wrap: true,
			weight: 1,
            minWidth: 200,
			sortField: 'serial',
			cell: (row) => <TooltipLabel text= {row?.mac || '--'}/>,		
			isEnabled:true,
		},
		{
			selector: 'status',
			cell: row => <div className={
				row?.inv_status?.toLowerCase() === "active" ||
					row?.inv_status?.toLowerCase() === "up"
					? "status status_active"
					: row?.inv_status?.toLowerCase() === "offline" ||
						row?.inv_status?.toLowerCase() === "decommissioning"
						? "status status_down"
						: "status status_inactive"
			}><TooltipLabel text = {row.inv_status}/></div>,
			name: 'state',
			title: 'state',
			sortField: 'inv_status',
			wrap: true,
			isEnabled:false,
			sortable:  location?.state?.siteId ? false :true,
		},
		{
			selector: (row) => row?.phone_status ? row?.phone_status : "--",
			cell: row => 
				<div data-tag="allowRowEvents" className="flex">
					<span data-tag={"allowRowEvents"} className={
						row?.phone_status?.toLowerCase() === "up"
							? "status status_active"
							: row?.phone_status?.toLowerCase() === "down"
								? "status status_down"
								: row?.phone_status?.toLowerCase() === "degraded"
									? "status status_degraded" : row?.phone_status?.toLowerCase() === "maint" ? "status status_inactive" : row?.connection_status?.toLowerCase() === "n/a" ? "status status_na" : ""
					} >{row?.phone_status ? row?.phone_status : "--"}</span>

				</div>,
			name: <ColumnHeaderWithTooltip tooltipText={'Indicates the current operational condition of a device within a network.'} columnName={'Status'} />,
			title: 'Status',
			sortable: false,
			wrap: true,
			sortField: 'phone_status',
			minWidth: 150,
			isEnabled:true,
		},
        {
			cell: (row) => {
				return row?.event_time ? <Timer targetDate = {row?.event_time}/> : '--';
			},
			name: "Since",
			title: "Since",
			sortable:  location?.state?.siteId ? false :true,
			grow:"0 !important",
			// minWidth: 200,
			isEnabled: true,
			sortField: "mpd.event_time"
		}
	]

	useEffect(() => {
		if (!user.tenant) {
			return;
		} else {
			dispatch(setDeviceDetails({ deviceName: undefined, serviceName: undefined, deviceData: undefined }));
		}
		// setFilter(() => []);
		
		if(Object.keys(selectedFilterData).length === 0){
			setstatusFilter([]);
			filter.current = [];
			setTenantFilter([]);
			setdeviceStatusFilterValue([]);
			setHighLightFilter(false)
		}
		setSelectedSite([]);
		setSites([]);
		setSearchTextState('')
		setFilterText(null);
		let filterReq =[...filter.current];
		
		if (selectedFilterData.phone_status) {
			filterReq = filterReq.filter(item => item.key !== "phone_status");
			let statusValue = JSON.parse(selectedFilterData.phone_status);
			setdeviceStatusFilterValue(statusValue.map(val => ({ value: val, label: val.toLowerCase() })));
			filterReq.push({ key: 'phone_status', value: statusValue });
		}
		
		if (user?.tenant?.id) {
			siteDataCall();
		}
		let filterCols = columns?.filter((obj) => user?.tenant?.name !== "Sangoma" ? obj?.name !== "Tenant" : obj);
		setTableRefreshKey(new Date().getTime())
		setTableColumns(filterCols);
		const { state } = location || {};
		if (state && Object?.keys(state)?.length) {
			const  {status, stateValue } = state;
			let locationFilters = [];

			if (status) {
				let statusValue = status==="n/a" ||  status==="N/A" ? "N/A": status?.toLowerCase()
					setdeviceStatusFilterValue([{ value: statusValue, label: status==="n/a" ||  status==="N/A" ? "others" :status }]);
					locationFilters = [
						...locationFilters,
						{ key: 'phone_status', value: [statusValue] },
					];
			}

			if(stateValue){
					setstatusFilter([{ value: stateValue?.toLowerCase(), label: stateValue }]);
					locationFilters = [
						...locationFilters,
						{ key: 'inv_status', value: [stateValue?.toLowerCase()] },
					];
			}

			if (locationFilters && locationFilters?.length > 0) {

				// setFilter((val) => {
				// 	let filterValue = [...val, ...locationFilters]
				// 	return filterValue
				// })
				filter.current  = [...filter.current, ...locationFilters]
			}
			getDevicesData(null, null, { filter: JSON.stringify(locationFilters) })
		} else{
			let filterData = filterReq.length > 0? { filter: JSON.stringify(filterReq) }: null
			getDevicesData(null, '', filterData)
		}

	}, [user?.tenant, location]);

	const phoneDetails = useMemo(() => {
		const getdetail = (id) => phoneStatus && (phoneStatus?.length > 0 && phoneStatus?.find(item => item?.phone_status === id)) || phoneStatus[id];
		const getPercentage = (id) => {return  phoneStatus[id] ? (phoneStatus[id]*100/totalCount).toFixed(2) : 0}
		const isEnabled = (val) => {
			return deviceStatusFilterValue?.length === 0 || deviceStatusFilterValue?.length >= 0 && deviceStatusFilterValue?.filter(i => i.value === val).length > 0 || deviceStatusFilterValue?.value === val
		}
		
		return [
		{
			id: 'up',
			text: 'Up',
			count: Array.isArray(phoneStatus)? Number(getdetail('up')?.total_count) : getdetail('up'),
			percentage: getdetail('up')?.percentage || getPercentage('up'),
			className: 'link__monitor__card__up',
			isEnabled: isEnabled('up'),
			disableClick : location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
			tooltipText:statusTooltipText['up']
		},
		{
			id: 'down',
			text: 'Down',
			count: Array.isArray(phoneStatus) ? Number(getdetail('down')?.total_count) : getdetail('down'),
			percentage:getdetail('down')?.percentage || getPercentage('down'),
			className: 'link__monitor__card__down',
			isEnabled: isEnabled('down'),
			disableClick : location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
			tooltipText:statusTooltipText['down']
	
		},
		
		{
			id: 'maint',
			text: 'Maint',
			count:  Array.isArray(phoneStatus) ? Number(getdetail('maint')?.total_count) : getdetail('maint'),
			percentage:  getdetail('maint')?.percentage || 0,
			className: 'link__monitor__card__maintenance',
			isEnabled: user?.tenant?.name === 'Sangoma' &&  isEnabled('maint') ? true: false && isEnabled('maint'),
			disableClick : location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
			tooltipText:statusTooltipText['maint']
	
		},
		{
			id: 'others',
			text: 'Others',
			count: Array.isArray(phoneStatus) ?  Number(getdetail('others')?.total_count) : getdetail('others'),
			percentage: getdetail('others')?.percentage || getPercentage('others')|| 0,
			className: 'link__monitor__card__na',
			isEnabled: isEnabled('others'),
			disableClick : location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
			tooltipText:statusTooltipText['N/A']
		},
		]
		}, [user?.tenant, phoneStatus]);

	const onPageChange = (pagination) => {
			getDevicesData(pagination, null, null, true);
	}

	const handleSearch = async (e) => {
		setTableRefreshKey(new Date().getTime())
		if (e.target.value) {
			setSearchTextState(e.target.value);
			await getDevicesData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder} , e.target.value, null, true);
		}
		else{
			setSearchTextState(null)
			await getDevicesData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder} , null, null, true)
		}
	};

	// function replaceHistory() {
	// 	navigate(location.pathname, { state: null })
	// }

	const onApplyFilter = (val, fltrValue) => {
		if(searchTextState) {		
			setSearchTextState(null)
		}
		// if (location.state) {
		// 	replaceHistory()
		// }
		setTableRefreshKey(new Date().getTime())
		let cloneFilter = [...filter.current]

		switch(val){		
			case 'Tenant...':{
				let tenantDatafilter = fltrValue ? fltrValue : tenantFilter?.map(i => { return i.value });
				cloneFilter = cloneFilter?.filter(i => i.key !== 'tenant_id');
				tenantDatafilter?.length > 0 && cloneFilter.push({ value: tenantDatafilter, key: 'tenant_id' });
				break;
			}
			case 'State...': {
				let stfilter = fltrValue ? fltrValue : statusFilter?.map(i => { return i.value.toLowerCase() });

				cloneFilter = cloneFilter?.filter(i => i.key !== 'inv_status');		
				stfilter?.length > 0 && cloneFilter.push({ value: stfilter, key: 'inv_status' }); break;
			}

			case 'Endpoint Status...': {
				let phonefilter = fltrValue ? fltrValue : deviceStatusFilterValue?.map(i => { return i.value.toLowerCase() });
				cloneFilter = cloneFilter?.filter(i => i.key !== 'phone_status');
				phonefilter?.length > 0 && cloneFilter.push({ value: phonefilter, key: 'phone_status' }); 
				const newParams = new URLSearchParams(searchParams);
				phonefilter?.length > 0 
                ? newParams.set('phone_status', JSON.stringify(phonefilter))
                : newParams.delete('phone_status');

				setSearchParams(newParams);
				break;
			}

			case 'Site select...': {
				let phonefilter = fltrValue ? fltrValue : selectedSite?.map(i => { return i.value });
				cloneFilter = cloneFilter?.filter(i => i.key !== 'mpd.site_id');
				phonefilter?.length > 0 && cloneFilter.push({ value: phonefilter, key: 'mpd.site_id' }); break;
			}
			default:
				break;
		
	}
		// setFilter(cloneFilter);
		filter.current = cloneFilter;
		let filterReq = cloneFilter?.length > 0 ? {filter: JSON.stringify(cloneFilter)} :  {filter: null}
		if (val !== "Endpoint Status...")
			getDevicesData({ offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder }, '', filterReq);
	}

	const handleTenantFilter = async (e, action) => {
		setTenantFilter(e);
		setSites([])
		if(action === 'clear'){
			onApplyFilter('Tenant...', e);
		}
	};

	// const handleStatusFilter = async (e, action) => {
	// 	setstatusFilter(e);
	// 	if(action === 'clear'){
	// 		onApplyFilter('State...', e);
	// 	}
	// };


	
	const handleDeviceStatusFilter = useCallback(async (e, action) => {
		setHighLightFilter(false)
		setdeviceStatusFilterValue(e);
		if(action === 'clear'){
			setSearchParemsCheck(true);
			setSearchParams(prevParams => {
				const newParams = new URLSearchParams(prevParams);
				if (newParams.has("phone_status")) {
					newParams.delete("phone_status");
					return newParams;
				}
			});
			onApplyFilter('Endpoint Status...', e);
		}
	}, []);

	const handleCardClick = (e,item) => {
		if(searchTextState) {
			setSearchTextState(null)
		}
		setHighLightFilter(true)
		setTableRefreshKey(new Date().getTime())
		setdeviceStatusFilterValue([{ value: item?.id, label: item?.text }]);
					let filterVar = [...filter.current];
					filterVar = filterVar?.filter(i => i.key !== 'phone_status');
					let locationFilters = [
						...filterVar,
						{ key: 'phone_status', value: [item?.id] }
					];
					// setFilter(locationFilters)
					filter.current = locationFilters;
			//getDevicesData({ offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder }, '', { filter: locationFilters ? JSON.stringify(locationFilters) : null });
					setSearchParemsCheck(false);
					setSearchParams(prevParams => {
						const newParams = new URLSearchParams(prevParams);
						newParams.set("phone_status", JSON.stringify([item.id]));
						return newParams;
					});
	}

	const onSiteChange = (val, action) => {
        setSelectedSite(val)
		setHighLightFilter(false)
		if(action === 'clear'){
			onApplyFilter('Site select...', val);
		}
    }

	const filterConfigurations = useMemo(() => [
		// {
		// 	key: "statusFilter",
		// 	isEnabled: true,
		// 	placeholder: "State...",
		// 	options:filterStatusListData,
		// 	value: statusFilter,
		// 	handler: handleStatusFilter,
		// 	disableApply: (location?.state == null || location?.state?.serviceName == '' || location?.state?.serviceName == 'phone_status' || location?.state?.stateValue) && !isLoading && statusFilter.length > 0 ? false : true,
		// },
		{
			key: "tenantFilter",
			isEnabled: user?.tenant?.name === "Sangoma",
			placeholder: "Tenant...",
			options: tenants?.map(i => {return {value: i.id, label: i.name}}),
			value: tenantFilter,
			handler: handleTenantFilter,
			disableApply: (location?.state == null || location?.state?.serviceName == '' || location?.state?.serviceName == 'phone_status' || location?.state?.stateValue) && !isLoading && tenantFilter.length > 0 ? false : true,
		},
		{
			key: "deviceStatusFilter",
			isEnabled: true,
			placeholder: "Endpoint Status...",
			options: user?.tenant?.name !== "Sangoma"
			? StatusFilterList?.filter((item) => item.value !== "maint" &&  item.value !== "degraded")
			: StatusFilterList?.filter((item) => item.value !== "degraded"),
			value: deviceStatusFilterValue,
			highlightFilter : highlightFilter,
			handler: handleDeviceStatusFilter,
			disableApply: (location?.state == null || location?.state?.serviceName == '' || location?.state?.serviceName == 'phone_status' || location?.state?.stateValue) && !isLoading && deviceStatusFilterValue.length > 0 ? false : true,
		},
		{
			key: "siteSelectFilter",
			isEnabled: user?.tenant?.name !== "Sangoma",
			placeholder: "Site select...",
			options: sites,
			value: selectedSite,
			handler: onSiteChange,
			disableApply: (location?.state == null || location?.state?.serviceName == '' || location?.state?.serviceName == 'phone_status' || location?.state?.stateValue) && !isLoading && selectedSite?.length > 0 ? false : true,
		},
		
	], [filterStatusListData, statusFilter, location?.state, isLoading, user?.tenant?.name, tenants, tenantFilter, deviceStatusFilterValue]);

	const handleRowClicked = (row) => {
		setFlappingHistoryPopupData({ data: row, openPopup: true });
	};
	useInterval(getDevicesData, 60000);	
  return (
	<MuiThemedBox className="main_inner_wrap">
		<Box className="justify-between">
			<Breadcrumbs text={`Endpoints (${totalCount})`} filterValue={filterText} onRemoveFilter={() => setFilterText(null)} />
		</Box>

		<Grid container className="flex_wrap justify-between">
			<Grid item md={3} className="filter_wrap">
			<SearchInputField debounceProp={user?.tenant?.id} handleClick={handleSearch} value={searchTextState} />
			</Grid>
			<Grid item md={9} className="flex flex_Wrap" gap={"9px"} >
				<Box sx={{ display: 'flex', width: "100%", alignItems: "center", justifyContent: 'flex-end' }}>

					<FilterStatusAndService
						style={{ width: "86%", justifyContent: "right", marginRight: "16px" }}
						filterConfigurations={filterConfigurations}
						onApplyClick={onApplyFilter}
						totalFilterCount={3}
						// highlightFilter={highlightFilter}
						// fromDeviceScreen={true}
					/>
					<GlobalExportButton
						enableFilteredDataExportButton={filter.current?.length > 0 || searchTextState?.length > 0}
						exportApiRequest={
							{
								tenantId: user.tenant?.name !== "Sangoma" ? user.tenant?.id : null,
								filetype: 'csv',
								dataOf: 'phones',
								tenantName: user?.tenant?.name,
								...getFilterRequest({
									offset: 1,
									limit: totalCount,
									sortField: paginationState?.sortField,
									sortOrder: paginationState?.sortOrder,
									filter: JSON.stringify(filter.current)
								})


							}
						}
						heading={`Endpoints`}
						text={"Are you sure you want to download endpoint list ?"}
						fileName={"endpoints"}
					/>
				</Box>
			</Grid>

		</Grid>
		<TableHeader tableHeaderCardDetails={phoneDetails} isLoading={isLoading && shouldHeaderLoader} md={user?.tenant?.name === 'Sangoma' ? 3 : 4}
			lg={user?.tenant?.name === 'Sangoma' ? 3 : 4} handleCardClick={handleCardClick} selectedFilter = {selectedFilter}/>

		<Box>
			<TableComponent className={"device_table rdt_table_wrap hover_table table_with_header"}
				columns={tableColumns} rows={rows} key={tableRefreshKey}
				customStyles={customStyles}
				isLoading={isLoading}
				onPageChange={onPageChange}
				totalRows={totalCount}
				onRowClicked={handleRowClicked}
				defaultSortOrder={'desc'}
				defaultSortField={'mpd.event_time'}/>
		</Box>

		<Suspense fallback={<div></div>}>
			{flappingHistoryPopupData?.openPopup ? (
				<PhoneFlappingHistoryPopup
					openPopup={flappingHistoryPopupData?.openPopup}
					data={flappingHistoryPopupData?.data}
					handleClosePopup={() => {
						setFlappingHistoryPopupData({ data: null, openPopup: false })
					}}
				/>
			) : null}
		</Suspense>


	</MuiThemedBox>
  )
}


const customStyles = {
	tableWrapper: {
		height: "389px",
		color: "#000",
	},
};