import { Box } from "@mui/material";
import Grid from '@mui/material/Grid';
import { CustomDropdownIndicator, MuiThemedBox, MuiTypography } from "components/common/MuiThemedComponents";
import NetopsHeaderTitle from "components/common/NetopsHeaderTitle";
import NetopsStatusCards, { colorConstants } from "components/common/NetopsStatusCards";
import useInterval from "components/common/useInterval";
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useCanAccess } from "services/canACLAccess";
import { fetchDashboardData, fetchLinkMonitorStatusData } from "store/devices";
import { statusTooltipText } from "../link/LinkMonitoring";
import { servicenowHeaderCardDetails, ticketDetails } from "../tickets/ticketsConstants";
import NetopsDashboardChartPreference from "./NetopsDashboardChartPreference";
import NetopsDashboardChart from "./NetopsDashboardChart";
import NetopsMonitoringStatusGraph from "./NetopsMonitoringStatusGraph";
import NetopsDashboardCountCard from "./NetopsDashboardCountCard";
import NetopsDashboardTitle from "./NetopsDashboardTitle";
import NetopsButton from "components/common/NetopsButton";
import {ReactComponent as NetopsLinkmonitoringIcon} from "../../../netopsStyles/assets/linkmonitor-btn-icon.svg"
import NetopsReactSelect from "components/common/NetopsReactSelect";
import { fetchDashboardTicketStatusData, fetchTicketingSystems } from "store/tickets";

const ticketTenantIds = process.env.REACT_APP_TICKET_TENANT_IDS

export const screenResolution = () => {
	if (window?.innerWidth >= 1920) {
		return "desktop";
	} else if (window?.innerWidth >= 1280) {
		return "laptop";
	} else if (window?.innerWidth >= 768) {
		return "tablet";
	} else {
		return "mobile";
	}
}



const NetopsDashboard = () => {
	const canAccess = useCanAccess();	
	const user = useSelector((state) => state.user);
	const phoneEnabled = useMemo(()=> user?.isPhoneEnabled && canAccess( 'read', 'endpoints'), [user?.isPhoneEnabled]);
	const mspEnabled = useMemo(()=> user?.isMSPEnabled , [user?.isMSPEnabled]);
	const gatewayEnabled = useMemo(()=> user?.isGatewayEnabled && canAccess( 'read', 'gateways'), [user?.isGatewayEnabled]);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const serviceNameMapppings = {
		velocloud: 'sdwan',
		meraki: 'wifi',
		wug: 'circuits',
		cradlepoint: 'cradlepoint',
		fortinet: 'fortinet'
	}
	const [data, setData] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [service, setService] = useState({
		value: '',
		label: 'All',
	});

	const [chartMonitoringViewPreference, setChartMonitoringViewPreference] = useState("donut");
	const [chartInventoryViewPreference, setChartInventoryViewPreference] = useState("donut");
	const [inventoryGraphData, setInventoryGraphData] = useState();
	const [endPointBrandData, setEndPointBrandData] = useState();
	const [phoneStatus, setPhoneStatus] = useState();
	const [gatewayStatus,setGatewayStatus] = useState();
	const [gatewayState, setGatewayState] = useState();
	const [gatewayModel, setGatewayModel] = useState({});
	const [unmappedDeviceGraphData, setUnmappedDeviceGraphData] = useState();
	const [phoneState, setPhoneState] = useState([]);
	const [linkStatusResponse, setLinkStatusResponse] = useState();
	const [ticketStatusResponse, setTicketStatusResponse] = useState([]);
	const [ticketingSystemOptionsList, setTicketingSystemOptionsList] = useState([]);
	const [ticketingSystemFilter, setTicketingSystemFilter] = useState([]);
	const [isFetchingTicketStatus, setIsFetchingTicketStatus] = useState(false);
	const [disableApiCallForFetchingTicketingSystems, setDisableApiCallForFetchingTicketingSystems] = useState(false);

	const dashboardApiCall = () => {
		return dispatch(fetchDashboardData({ service: service.value, tenantId: user?.tenant?.id, tenantName: user?.tenant?.name }));
	}

	const linkStatusApiCall = () => {
		return dispatch(
			fetchLinkMonitorStatusData({
				tenantId: user?.tenant?.id,
				tenantName: user?.tenant?.name,
				params: {
					filter: (service?.value && service?.label !== 'All') ?
						JSON.stringify([{ "key": "monitor_device_data.service", "value": [service?.value] }]) : null,
					circuitFilter : true
				},
			})
		)
	}

	const handleServiceChange = useCallback((newValue) => {
		setService(newValue);
	}, [data])


	const handleRedirectToMonitoringStatus = useCallback((e, data) => {
		
		if (canAccess('read', 'link-monitoring')) {
			navigate("/nf-status/link-monitoring",
				{
					state: {
						serviceName: service?.label === "All" ? null : service?.value,
						status: data?.id,
						circuitFilter: true,
					}
				}
			)
		}
	}, [canAccess, navigate, service?.label, service?.value])

	const handleRedirectToEndPointStatus = useCallback((e, data) => {
		
		navigate("/nf-status/phones",
			{
				state: {
					serviceName: 'phone_status',
					status: data?.id,
				}
			}
		)
	
}, [navigate, service?.label, service?.value])

const handleRedirectToGatewayStatus = useCallback((e, data) => {
		
	navigate("/nf-status/gateway",
		{
			state: {
				serviceName: 'connection_status',
				status: data?.id,
			}
		}
	)

}, [navigate, service?.label, service?.value])

	const handleRedirectToTicket = useCallback((data) => {
		canAccess('read', 'tickets') && navigate('/nf-status/tickets', {
			state: {
				status: data?.id,
				dashboardTicketingSystem: ticketingSystemFilter?.[0]?.value?.toLowerCase()

			},
		})
	}, [navigate, ticketingSystemFilter])


	const fetchData = async () => {
		setIsLoading(true);
		try {
			const results = await Promise.allSettled([dashboardApiCall(), linkStatusApiCall(), !disableApiCallForFetchingTicketingSystems && getTicketingSystems()]);

			if(disableApiCallForFetchingTicketingSystems) {
				setDisableApiCallForFetchingTicketingSystems(false)
			}
	
			const dashboardResult = results[0];
			if (dashboardResult?.status === "fulfilled") {
				const dashboardData = dashboardResult.value?.payload;
				setData(dashboardData);
				setPhoneStatus(dashboardData?.phoneStatus?.filter(item => user?.tenant?.name === 'Sangoma' || item.phone_status !== 'maint') || {});
				setPhoneState(dashboardData?.phoneState || []);
				setGatewayStatus(dashboardData?.gateway_status || {});
				setGatewayState(dashboardData?.gateway_state || {});
				setGatewayModel(dashboardData?.gateway_tag || {})
				let inventoryGraphDetails = {
					sites: dashboardData?.total_sites,
				};
				let endPointBrandGraphData  = {
					sangoma: dashboardData?.phoneBrand[0]?.sangoma_count,
					yealink: dashboardData?.phoneBrand[0]?.yealink_count,
					polycom: dashboardData?.phoneBrand[0]?.polycom,
					others: dashboardData?.phoneBrand[0]?.other
				}

				if(mspEnabled){
					inventoryGraphDetails.links = dashboardData?.total_links;
					inventoryGraphDetails.devices = dashboardData?.total_devices;
				}
				if(phoneEnabled){
					inventoryGraphDetails.phones = dashboardData?.total_phones;
				}
				if(gatewayEnabled){
					inventoryGraphDetails.gateways = dashboardData?.total_gateway_devices;
				}

				if (user?.tenant?.name === "Sangoma") {
					inventoryGraphDetails.tenants = dashboardData?.total_tenants;
				}
				setInventoryGraphData(inventoryGraphDetails)
				setEndPointBrandData(endPointBrandGraphData);

				if (dashboardData?.unmap_service_count) {
					const serviceCount = {};
					Object.keys(dashboardData.unmap_service_count).forEach((key) => {
						serviceCount[serviceNameMapppings[key]] = dashboardData.unmap_service_count[key];
					});
					setUnmappedDeviceGraphData(serviceCount);
				}
			} else {
				console.error("Dashboard API call failed:", dashboardResult.reason);
			}
	
			const linkStatusResult = results[1];
			if (linkStatusResult?.status === "fulfilled") {
				let linkStatusData = linkStatusResult.value?.payload;
				if (user?.tenant?.name !== "Sangoma") {
					linkStatusData = linkStatusData?.filter((item) => item.link_status !== "maint");
				}
				setLinkStatusResponse(linkStatusData);
			} else {
				console.error("Link Status API call failed:", linkStatusResult.reason);
			}
	
			const ticketingSystemsResult = results[2];
			if (ticketingSystemsResult.status !== "fulfilled") {
				console.error("Ticketing systems API call failed:", ticketingSystemsResult.reason);
			}
	
		} catch (err) {
			toast.error("Oops! Something went wrong");
			console.error("Unexpected error:", err);
		} finally {
			setIsLoading(false);
		}
	};
	



	const tenantOptions = [
		{
			value: '',
			label: 'All',
		},
		{
			value: 'wug',
			label: 'Circuits',
		},
		{
			value: 'cradlepoint',
			label: 'Cradlepoint',
		},

		{
			value: 'fortinet',
			label: 'Fortinet',
		},
		{
			value: 'velocloud',
			label: 'SDWAN',
		},
		{
			value: 'meraki',
			label: 'WiFi',
		},


	]



	const cardDetails = useMemo(() => {
		return [
			{
				id: 1,
				isEnabled: user.tenant?.name === "Sangoma",
				cardTitle: "Total Tenants",
				count: data?.total_tenants

			},
			{
				id: 2,
				isEnabled: true,
				cardTitle: "Total Sites",
				count: data?.total_sites

			},
			{
				id: 3,
				isEnabled: mspEnabled,
				cardTitle: "Total Devices",
				count: data?.total_devices

			},
			{
				id: 4,
				isEnabled: mspEnabled,
				cardTitle: "Total Monitoring Links",
				count: data?.total_links

			},
			{
				id:5,
				isEnabled: phoneEnabled,
				cardTitle: "Total Endpoints",
				count: data?.total_phones
			},
			{
				id: 4,
				isEnabled: gatewayEnabled,
				cardTitle: "Total Gateways",
				count: data?.total_gateway_devices

			},
		]
	}, [data, user?.tenant?.name])

	const monitoringStatusChartDetails = useMemo(() => {
		return [
			{
				id: 3,
				text: 'Circuits',
				statusData: data?.service_link_status?.wug,
				navUrl: "/nf-status/link-monitoring",
				isEnabled: true,
				redirectUrl: 'https://liveview.netfortris.com/',
				noTextData: <a  className="no__data__text"  href="https://www.sangoma.com/managed-services/" target="_blank" rel="noreferrer">
						Service not subscribed</a>,
				footer: "https://sangoma.com/products-and-solutions/managed-network-and-connectivity/",
			},
			{
				id: 4,
				text: 'Cradlepoint',
				redirectUrl: 'https://www.cradlepointecm.com',
				statusData: data?.service_link_status?.cradlepoint,
				navUrl: "/nf-status/link-monitoring",
				isEnabled: true,
				noTextData:<a className="no__data__text" href="https://www.sangoma.com/managed-services/managed-internet/managed-wireless-internet/" target="_blank" rel="noreferrer">
						Service not subscribed</a>,
				footer: "https://sangoma.com/products-and-solutions/managed-network-and-connectivity/",
			},
			{
				id: 5,
				text: 'Fortinet',
				statusData: data?.service_link_status?.fortinet,
				navUrl: "/nf-status/link-monitoring",
				isEnabled: true,
				noTextData:
					<a  className="no__data__text" href="https://www.sangoma.com/managed-services/managed-security/" target="_blank" rel="noreferrer">
						Service not subscribed</a>,
				footer: "https://sangoma.com/products-and-solutions/managed-network-and-connectivity/",
			},
			{
				id: 1,
				text: 'SDWAN',
				redirectUrl: 'https://vco4.netfortris.com/',
				statusData: data?.service_link_status?.velocloud,
				navUrl: "/nf-status/link-monitoring",
				isEnabled: true,
				noTextData:
					<a className="no__data__text" href="https://www.sangoma.com/managed-services/managed-sd-wan/" target="_blank" rel="noreferrer">
						Service not subscribed</a>,
				footer: "https://sangoma.com/products-and-solutions/managed-network-and-connectivity/",
				// sdwanDevicestatus:data?.service_link_status?.velocloud_device_status,
				sdwanDevicestatus: data?.service_link_status?.sdwanDeviceStatus,
				sdwanDeviceGraphId: 'sdwan_device_graph'
			},
			{
				id: 2,
				text: 'WiFi',
				redirectUrl: 'https://api.meraki.com',
				statusData: data?.service_link_status?.meraki,
				navUrl: "/nf-status/link-monitoring",
				isEnabled: true,
				noTextData: <a className="no__data__text" href="https://www.sangoma.com/managed-services/managed-internet/managed-wi-fi-access-points/" target="_blank" rel="noreferrer">
						Service not subscribed</a>,
				footer: "https://sangoma.com/products-and-solutions/managed-network-and-connectivity/",
			},
		]
	}, [data, isLoading])

	const inventoryChartDetails = useMemo(() => {
		return [
			{
				id: 1,
				text: 'Inventory data',
				statusData: inventoryGraphData,
				isEnabled: true,
			},
			{
				id: 2,
				text: 'Devices Status',
				statusData: data?.device_status,
				isEnabled: mspEnabled,
			},
			{
				id: 3,
				text: 'Monitoring Links',
				statusData: data?.link_status,
				isEnabled: mspEnabled,
			},
			{
				id: 4,
				text: 'Unmapped Devices',
				statusData: unmappedDeviceGraphData,
				isEnabled: user?.userRole !== 'user' && mspEnabled,
			},
			{
				id: 5,
				text: 'Endpoint State',
				statusData: phoneState?.reduce((acc, item) => {
					acc[item.inv_status] = parseInt(item.state_count, 10);
					return acc;
				}, {}),
				isEnabled: phoneEnabled,
			},
			{
				id: 5,
				text: 'Endpoint brands',
				statusData: endPointBrandData,
				isEnabled: user?.tenant?.name !== 'Sangoma' && phoneEnabled,
			},
			{
				id: 6,
				text: 'Gateway State',
				statusData: gatewayState,
				isEnabled: gatewayEnabled,
			},
			{
				id: 7,
				text: 'CFG Group',
				statusData: gatewayModel,
				isEnabled: user?.tenant?.name !== 'Sangoma' && gatewayEnabled,
			}
		]
	}, [inventoryGraphData, phoneState, gatewayEnabled,gatewayModel, gatewayState ,data?.device_status, data?.link_status, data?.total_devices, mspEnabled, unmappedDeviceGraphData, user?.userRole, phoneEnabled, endPointBrandData])

	const monitoringStatusDetails = useMemo(() => {
		const getdetail = (id) => linkStatusResponse && linkStatusResponse?.length > 0 && linkStatusResponse?.find(item => item?.link_status === id)
		return [
			{
				id: 'up',
				text: 'Up',
				count: getdetail('up')?.total_count || 0,
				percentage: getdetail('up')?.percentage || 0,
				className: 'link__monitor__card__up',
				isEnabled: true,
				tooltipText: statusTooltipText['up'],
				color: colorConstants["GREEN"]

			},
			{
				id: 'down',
				text: 'Down',
				count: getdetail('down')?.total_count || 0,
				percentage: getdetail('down')?.percentage || 0,
				className: 'link__monitor__card__down',
				isEnabled: true,
				tooltipText: statusTooltipText['down'],
				color: colorConstants["RED"]

			},
			{
				id: 'degraded',
				text: 'Degraded',
				count: getdetail('degraded')?.total_count || 0,
				percentage: getdetail('degraded')?.percentage || 0,
				className: 'link__monitor__card__degraded',
				isEnabled: true,
				tooltipText: statusTooltipText['degraded'],
				color: colorConstants["GREY"]

			},
			{
				id: 'maint',
				text: 'Maint',
				count: getdetail('maint')?.total_count || 0,
				percentage: getdetail('maint')?.percentage || 0,
				className: 'link__monitor__card__maintenance',
				isEnabled: user?.tenant?.name === 'Sangoma',
				tooltipText: statusTooltipText['maint'],
				color: colorConstants["YELLOW"]

			},
			{
				id: 'N/A',
				text: 'Others',
				count: getdetail('N/A')?.total_count || 0,
				percentage: getdetail('N/A')?.percentage || 0,
				className: 'link__monitor__card__others',
				isEnabled: true,
				tooltipText: statusTooltipText['N/A'],
				color: colorConstants["BLUE"]

			},
		]
	}, [user, linkStatusResponse])

	const phoneStatusDetails = useMemo(() => {
		const getdetail = (id) => phoneStatus && phoneStatus?.length > 0 && phoneStatus?.find(item => item?.phone_status === id)
		return [
			{
				id: 'up',
				text: 'Up',
				count: getdetail('up')?.total_count || 0,
				percentage: getdetail('up')?.percentage || 0,
				className: 'link__monitor__card__up',
				isEnabled: true,
				tooltipText: statusTooltipText['up'],
				color: colorConstants["GREEN"]

			},
			{
				id: 'down',
				text: 'Down',
				count: getdetail('down')?.total_count || 0,
				percentage: getdetail('down')?.percentage || 0,
				className: 'link__monitor__card__down',
				isEnabled: true,
				tooltipText: statusTooltipText['down'],
				color: colorConstants["RED"]

			},
			{
				id: 'maint',
				text: 'Maint',
				count: getdetail('maint')?.total_count || 0,
				percentage: getdetail('maint')?.percentage || 0,
				className: 'link__monitor__card__maintenance',
				isEnabled: user?.tenant?.name === 'Sangoma',
				tooltipText: statusTooltipText['maint'],
				color: colorConstants["YELLOW"]

			},
			{
				id: 'N/A',
				text: 'Others',
				count: getdetail('N/A')?.total_count || 0,
				percentage: getdetail('N/A')?.percentage || 0,
				className: 'link__monitor__card__others',
				isEnabled: true,
				tooltipText: statusTooltipText['N/A'],
				color: colorConstants["BLUE"]

			},
		]
	}, [user, linkStatusResponse])

	const gatewayStatusDetails = useMemo(() => {
		const getdetail = (id) => gatewayStatus && gatewayStatus?.length > 0 && gatewayStatus?.find(item => item?.status === id)
		return [
			{
				id: 'up',
				text: 'Up',
				count: getdetail('up')?.total_count || 0,
				percentage: getdetail('up')?.percentage || 0,
				className: 'link__monitor__card__up',
				isEnabled: true,
				tooltipText: statusTooltipText['up'],
				color: colorConstants["GREEN"]

			},
			{
				id: 'down',
				text: 'Down',
				count: getdetail('down')?.total_count || 0,
				percentage: getdetail('down')?.percentage || 0,
				className: 'link__monitor__card__down',
				isEnabled: true,
				tooltipText: statusTooltipText['down'],
				color: colorConstants["RED"]

			},
			{
				id: 'degraded',
				text: 'Degraded',
				count: getdetail('degraded')?.total_count || 0,
				percentage: getdetail('degraded')?.percentage || 0,
				className: 'link__monitor__card__degraded',
				isEnabled: true,
				tooltipText: statusTooltipText['degraded'],
				color: colorConstants["GREY"]

			},
			{
				id: 'maint',
				text: 'Maint',
				count: getdetail('maint')?.total_count || 0,
				percentage: getdetail('maint')?.percentage || 0,
				className: 'link__monitor__card__maintenance',
				isEnabled: user?.tenant?.name === 'Sangoma',
				tooltipText: statusTooltipText['maint'],
				color: colorConstants["YELLOW"]

			},
			{
				id: 'N/A',
				text: 'Others',
				count: getdetail('N/A')?.total_count || 0,
				percentage: getdetail('N/A')?.percentage || 0,
				className: 'link__monitor__card__others',
				isEnabled: true,
				tooltipText: statusTooltipText['N/A'],
				color: colorConstants["BLUE"]

			},
		]
	}, [user, linkStatusResponse])

	const resolution = useMemo(() => screenResolution(),[])



	

	useEffect(() => {
		if (!user.tenant) {
			return;
		}
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.tenant, service]);


	useEffect(() => {
		if (service?.label !== "All") {
			const value = serviceNameMapppings[service?.value]
			setUnmappedDeviceGraphData({
				[value]: data?.total_unmap
			})
		}
	}, [service, data?.total_unmap])


	const handleTicketCardClick = (e, item) => {
		if (item?.count === 0) {
			return
		}
		e?.stopPropagation(); handleRedirectToTicket(item)
	}

	const renderTicketCards = useMemo(() => {
		let renderItem = user?.email?.toLowerCase()?.includes("sangoma.com") 
		//canAccess('read', 'tickets') 
		return renderItem;
	}, [user?.email, ticketTenantIds, user?.tenant?.id])

	const ticketStatusApiCall = useCallback(async (ticketingSystem) => {
		setIsFetchingTicketStatus(true)
		const ticketingSystemValue = ticketingSystem ? ticketingSystem : ticketingSystemFilter?.[0]?.value?.toLowerCase();
		if(!ticketingSystemValue) {
			return
		}
		try {
			const request = {
				tenantId: user?.tenant?.name === "Sangoma" ? null: user?.tenant?.id,
				ticketing_system :ticketingSystemValue?.toLowerCase()
			}
	
			const response = await dispatch(fetchDashboardTicketStatusData(request))?.unwrap();
			if(response?.length) {
				const updatedTicketStatus = response?.reduce((acc, item) => {
					acc[item?.status?.toLowerCase()] = {
						total_count:item?.total_count,
						percentage: item?.percentage
					};
					return acc;
				}, {});
				setTicketStatusResponse(updatedTicketStatus);
			
			} else {
				setTicketStatusResponse({});
			}
		} catch(error) {
			console.error(error);
		} finally {
			setIsFetchingTicketStatus(false)
		}
		
	}, [dispatch, ticketingSystemFilter, user?.tenant?.id, user?.tenant?.name])

	const enableTicketingSystemFilter = useMemo(() => 
		ticketingSystemOptionsList?.length > 1  || user?.tenant?.name === "Sangoma" ,[ticketingSystemOptionsList?.length, user?.tenant?.name]);

	const onChangeOfTicketingSystemFilter = (val) => {
		setTicketingSystemFilter([val]);
		ticketStatusApiCall(val?.value?.toLowerCase())
	}

	const getTicketingSystems = useCallback(async () => {	
		setIsFetchingTicketStatus(true)
		try {
			const tenantId = user?.tenant?.name === "Sangoma" ? "sangoma" : user?.tenant?.id;
			const request = { tenantId };
			const response = await dispatch(fetchTicketingSystems(request))?.unwrap();
			

			if (Array.isArray(response?.data) && response?.data?.length) {
				const options = response?.data?.map(item => ({
					id: item?.id,
					label: item?.name,
					value: item?.name
				}));

				setTicketingSystemOptionsList(options);
				if (options?.length > 0) {
					const servicenowEnabled = options?.find(item => item.value === "Servicenow");
					if (servicenowEnabled && user?.tenant?.name !== "Sangoma") {
						setTicketingSystemFilter([servicenowEnabled]);
						await ticketStatusApiCall(servicenowEnabled?.value.toLowerCase())
					} else {
						const salesforceOption = options.find(item => item.value === "Salesforce")
						if (salesforceOption) {
							setTicketingSystemFilter([salesforceOption]);
							await ticketStatusApiCall(salesforceOption.value.toLowerCase())

						}
					}

				}			
			} else {
				setTicketingSystemOptionsList([]);
				toast.error(response);
				return null;
			}
		} catch (error) {
			console.error("Error fetching ticketing systems:", error);
			toast.error("Failed to fetch ticketing systems.");
			return null;
		} finally {
			setIsFetchingTicketStatus(false);
		}
	}, [user?.tenant?.name, user?.tenant?.id, dispatch, ticketStatusApiCall]);

	const ticketCardDetails = useMemo(() => {
		const defaultTicketStatus = ["Open", "New", "In Progress", "Escalated", "Needs Followup"];
		const defaultTicketOption = [{id: 0, value: 'Needs Followup', label: 'Needs Followup'}];
		const ticketingSystem = ticketingSystemFilter?.[0]?.value?.toLowerCase();
		const defaultServiceStatus= ['New', 'In Progress', 'Closed', 'On Hold']
		const headerCards = ticketingSystem === 'servicenow' ? servicenowHeaderCardDetails(ticketStatusResponse, defaultServiceStatus) :
		ticketDetails(ticketStatusResponse, defaultTicketStatus, defaultTicketOption)
		return  headerCards
	},[ticketingSystemFilter, ticketStatusResponse])



	useInterval(fetchData, 60000);

	return (
		<Grid container className={`${user?.themePreference === 'dark' ? " dark__mode" : " "} netops__dashboard`}>
			<Grid sx={{ padding: 0 }} item xs={12} md={12}>
				<Box className="wrapper__container">
					<Box className="wrapper__header" sx={{ paddingTop: "20px" }} >
						<NetopsHeaderTitle title="Overview" />
						{mspEnabled &&
						<Box className="form__group__wrap">
							<MuiTypography className="title">Service:</MuiTypography>
							<NetopsReactSelect
								components={{DropdownIndicator : CustomDropdownIndicator}}
								onChange={handleServiceChange}
								options={tenantOptions}
								placeholder="Select Service"
								value={service}

							/>
						</Box>
						}
					</Box>

					<Grid columnSpacing={"14px"} container className="wrapper__content" >
						{cardDetails && cardDetails?.length > 0 && cardDetails?.map((detail, idx) => {
							if (detail?.isEnabled) {
								return (
									<Grid key={idx} item md={(12/ (cardDetails?.filter(item => item?.isEnabled)?.length))} xs={12} sm={6}  >
										<NetopsDashboardCountCard detail={detail} isLoading={isLoading} />
									</Grid>
								)
							}
						})}
					</Grid>
				</Box>
			</Grid>


			{renderTicketCards &&
				<Grid item xs={12} md={12} className="wrapper__container">

					<Box className="wrapper__header">
						<NetopsDashboardTitle title={"Ticket Status"} />
						{enableTicketingSystemFilter &&
							<Box className="form__group__wrap">
								<NetopsReactSelect
									components={{ DropdownIndicator: CustomDropdownIndicator }}
									onChange={onChangeOfTicketingSystemFilter}
									options={ticketingSystemOptionsList}
									placeholder="Select "
									value={ticketingSystemFilter}
									className="react-select-container"

								/>
							</Box>
						}
					</Box>
					
					<Box className="wrapper__content" sx={{ padding: "16px 0px 24px !important" }}>
						<NetopsStatusCards md={2.4} lg={2.4} tableHeaderCardDetails={ticketCardDetails} handleCardClick={handleTicketCardClick} isLoading={isFetchingTicketStatus} />
					</Box>
				</Grid>
			}

			{mspEnabled && <Grid item xs={12} md={12} className="wrapper__container">
				<NetopsDashboardTitle title={`Monitoring Status ${`(Devices + Circuits)`}`} />
				<Box sx={{ padding: "16px 0px 24px !important" }}>
					<Grid container columnSpacing={"16px"}>
						<Grid item xs={12} md={6} lg={6}>
							<NetopsStatusCards
								customCardWidth ={250}
								tableHeaderCardDetails={monitoringStatusDetails} handleCardClick={handleRedirectToMonitoringStatus} isLoading={isLoading} />
						</Grid>

						<Grid item xs={12} md={6} lg={6} >
							<NetopsMonitoringStatusGraph linkStatusResponse={linkStatusResponse} handleRedirectToMonitoringStatus={handleRedirectToMonitoringStatus}  isLoading={isLoading} keyValue={'link_status'} />
						</Grid>
					</Grid>
				</Box>

			</Grid>}

			{phoneEnabled && <Grid item xs={12} md={12} className="wrapper__container">
				<NetopsDashboardTitle title={`Endpoint Status`} />
				<Box sx={{ padding: "16px 0px 24px !important" }}>
					<Grid container columnSpacing={"16px"}>
						<Grid item xs={12} md={6} lg={6}>
							<NetopsStatusCards
								customCardWidth ={250}
								tableHeaderCardDetails={phoneStatusDetails} handleCardClick={handleRedirectToEndPointStatus} isLoading={isLoading} />
						</Grid>

						<Grid item xs={12} md={6} lg={6} >
							<NetopsMonitoringStatusGraph linkStatusResponse={phoneStatus} keyValue={'phone_status'} handleRedirectToMonitoringStatus={handleRedirectToEndPointStatus} isLoading={isLoading} />
						</Grid>
					</Grid>
				</Box>
			</Grid>}

			{gatewayEnabled && <Grid item xs={12} md={12} className="wrapper__container">
				<NetopsDashboardTitle title={`Gateway Status`} />
				<Box sx={{ padding: "16px 0px 24px !important" }}>
					<Grid container columnSpacing={"16px"}>
						<Grid item xs={12} md={6} lg={6}>
							<NetopsStatusCards
								customCardWidth ={250}
								tableHeaderCardDetails={gatewayStatusDetails} handleCardClick={handleRedirectToGatewayStatus} isLoading={isLoading} />
						</Grid>

						<Grid item xs={12} md={6} lg={6} >
							<NetopsMonitoringStatusGraph linkStatusResponse={gatewayStatus} keyValue={'status'} handleRedirectToMonitoringStatus={handleRedirectToGatewayStatus} isLoading={isLoading} />
						</Grid>
					</Grid>
				</Box>
			</Grid>}

			{mspEnabled && 
			<Grid item md={12} xs={12} className="wrapper__container monitoring__status__service__wise">
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<NetopsDashboardTitle title={`Monitoring Status ${`(Service Wise)`}`} />
							<NetopsDashboardChartPreference chartPreference={chartMonitoringViewPreference} setChartPreference={setChartMonitoringViewPreference} />
					</Box>

					{canAccess('read', 'link-monitoring') &&
						<NetopsButton href={"/nf-status/link-monitoring"} variant="outlined" onClick={() => {
							ReactGA.event({
								category: 'User Interaction',
								action: 'Click Button',
								label: ` Link Moniter Clicked `,
							})
						}}>
							<NetopsLinkmonitoringIcon className={"link__monitor__btn__icon"} />
							<span>Link Monitoring</span>
						</NetopsButton>
					}



				</Box>
			

				<Box sx={{ 
					padding: "16px 0px 24px !important", 
					width:"100%",
					display: 'grid',
					gridTemplateColumns: `repeat(auto-fit, minmax(${user?.isSidebarOpen ? resolution === "desktop" ? 230 : resolution === "laptop"? 250: 280 :250}px, 1fr))`, 
					gap: "24px", }}
					>
					{monitoringStatusChartDetails && monitoringStatusChartDetails?.length > 0 && monitoringStatusChartDetails?.filter(i => i.isEnabled)?.map((chartDetail, idx) => {
						return (
								<NetopsDashboardChart
									key={chartMonitoringViewPreference+isLoading+idx}
									isLoading={isLoading}
									text={chartDetail?.text}
									redirectUrl={chartDetail?.redirectUrl}
									statusData={chartDetail?.statusData}
									navUrl={chartDetail?.navUrl}
									chartViewPreference={chartMonitoringViewPreference}
									noDataText={chartDetail?.noTextData}
									footer={chartDetail?.footer}
									sdwanDevicestatus={chartDetail?.sdwanDevicestatus}
									sdwanDeviceGraphId={chartDetail?.sdwanDeviceGraphId}
									service={service?.value}
								/>
						)
					}
					)}

					</Box>
			</Grid>
}

			<Grid item md={12} xs={12} className="wrapper__container inventory__container">
				<MuiThemedBox className="wrapper__container">
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<NetopsDashboardTitle title={`Inventory`} />
							<NetopsDashboardChartPreference chartPreference={chartInventoryViewPreference} setChartPreference={setChartInventoryViewPreference} />
					</Box>

					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: `repeat(auto-fit, minmax(${resolution === "laptop" ? user?.isSidebarOpen ? 300 : 250 : 300}px, 1fr))`,
							gap: "24px",
							padding: "16px 0px 24px !important"
						}}>
						{inventoryChartDetails && inventoryChartDetails?.length > 0 && inventoryChartDetails?.map((chartDetail, idx) => {
							if (chartDetail?.isEnabled) {
								return (
									<NetopsDashboardChart
										key={isLoading +idx+chartInventoryViewPreference}
										isLoading={isLoading}
										text={chartDetail?.text}
										statusData={chartDetail?.statusData}
										service={service?.value}
										chartViewPreference={chartInventoryViewPreference}
										isInventoryGraph={true}
									/>
								)
							}
						})}
					</Box>
				</MuiThemedBox>
			</Grid>
		</Grid>
	);
}

export default NetopsDashboard;
