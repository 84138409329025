import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import axios from "services/Axios";

let base_url = process.env.REACT_APP_BASE_URL + '/notification-details';


export const fetchAllNotificationDetails = createAsyncThunk(
    "notifications/fetchAllNotificationDetails",
    async (data, { rejectWithValue }) => {
        let url = `${base_url}`
        try {
            const response = await axios.get(url, { params: data?.params });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const fetchNotificationsById = createAsyncThunk("notifications/fetchNotificationsById",
    async (data, { rejectWithValue }) => {
        let url = `${base_url}/tickets/getData/${data?.ticketId}`
        try {
            const response = await axios.get(url, { params: data?.params });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }

})


export const addNotificationDetails = createAsyncThunk("notifications/addNotificationDetails",
    async (data, { rejectWithValue }) => {
        let url = `${base_url}/add`
        try {
            const response = await axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }

})


export const updateNotificationDetails = createAsyncThunk("notifications/updateNotificationDetails",
    async (data, { rejectWithValue }) => {
        let url = `${base_url}/update/${data?.id}`
        try {
            const response = await axios.put(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }

})


export const deleteNotificationDetails = createAsyncThunk("notifications/deleteNotificationDetails",
    async (data, { rejectWithValue }) => {
        let url = `${base_url}/delete/${data?.id}`
        try {
            const response = await axios.put(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }

})

export const NotificationsSlice = createSlice({
    name: "notifications",
    initialState: {
        userNotificationDetails: [],
        totalUserNotificationDetailsCount: 0
    },
    reducers: {
        resetNotificationsList: (state) => {
            state.userNotificationDetails = [];
            state.totalUserNotificationDetailsCount = 0;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllNotificationDetails.fulfilled, (state, action) => {
                state.userNotificationDetails = action.payload.data;
                state.totalUserNotificationDetailsCount = Number(action.payload.total);
            });
        builder.addCase(PURGE, () => {
            return NotificationsSlice.getInitialState();
        });
    }
});

export const { resetNotificationsList } = NotificationsSlice.actions;

export default NotificationsSlice.reducer;