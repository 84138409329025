import { persistReducer, persistStore } from 'redux-persist';

// ** Reducers
import { combineReducers } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import devices from 'store/devices';
import exportApi from 'store/exportApi'
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import user from "store/user";
import services from "store/services";
import sync from "store/sync";
import usermanagement from "store/usermanagement";
import changelogs from "store/changelogs";
import notifications from './notifications';
import tickets from './tickets';

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
    user,
    devices,
    services,
    sync,
    changelogs,
    usermanagement,
    exportApi,
    tickets,
    notifications
}))

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})

export const persistor = persistStore(store)