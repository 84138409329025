/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import axios from "services/Axios";

let base_url = process.env.REACT_APP_BASE_URL;

export const fetchEmailTemplate = createAsyncThunk(
    "appReports/fetchEmailTemplate",
    async () => {
        const response = await axios.get(`${base_url}/users/email/template`);
        return response.data;
    }
);

export const updateEmailTemplate = createAsyncThunk(
    "appReports/updateEmailTemplate",
    async (data) => {
        const response = await axios.put(`${base_url}/users/email/template`, data);
        return response.data;
    }
);

export const UserSlice = createSlice({
    name: 'user',
    initialState: {
        id:0,
        email: "",
        name: "",
        userRole: "",
        tenant: "",
        breadcrumbs: [],
        isLoading: false,
        acl: false,
        role: false,
        canCreateSubUsers: false,
        roleId: null,
        status: '',
        themePreference:'light',
        isSidebarOpen: false,
        isPhoneEnabled:false, 
        isMSPEnabled: false,
        isGatewayEnabled: false,
        ticketingAlert: false
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setTenant: (state, action) => {   
           state.tenant = action.payload;
        },
        setUser: (state, action) => {
            state.id = action.payload.id
            state.email = action.payload.email;
            state.name = action.payload.name;
            state.userRole = action.payload.userRole
            state.acl = action.payload.acl;
            state.role = action.payload.role;
            state.canCreateSubUsers = action.payload.canCreateSubUsers;
            state.status = action.payload.status;
            state.roleId = action.payload.roleId;
            state.themePreference = action.payload.themePreference;
            state.site_group_ids = action.payload.site_group_ids;
        },
        setSidebarOpen: (state, action) => {
            state.isSidebarOpen = action.payload
        },
        setThemePreference: (state, action) =>{
            state.themePreference = action.payload.themePreference
        },
        setUserServiceStatus: (state, action) =>{
            state.isPhoneEnabled = action.payload.isPhoneEnabled;
            state.isMSPEnabled = action.payload.isMSPEnabled;
            state.isGatewayEnabled = action.payload.isGatewayEnabled;
        },
        setTicketingStatus : (state, action) => {
            state.ticketingAlert = action.payload.ticketingAlert;
        }
    },
    extraReducers: builder => {
        builder.addCase(PURGE, () => {
            return UserSlice.getInitialState(); 
        });
    }
})

export const { setLoading, setTenant, setUser , setThemePreference, setSidebarOpen, setUserServiceStatus,setTicketingStatus} = UserSlice.actions;
export default UserSlice.reducer
