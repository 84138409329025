import React from 'react'
import { StyledTooltip } from '../TooltipLabel/TooltipLabel';
import help from '../../../assets/icons/help.png';
import helpDarkTheme from '../../../assets/icons/help_dark_theme.png'
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

const ColumnHeaderWithTooltip = ({ tooltipText, columnName }) => {
    return (
        <StyledTooltip placement="top" title={tooltipText} >
            {columnName}
        </StyledTooltip>
    )
}

export const ColumnHeaderWithQuestionMarkTooltip = ({ tooltipText, columnName }) => {
    const user = useSelector((state) => state.user);
    return (

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography  sx={{ paddingRight: '4px', fontWeight:700, fontSize: "14px", textTransform:"uppercase" }}>

                {columnName}
            </Typography>
            <StyledTooltip placement="top" title={<div dangerouslySetInnerHTML={{ __html: tooltipText }} />} width="230px">
                <img src={user?.themePreference === 'dark' ? helpDarkTheme : help} alt='help' style={{ width: '20px', height: '20px' }} />
            </StyledTooltip>
        </Box>
    )
}


export default ColumnHeaderWithTooltip
