import React, {useState, useEffect, Suspense} from 'react'
import { MuiThemedBox } from "components/common/MuiThemedComponents";
import Box from "@mui/material/Box";
import './ticketAlert.css';
import { useSelector, useDispatch } from 'react-redux';
import InputLabel from "@mui/material/InputLabel";
import SelectDropDown from 'components/common/SelectDropdown';
import Footer from "components/common/modal/Footer";
import { saveTicketAlert, getTicketAlert } from 'store/tickets';
import { toast } from 'react-toastify';
import FormPopup from "components/common/modal/FormPopup";
import Header from "components/common/modal/Header";
import { Alert } from "@mui/material";

export default function TicketAlert({close}) {

    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [thersholdValue, setThersholdValue] = useState({label: '5 mins', value : 5});
    const [isLoading, setIsLoading] = useState(false);

    const optionList = [{label: '5 mins', value : 5}, {label: '10 mins', value : 10}, {label: '15 mins', value : 15},
        {label: '20 mins', value : 20},{label: '25 mins', value : 25},{label: '30 mins', value : 30}
    ]

    useEffect(() => {
        fetchTicketAlert();
    }, [])

    const onThersholdChange = (e) => {
        setThersholdValue(e);
    }

    const fetchTicketAlert = async() => {
        setIsLoading(true);
        try{
        let req = {
            tenantId : user?.tenant?.id
        }
        const response = await dispatch(getTicketAlert(req)).unwrap();
        setThersholdValue(optionList.find(i => i.value === response?.[0]?.ticket_creation_threshold))
        }catch(error){
            toast.error('Something went wrong');
        }finally{
            setIsLoading(false);
        }
    }
 
    const onSaveClick = async() => {
        setIsLoading(true);
        try{
        let req = {
            tenantId : user?.tenant?.id,
            service: 'gateway',
            ticket_creation_thershold: thersholdValue?.value
        }
        await dispatch(saveTicketAlert(req));
        toast.success('Changes saved successfully');
        close();
        }catch(error){
            toast.error('Something went wrong');
        }finally{
            setIsLoading(false);
        }
    }

  return (
    <Suspense fallback={<div></div>}>
    <FormPopup open={true} size={"device_modal"} className={user?.themePreference === 'dark' ? "site__modal form__modal__darkMode" : "stepper__modal"}>
    
      <MuiThemedBox className="main_inner_wrap">
      <Header text={'Alert Manager'} close={close}/>      
        <Box className={"ticket_alert_wrap"}>
             
                  <div className='thershold_wrap'>
                      <InputLabel className="input_label">
                          {'Ticket Creation Threshold'}
                      </InputLabel>
                      <SelectDropDown
                          onChange={onThersholdChange}
                          hideFilterIcon={true}
                          options={optionList}
                          placeholder="Search..."
                          value={thersholdValue}
                          className="react-select-container"
                          isLoading = {isLoading}
                      />
                      <Alert variant="outlined" severity="warning" style={{ marginBottom: '15px', marginTop: '15px' }}>
                      A ticket will be created only after the threshold time has passed. This helps prevent excessive ticket generation within a short period.
                              </Alert>
                  </div>
                  <br />
          </Box>
          <Footer isLoading={isLoading} text={"Save"} submit={onSaveClick} />

      </MuiThemedBox>
    </FormPopup>
    </Suspense>
      
  )
}
