import React, {useState, useEffect, Suspense, useMemo} from 'react'
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import ProgressLoading from "components/common/ProgressLoading";
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel';
import DataTable, { createTheme } from "react-data-table-component";
import { fetchSitesData} from "store/devices";
import SelectDropDown from 'components/common/SelectDropdown';
import FilterStatusAndService, { ServiceFilterList } from "components/common/FilterStatusAndService";
import ButtonLayout from "components/common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {fetchUnMappedData} from 'store/binding'
import { toast } from "react-toastify";
import { createSiteBinding } from "store/devices";
import FormPopup from 'components/common/modal/FormPopup';
import CreateSite from 'components/component/createSite/CreateSite';
import {  MuiThemedButton, ReactDataTableStyles } from 'components/common/MuiThemedComponents';
import { useLocation, useNavigate } from 'react-router-dom';
import CopyTextCell from "components/common/CopyTextCell/CopyTextCell";
import GlobalExportButton from 'components/common/GlobalExportButton';
import './binding.css';
import Alert from '@mui/material/Alert';
import { useCanAccess } from 'services/canACLAccess';
// import  { StyledTooltip } from "components/common/TooltipLabel/TooltipLabel";


export default function UnmappedBinding() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate()
    const user = useSelector(state => state.user);
    const tenants = useSelector((state) => state.devices?.tenants);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [sites, setSites] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState(null);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [selectedSite, setSelectedSite] = useState(null);
    const [openSite, setopenSite] = useState(false);
    const [serviceFilter, setServiceFilter] = useState(null);
    const [deviceData, setDeviceData] = useState([]);
    const [isMapping, setIsMapping] = useState(false);
    const [tableRefreshKey, setTableRefreshKey] = useState(new Date());
    const [filterMaint, setFilterMaint] = useState([{value: 'up', label: 'Up/Down'}]);
    const [markSiteError, setMarkSiteError] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [duplicateFilter, setDuplicateFilter] = useState({value: '', label: ''})

	const canAccess = useCanAccess();

    useEffect(() => {
        setSelectedTenant(null);
        setSelectedSite(null);
        setSites([]);
        setDeviceData([]);
        if(user?.tenant?.name === 'Sangoma' && !selectedTenant?.value){      
            setRows([]);
            if (location?.state?.service && user?.tenant?.id == '') {
                getData();
            }
            return;
        }
        if(user?.tenant?.id || selectedTenant?.value ){
            getData()
        }
        if(location?.state?.service) {
           const selectedService =  ServiceFilterList?.find(item => item?.value === location?.state?.service );
           setServiceFilter(selectedService)
        }else{
            setServiceFilter(null);
        }
       
    }, [user?.tenant])

    const onChangeTenant = async(val) => {
        setSelectedTenant(val);
        setSelectedSite(null);
        setSelectedDevices([]);
        setSites([])
        getData(val?.value);
    }

	const handleSiteClose = () => {
		setopenSite(false);
	};

    const getService = (row) => {
        if(row === 'velocloud') return 'SDWAN';
        if(row === 'meraki') return 'WIFI';
        if(row === 'wug') return 'CIRCUITS';
        if(row === 'fortinet') return 'FORTINET';
        if(row === 'cradlepoint') return 'CRADLEPOINT';
    }


    const getData = async (id) => {
        setIsLoading(true);
    
        try {
            const unmappedData = await dispatch(fetchUnMappedData({id: id ? id : user?.tenant?.id, offset: 1, limit: 1000, sortField: 'name', sortOrder: 'asc'})).unwrap();
            
            if (unmappedData) {
                let data = [];
                if (location?.state?.service && user?.tenant?.id == '' && id == undefined) {
                    data = unmappedData;
                } else {
                    data = unmappedData?.data;
                }
                setDeviceData(data);
    
                data = data?.filter(item => item.status?.toLowerCase() !== 'maint');
                const redirectedService = location?.state?.service;
                if (redirectedService) {
                    const filteredData = data?.filter(item => {
                        if (item?.service === location?.state?.service) {
                            return item;
                        }
                    });
                    setRows(filteredData);
                    if (location?.state?.service && user.tenant?.name == 'Sangoma') {
                        const selectedService = ServiceFilterList?.find(item => item?.value === location?.state?.service);
                        setServiceFilter(selectedService);
                    }
                } else {
                    if (serviceFilter?.length > 0) {
                        let result = data
                        let srfilter = Array.isArray(serviceFilter) && serviceFilter?.map(i => i.value.toLowerCase()) || [];
                        if (srfilter?.length > 0 && !srfilter.includes('all')) {
                            result = result.filter(item => srfilter.includes(item?.service));
                        }
                        setRows(result);
                    } else {
                        setRows(data);
                    }
                }
            }
    
            if (user?.tenant?.id == '' && id !== undefined || user?.tenant?.id) {
                const res = await dispatch(fetchSitesData({tenantId: id ? id : user?.tenant?.id, offset: 1, limit: 2000, sortField: 'name', sortOrder: 'asc'})).unwrap();
                let result = res?.data?.map(i => { 
                    return { value: i.id, label: i.custom_fields?.display_name ? i?.custom_fields?.display_name : i.name }; 
                });
                setSites(result);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
            toast.error("Oops! Something went wrong");
        } finally {
            setIsLoading(false);
        }
    };
    

    

    const onDeviceSelect = (rows) => {
        // let devices = [...selectedDevices];
        // const devicesId = devices?.map(i => i.remote_site?.id);
      
    
        // if(!devicesId?.includes(row?.id)) {
        //     devices.push({netbox_site: obj, remote_site: row});
        // }
        // else {
        //     devices = devices?.filter(i => i.remote_site?.id !== row.id);
        // }
        // setSelectedDevices(devices)
        setSelectedDevices(rows?.selectedRows);
        console.log(rows)
    }

    const handleMap = async() => {
        if(selectedSite?.value && selectedDevices.length > 0){
            setIsMapping(true)
            let data =  [];
            let obj = {
                tenant_id: !selectedTenant?.value ? user?.tenant?.id : selectedTenant?.value,
                tenant_name: !selectedTenant?.value ? user?.tenant?.name : selectedTenant?.label,
                is_vip: !selectedTenant?.value ? user?.tenant?.custom_fields?.marked_vip : selectedTenant?.custom_fields?.marked_vip,
                id: selectedSite?.value
            }
            selectedDevices?.map(i => { 
             data.push({remote_site: i, netbox_site: obj});
            });


            let res = await dispatch(createSiteBinding({ data: data}));
            setSelectedDevices([]);
            setTableRefreshKey(new Date().getTime());
            setSelectedSite(null);
            if (res?.payload) {
                toast.success(" Site Binded successfully");
                getData(selectedTenant?.value);
                setSelectedSite(null);
                setSelectedDevices([]);
            } else {
                setSelectedDevices([]);
                toast.error("Something went wrong in mapping process.");
                setSelectedSite(null);
                setSelectedDevices([]);
            }
            setIsMapping(false);
            }
       else{
        if(!selectedSite?.value) {
            toast.error("Please select a site to map.");
            setMarkSiteError(true);
            setTimeout(() => {
                setMarkSiteError(false);
            }, 4000);
        }else if(!selectedDevices.length > 0) {
            toast.error("Please select the devices to map to the selected site.");
        }else {
            toast.error("Something went wrong in mapping process.");
        }
       
       }
    }

    const onSiteChange = (val) => {
        setSelectedSite(val)
    }

    const handleServiceFilter = async (val) => {
        val = Array.isArray(val) ? val : [val];
        if(location?.state) {
            navigate(location.pathname, {state: null})
        }
		setServiceFilter(val);
        let filterUpData = [...deviceData];
        let srfilter = Array.isArray(val) && val?.map(i => { return i.value.toLowerCase() }) || [];
        if (srfilter?.length > 0 && !srfilter.includes('all')) {
            filterUpData = filterUpData.filter(item => srfilter.includes(item?.service))
        }
        if (filterMaint?.length > 0) {
            const stFilter = filterMaint?.map(i => { return i.value?.toLowerCase() }) || [];
            filterUpData = filterUpData.filter(item => stFilter.includes(item.status?.toLowerCase()))
        }
        // if(duplicateFilter?.value === 'hide') filterUpData = filterUpData?.filter(i => !i.duplicate);

        setRows(filterUpData);
	};

    const headers = [
        { name: 'Devices (' + rows?.length + ')',  cell: (row) => (
        <TooltipLabel text = {row?.name ? row?.name: row?.devicedisplayname} />) },
        { name: 'Product Name',  cell: (row) => (row?.product_name ? <CopyTextCell text = {row?.product_name}/> : '--')},
        { name: 'Ip Address',  cell: (row) => (row?.ip_address ? <CopyTextCell text = {row?.ip_address}/> : '--')},
        { name: 'Serial', cell: (row) => (<TooltipLabel text = {row?.serial || '--'} />)},
        { name: 'Service', cell: (row) => (<TooltipLabel text = {getService(row?.service)} />)},
    ]

    createTheme('table__dark__theme',
        ReactDataTableStyles
    );

    const onFilterClick = (val) => {
        setFilterMaint(val)
        let filterUpData = [...deviceData];
        let srfilter = Array.isArray(serviceFilter) && serviceFilter?.map(i => { return i.value.toLowerCase() }) || [];
        if(srfilter?.length > 0 && !srfilter.includes('all')){
        filterUpData = filterUpData.filter(item => srfilter.includes(item?.service))}
        if(val?.length > 0){
            const stFilter = val?.map(i => {return i.value?.toLowerCase()}) || [];
            filterUpData = filterUpData.filter(item => stFilter.includes(item.status?.toLowerCase()))
        }
        //if(duplicateFilter?.value === 'hide') filterUpData = filterUpData?.filter(i => !i.duplicate);
      
        setRows(filterUpData);
    }

    // eslint-disable-next-line no-unused-vars
    const onChangeDuplicate = (val) => {
        setDuplicateFilter(val);
        let filterUpData = [...deviceData];
        if(val.value === 'hide') {filterUpData = filterUpData?.filter(i => !i.duplicate);}
        if(filterMaint?.length > 0){
            const stFilter = filterMaint?.map(i => {return i.value?.toLowerCase()}) || [];
            filterUpData = filterUpData.filter(item => stFilter.includes(item.status?.toLowerCase()))
        }
        let srfilter = Array.isArray(serviceFilter) && serviceFilter?.map(i => { return i.value.toLowerCase() }) || [];
        if(srfilter?.length > 0 && !srfilter.includes('all')){
        filterUpData = filterUpData.filter(item => srfilter.includes(item?.service))}
        setRows(filterUpData)
    }


    const getFilterRequest = (filterCount) => {
        const getFilterValues = (filter) => {
            if (Array.isArray(filter)) {
                return filter.map(item => item.value || item);
            } else if (typeof filter === 'object' && filter !== null && filter.value) {
                return [filter.value];
            } else {
                return [];
            }
        };
    
        const values = {
            service: getFilterValues(serviceFilter),
           // duplicateFilter:  getFilterValues(duplicateFilter),
            status: getFilterValues(filterMaint)
        };

        if(filterCount) {
            return getFilterValues(serviceFilter).length > 0 || getFilterValues(filterMaint).length > 0;
               
        }
    
        return JSON.stringify(values);
    };

    const filterConfigurations = useMemo(() => [
		{
			key: "statusFilter",
			isEnabled: true,
			placeholder: "Status...",
			options: [{ value: 'up', label: 'Up/Down' }, { value: 'maint', label: 'Maintenance' }],
			value: filterMaint,
			handler: onFilterClick,
            containerWidth : '250px'
		},
		// {
		// 	key: "serviceFilter",
		// 	isEnabled: true, 
		// 	placeholder: "Service...",
		// 	options: ServiceFilterList,
		// 	value: serviceFilter, 
		// 	handler: handleServiceFilter,
        //     containerWidth : '250px'
		// },
		
	], [
		user?.tenant?.name,
		serviceFilter,
        filterMaint,
		isLoading,
	]);

    
 
    return (
        <Box className={user?.themePreference === "dark" ? " map__filtered__wrapper__darkmode flex-col unbinding_page" :"flex-col unbinding_page"} gap="10px">
            {(user?.tenant?.name !== 'Sangoma' || selectedTenant?.value) && <Alert variant="outlined" severity="warning" style = {{margin: '15px 0px 5px 0px'}}>
            {`By default, maintenance devices and circuits are hidden. To view them, please select the "Maintenance" in the filter`}
            </Alert>}
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: '10px' }}>
                <Grid item md={4} className='flex flex_wrap'>
                    {user?.tenant?.name === 'Sangoma' && <Grid item md={3} className="filter_wrap">
                        <SelectDropDown options={tenants?.filter(i => i.name !== 'Sangoma')?.filter(i => (i.custom_fields?.meraki_org_id ||
                            i.custom_fields?.vco_enterprise_id || i.custom_fields?.cradlepoint_id || i.custom_fields?.fortinet_adom_oid ||
                            i.custom_fields?.wug_device_group_id))?.map(i => {
                                return {
                                    value: i.id,
                                    label: i.name, custom_fields: i?.custom_fields
                                }
                            })}
                            styles={customTenantStyle}
                            value={selectedTenant} placeholder={'Select Tenant'} hideFilterIcon={true} onChange={onChangeTenant} />
                    </Grid>}
                    <SelectDropDown
                        required={markSiteError}
                        styles={customStyle}
                        isLoading={user?.tenant?.id == '' && selectedTenant?.value == undefined ? false : isLoading}
                        options={sites} hideFilterIcon={true} placeholder={`Select Site...`}
                        onChange={onSiteChange} value={selectedSite} />
                </Grid>
                <Grid item md={8} className='flex'>
                    <FilterStatusAndService
						style={{ marginRight: '10px' }}
						filterConfigurations={filterConfigurations || []}
					/>
                     <SelectDropDown
                        styles={customTenantStyle}
                        options={ServiceFilterList} hideFilterIcon={true} placeholder={`Service...`}
                        onChange={handleServiceFilter} value={serviceFilter} />

                    {/* <SelectDropDown options={[{ value: 'hide', label: 'Hide Duplicate Circuits' }, { value: 'show', label: 'Show Duplicate Circuits' }]}
                        styles={customTenantStyle} value={duplicateFilter} hideFilterIcon={true} onChange={onChangeDuplicate} /> */}
                    {(user?.tenant?.name !== 'Sangoma' || selectedTenant?.value) && canAccess('create', 'sites') && <>
                        <ButtonLayout text={`site`} handleClick={() => setopenSite(true)} buttonStyleType={"outlined"} icon={<FontAwesomeIcon icon="fa-solid fa-plus" className="iconPadding" />} />
                        <GlobalExportButton
                            enableFilteredDataExportButton={getFilterRequest(true)}
                            exportApiRequest={{
                                filetype: 'csv',
                                dataOf: 'unmap',
                                tenantId: !selectedTenant?.value ? user?.tenant?.id : selectedTenant?.value,
                                filters: getFilterRequest()
                            }}
                            fileName={`UnmapData`}
                            text={"Are you sure you want to download unmapped binding devices ?"}
                            heading={"Unmapped Binding Devices"}
                        />
                    </>
                    }
                </Grid>
            </div>
            <Box className={"unmapped__binding__table"}>
                <DataTable
                    theme={user?.themePreference === 'dark' ? "table__dark__theme" : null}
                    className={`unmapped__table rdt_table_wrap hover_table ${user?.themePreference === 'dark' ? 'rdt__table__darkMode' : ''}`}
                    columns={headers}
                    data={rows}
                    key={tableRefreshKey}
                    fixedHeader
                    onSelectedRowsChange={onDeviceSelect}
					selectableRows 
                    progressPending={isLoading ? true : false}
                    progressComponent={<ProgressLoading />}
                />
            <Suspense fallback={<div></div>}>
				{openSite &&
					<FormPopup open={openSite} size={"siteModal"} className={"site__modal"}>
						<CreateSite isCreateSite={true} updateData={getData}
                     handleClose={handleSiteClose} isUpdateList ={true}/>
					</FormPopup>}
			</Suspense>
         
        </Box>
            <Box className="flex_end">
                {canAccess( 'create', 'binding') &&
                    <MuiThemedButton showLoader={isMapping} onClick={handleMap} variant="outlined" type="submit" className={user?.themePreference === 'light' ? "button-outlined flex" : ""}>
                        MAP
                    </MuiThemedButton>
                    }
                    </Box>
        </Box>
    )
}

const customStyle = {
    option: (base) => ({
        ...base,
        textTransform: 'capitalize',
    }),
    valueContainer: (base) => ({
        ...base,
        textTransform: 'capitalize',
    }),
    container: (base) => ({
        ...base,
        minWidth: '350px'
    })
}


const customTenantStyle = {
    option: (base) => ({
        ...base,
        textTransform: 'capitalize',
    }),
    valueContainer: (base) => ({
        ...base,
        textTransform: 'capitalize',
    }),
    container: (base) => ({
        ...base,
        minWidth: '170px',
        marginRight: '20px'
    })
}

