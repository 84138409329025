import { useSelector } from "react-redux"
import { Box } from "@mui/material"
import { MuiThemedCircularProgress, MuiTypography } from "components/common/MuiThemedComponents"
import { snakeCase } from "lodash"


export const ChartNoData = ({ noDataText }) => {
	return (
		<Box className="chart__data__not__available">
			<MuiTypography className="no__data__text">{noDataText ? noDataText : 'Data not available'}</MuiTypography>
		</Box>
	)
}

export const ChartLoader = () => {
	return (
		<Box sx={{ minHeight: 300, display: "flex", alignItems: "center", justifyContent: "center" }}>
			<MuiThemedCircularProgress />
		</Box>
	)
}

export const DashboardGraphLegend = ({legendDetails, handleLegendClick, text}) =>{
	return (
		<Box className="chart__legend__container">
		{legendDetails && legendDetails?.length > 0 && legendDetails?.map((legendItem, idx) => {
			return (
				<Box key={idx} className="chart__legend__item" onClick={() => handleLegendClick(legendItem, text)}>
					<Box className="chart__legend__color" style={{ backgroundColor: legendItem?.color }}></Box>
					<MuiTypography styles={{ textDecoration: !(legendItem?.isActive) ? "line-through" : "none" }} className={"chart__legend__text"} variant="p">{legendItem?.name}</MuiTypography>
				</Box>
			)
		})}
	</Box>
	)
}

export const SdwanGraph = ({ title, isLoading, text, chartViewPreference, statusData, graphId , checkNoData, noDataText, legendDetails, handleLegendClick}) => {
	return (
		<Box className="dashboard__graph__wrapper sdwan__graph__container">
			<Box className="chart__heading__container sub__heading__container">
				<MuiTypography variant="p" className="chart__sub__heading">{title}</MuiTypography>
			</Box>
			<Box sx={{ position: 'relative', top: '-8px' }}>
			<NetopsDashboardGraph graphId={graphId} isLoading={isLoading} text={text} chartViewPreference={chartViewPreference} noDataText={noDataText} noData={checkNoData(statusData)} legendDetails={legendDetails} handleLegendClick={handleLegendClick} />
			</Box>
		</Box>
	)
}



const NetopsDashboardGraph = ({ isLoading, text, noDataText, noData, graphId, legendDetails, handleLegendClick, customWidth}) => {
	const user = useSelector((state) => state.user);

	return (
		<Box  className={`${user?.themePreference === 'dark' ? "dark__mode " : ""} dashboard__chart__graph__card`}>
			{isLoading ? <ChartLoader /> :
				!isLoading && noData ?
					<ChartNoData noDataText={noDataText} /> :
					<>
						<div  style={{minWidth:customWidth ? customWidth : "200px", minHeight: "200px"}} id={graphId ? graphId : snakeCase(text)} className={`${user?.themePreference === "dark" ? "dark__mode" : ""} netops__highcharts`}>
						</div>
						<DashboardGraphLegend legendDetails={legendDetails} handleLegendClick={handleLegendClick} text={text} />
					</>
			}
		</Box>
	)
}


export default NetopsDashboardGraph

