import { Alert, ButtonGroup, FormGroup, Grid, InputLabel, Step, StepLabel, Stepper } from "@mui/material";
import Box from "@mui/material/Box";
import FormLayout from "components/common/FormLayout";
import InputGroup from "components/common/InputGroupWithLabel";
import { MuiThemedButton, ThemedReactSelect } from "components/common/MuiThemedComponents";
import ProgressLoading from "components/common/ProgressLoading";
import Footer from "components/common/modal/Footer";
import FormPopup from "components/common/modal/FormPopup";
import Header from "components/common/modal/Header";
import { formTypes } from "constant/Formtypes";
import { Suspense, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addDeviceData, createDeviceType, fetchDeviceManufacturers, fetchDeviceRoles, fetchDeviceType, 
    fetchSiteNames, fetchUserTenantsData, updateDevieData, fetchCircuitsData, unLinkCircuits, getCircuitList,
    addDeviceAssociateCircuits,
    updateDeviceAssociateCircuits} from "store/devices";
import { setLoading } from "store/user";
import ColorlibStepIcon from "../createTenants/ColorlibStepIcon";
import AssociateCircuitsPopup from "./AssociateCircuitPopup";
import { QontoConnector, removeItemsAfterKey } from "components/common/StepperUtils";

const CreateDevice = ({
    handleClose,
    isCreateDevice,
    isUpdateList,
	editData,
	updateData,

}) => {
    const isEditData = editData ? Object?.keys(editData)?.length: 0;
    const [circuitList, setCircuitList] = useState([]);
    const [markError, setMarkError] = useState(false);
    const [createDeviceError, setCreateDeviceError] = useState(false);
    const [selectCircuit, setSelectedCircuit] = useState({});

    const formParameters = [
        {
            title: "Tenants",
            identifier: "tenant",
            parameterType: "Autocomplete",
            defaultValue: "",
            validationRules: ["required"],
            placeholder: "Select Tenant",
            widthMd: 12,
            widthsm: 12,
            widthxs: 12,
            isEnabled: true,
        },
        {
            title: "Site Display Name",
            identifier: "site_name",
            parameterType: "Autocomplete",
            defaultValue: "",
            validationRules: ["required"],
            placeholder: "Select Site Display Name",
            widthMd: 12,
            widthsm: 12,
            widthxs: 12,
            isEnabled: true,
        },
        {
            title: "Device Role",
            identifier: "device_role",
            parameterType: "Autocomplete",
            defaultValue: "",
            validationRules: ["required"],
            placeholder: "Select Device Role",
            widthMd: 12,
            widthsm: 12,
            widthxs: 12,
            isEnabled: true,
        },
        {
            title: "Name",
            identifier: "name",
            parameterType: "InputField",
            defaultValue: "",
            validationRules: ["required"],
            widthMd: 12,
            widthsm: 12,
            widthxs: 12,
            maxChar: 59,
            isEnabled: true,
        },
        {
            title: "Device Type",
            identifier: "device_type",
            parameterType: "lineDropDowns",
            defaultValue: "",
            validationRules: ["required"],
            placeholder: "Select Device type",
            widthMd: 12,
            widthsm: 12,
            widthxs: 12,
            isCircuitsEnable :false,
            isEnabled: true,
        },
        {
            title: "Serial Number",
            titleSide: "IP Address",
            identifier: "serial",
            identifierSide: "ip_address",
            parameterType: "lineInputs",
            defaultValue: "",
            validationRules: ["required"],
            widthMd: 12,
            widthsm: 12,
            widthxs: 12,
            isEnabled: true,
            maxChar: 20,
        },
        {
            title: "State",
            identifier: "state",
            parameterType: "Status_ListSingle",
            defaultValue: "",
            validationRules: ["required"],
            optionList: [
                {
                    label: "Active",
                    value: "active",
                },
                {
                    value: "staged",
                    label: "Staged",
                },
                {
                    label: "Offline",
                    value: "offline",
                },
                {
                    label: "Planned",
                    value: "planned",
                },
                {
                    label: "Inventory",
                    value: "inventory",
                },
                {
                    label: "Decommissioning",
                    value: "decommissioning",
                },
            ],
            placeholder: "Select status",
            widthMd: 12,
            widthsm: 12,
            widthxs: 12,
            isEnabled: true,
        },
        {
            identifier: "associate_circuit_button",
            parameterType: "button",
            defaultValue: "",
            hideFilterIcon: true,
            validationRules: [],
            placeholder: "Select Circuit",
            widthMd: 4,
            widthsm: 4,
            widthxs: 4,
            isEnabled: false,
        },
    ]
    const copiedFormTypes = [...formParameters]
    const [parameters, setParameters] = useState(formParameters);
    const [formValues, setFormValues] = useState([]);
    const [manufacturerList, setManufacturerList] = useState([]);
    const [siteNameList, setSiteNameList] = useState([]);
    const [openDeviceType, setOpenDevicetype] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeviceLoad, setIsDeviceLoad] = useState(false);
    const [deviceCheck, setDeviceCheck] = useState(false);
    const [nameError, setNameError] = useState([]);
	const [isError, setIsError] = useState(false);
    const [isSiteNamesLoading, setIsSiteNamesLoading] = useState(false);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [filterParameter, setFilterParameter] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState(editData? {value: editData?.tenant?.id, label: editData?.tenant?.value}: null);
    const selectedSite = useRef(editData? {value: editData?.site?.id, label: editData?.site?.name}: null);
    const [selecedCircuit, setSelectedCircuits] = useState([]);
    const [unmapLoader, setUnmapLoader] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [wanLoader, setWanLoader] = useState({port:0, loader:false});
    const [internalLoader, setInternalLoader] = useState({port:0, loader:false});
    const [completed, setCompleted] = useState({});
    // const [associateCircuitEnable, setAssociateCircuitEnable] = useState(true);
    const handleDeviceClose = useRef(false);
    const deviceResult = useRef([]);
    const elementRef = useRef(null);
    const steps = ["Device Details", "Associate Circuits"];

    const getDeviceRoles = async () => {
        let res = await dispatch(fetchDeviceRoles());
        res = res.payload?.filter(data => data?.name == 'Firewall' || data?.name == 'Switch');
        return res;
    }

    const getCircuitData = async(data) => {
        let res = await dispatch(fetchCircuitsData({tenantId: selectedTenant?.value ? selectedTenant?.value : user?.tenant?.id, siteId: selectedSite.current?.value ? selectedSite.current?.value : data?.value, service: !isEditData || editData?.device_role_name == 'Firewall' ? 'fortinet': isEditData && 'cradlepoint' }));
        setCircuitList(res?.payload);
        return res?.payload;
    }

    // const getDevicePortInformationData = async() => {
    //     let res = await dispatch(fetchDevicePortInformation());
    //     return res?.payload;
    // }

    const getDeviceType = async () => {
        return await dispatch(fetchDeviceType());
    }

    const getTenantList = async () => {
        return await dispatch(fetchUserTenantsData(user));
    };

    const getSiteNames = async (id) => {
        let res = await dispatch(fetchSiteNames({ tenantId: id ? id : user?.tenant?.id }));
        setSiteNameList(res.payload);
        
     }
    const getDeviceManufacturer = async () => {
        let res = await dispatch(fetchDeviceManufacturers());
        res = res?.payload?.filter(data => data?.name == 'Cisco' || data?.name == 'Fortinet');
        setManufacturerList(res);
    }

    const createParameters = async () => {
        setIsLoading(true);
        let params = copiedFormTypes?.filter((para) =>
            user?.tenant?.name !== "Sangoma" ? para?.identifier !== "tenant" : para
        );

        if (isEditData) {
            params = params.map(param => {
                if (param.identifier === 'tenant') {
                    return { ...param, isDisabled: true };
                }
                return param;
            });
        }
        let dropdownOption = params;
        for (let i = 0; i < dropdownOption?.length; i++) {
            let params = dropdownOption[i];
            if (params?.parameterType === "Autocomplete" && !deviceCheck) {
                if (params?.identifier === "device_role") {
                    const deviceRoleData = await getDeviceRoles();
                    params.optionList = deviceRoleData;
                }

                if (params?.identifier === "tenant") {
                    let tenantsData = await getTenantList();
                    tenantsData = tenantsData?.payload?.filter(data => data?.name !== "Sangoma");
					params.optionList = tenantsData;
                }
               
                if (params?.identifier === "site_name") {
                    params.optionList = siteNameList;

                }
            }
            if(params?.identifier === "state"){
                params.optionList = params.optionList.filter(data => user?.tenant?.id ? data.value !== "decommissioning": data);
            }
            if (params?.identifier === "device_type") {
                const deviceTypeData = await getDeviceType();
                params.optionList = deviceTypeData?.payload;
            }
        }

        setDeviceCheck(false);
        setIsLoading(false);
        setIsSiteNamesLoading(false);
        setParameters(dropdownOption);
        setFilterParameter(dropdownOption.filter(i => i.isEnabled));
    };

    const getCircuitDataById = async() => {  
        if ( editData?.device_role?.id === 1 ||  editData?.device_role?.name === 'Firewall' || editData.custom_fields?.cradlepoint_router_id) {
            setWanLoader((prev) =>({...prev, port:1, loader:true}));
            setInternalLoader((prev) =>({...prev, port:1, loader:true}));
        const dropdownOption = [...parameters];
        let data = await dispatch(getCircuitList({deviceId : editData?.id}));
        data = data?.payload;
        setFormValues((prevValues) => ({
            ...prevValues,
            circuitlist: data,
        }));
        setWanLoader((prev) =>({...prev, port:1, loader:false}));
        setInternalLoader((prev) =>({...prev, port:1, loader:false}));
        // let parameterToEnable = dropdownOption?.find(i => i.identifier === 'associate_circuit_button');
        let deviceIdentifier = dropdownOption?.find(i => i.identifier === 'tenant');
        let tenantIdentifier = formParameters?.find(i => i.identifier === 'device_role');
        let siteIdentifier = formParameters?.find(i => i.identifier === 'site_name');
        let parameterToEnable = formParameters?.find(i => i.identifier === 'device_type');
        if (deviceIdentifier) {
            deviceIdentifier.isDisabled = data?.length > 0;
        }

        if (tenantIdentifier) {
            tenantIdentifier.isDisabled = data?.length > 0;
        }
        if (siteIdentifier) {
            siteIdentifier.isDisabled = data?.length > 0;
        }
        if (parameterToEnable) {
            parameterToEnable.isDisabled = data?.length > 0;
        }
        // parameterToEnable.isEnabled = data?.length > 0 ? true : false;
        // parameterToEnable.title = `${data?.length} Mapped Circuit`;
        setFilterParameter(dropdownOption.filter(i => i.isEnabled));
        setSelectedCircuits({ site: editData?.site?.name, circuits: data, deviceId: editData.id});
    }
    }

    // const deviceTypeSelceted = async(data) =>{
    //     const formParameters = parameters;
    //         let parameterToEnable = formParameters?.find(i => i.identifier === 'device_type');
    //         parameterToEnable.isLoading = true;
    //         const res = await getDevicePortInformationData();
    //         let result = res.length > 0 && res?.filter(i =>
    //             data?.label && (i?.type + i?.model) === data.label
    //         );
    //         setDevicePortData(result);
    //         parameterToEnable.isLoading = false;
    //         if(result.length > 0 ){
    //             setAssociateCircuitEnable(false);
    //         }else {
    //             setAssociateCircuitEnable(true);
    //         }
    // }

    // useEffect(() => {
       // if(editData) setSelectedTenant({value: editData?.tenant?.id, label: editData?.tenant?.value})
    //     getSiteNames(editData?.tenant?.id);
    //     getCircuitDataById()
    // }, [])

    useEffect(() => {
        // if(editData) setSelectedTenant({value: editData?.tenant?.id, label: editData?.tenant?.value})
        if(!editData.custom_fields?.cradlepoint_router_id)
            getSiteNames(editData?.tenant?.id);

        getCircuitDataById()
    }, [isSiteNamesLoading])


    useEffect(() => {
        if(!editData.custom_fields?.cradlepoint_router_id)
          getDeviceManufacturer();

        if(Object.keys(editData).length !== 0) getCircuitData();
        if (editData) {
			if (Object?.keys(editData)?.length) {
				let data = {
					...editData,
                    site_name:{value: editData?.site?.id, label: editData?.site?.name, object: editData?.site },
                    device_role:{value: editData?.device_role?.id, label: editData?.device_role?.name, object: editData?.device_role},
                    device_type:{value: editData?.device_type?.id, label: editData?.device_type?.model, object: editData?.device_type },
                    ip_address : editData?.device_role?.name == 'Firewall' ? editData?.custom_fields?.fortinet_public_ip : editData?.custom_fields?.switch_ip_address,
                    state: editData?.status
				};
                // deviceTypeSelceted({value: editData?.device_type?.id, label: editData?.device_type?.model})
               
				setFormValues(data);
			}
        }

        if (formTypes()?.length && isCreateDevice && !editData.custom_fields?.cradlepoint_router_id) {
            createParameters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.tenant?.id]);

    
    useEffect(() => {
        if (siteNameList?.length > 0) {
            const dropdownOption = [...parameters];
            let parameterToEnable = dropdownOption?.find(i => i.identifier === 'site_name');
            parameterToEnable.optionList = siteNameList;
            setFilterParameter(dropdownOption.filter(i => i.isEnabled));
            setIsSiteNamesLoading(false);

        } else if (siteNameList?.length === 0) {
            setIsSiteNamesLoading(false);
        }
    }, [siteNameList]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
        //   for (let entry of entries) {
        //     // Handle resize logic here
        //     console.log(entry.contentRect);
        //   }
        });
    
        if (elementRef.current) {
          resizeObserver.observe(elementRef.current);
        }
    
        return () => {
          // Clean up the observer on unmount
          if (elementRef.current) {
            resizeObserver.unobserve(elementRef.current);
          }
        };
      }, []);

    const closeDevice = async () => {
        setFilterParameter([]);
        handleClose();
    };
    const closeDeviceType = async () => {
        setOpenDevicetype(false);
        setFormValues((val) => ({
            ...val,
            manufacturer: "",
            model: ""
        })
        )
    };

    const validateInput = async (e, id, regex, errorMessage) => {
        if (regex.test(e.target.value)) {
            setNameError("");
            setIsError(false);
            await setFormValues((prevValue) => ({
                ...prevValue,
                [id]: e.target.value,
            }));
        } else {
            setNameError((prevValue) => ({
                ...prevValue,
                msg: errorMessage,
                id: id,
            }));
            setIsError(true);
        }
    };
    
    const handleInputChange = async (e, id) => {
        if (id === "name") {
            const nameRegex = /^[a-zA-Z0-9-_ ]*$/;
            const nameErrorMessage = "Only alphanumeric characters, hyphen, underscore, and spaces are allowed.";
            await validateInput(e, id, nameRegex, nameErrorMessage);
        } else if (id === "serial" || id == "model") {
            const serialRegex = /^[a-zA-Z0-9-_]*$/;
            const serialErrorMessage = "Only alphanumeric characters, hyphen and underscore are allowed.";
            await validateInput(e, id, serialRegex, serialErrorMessage);
        } else {
            setFormValues((prevValue) => ({
                ...prevValue,
                [id]: e.target.value,
            }));
        }
    };

    const handleInputChangeSide = (e, id) => {
        const ipRegex = /^([0-9]{1,3}\.){0,3}[0-9]{0,3}$/;
        const isValidIp = ipRegex.test(e.target.value);
        if (isValidIp) {
            setFormValues((prevValue) => ({ ...prevValue, [id]: e.target.value }));
        }

    }

    const handleSelectChange = async (data, id) => {
        if (id === "tenant") {
            setIsSiteNamesLoading(true);
            setSelectedTenant(data)
            await setFormValues((prevValues) => ({
                ...prevValues,
                [id]: data ? data.value : "",
                'associate_circuit' : [],
                'device_role' : ""
            }));

            if (data !== null) {
                await getSiteNames(data.value);
             
            } else {
                setIsSiteNamesLoading(false);
            }
        } else if(id === 'device_role'){
            await setFormValues((prevValues) => ({
                ...prevValues,
                [id]: data,
            }));
            const formParameters = parameters;
            let circuit_button = formParameters?.find(i => i.identifier === 'associate_circuit_button');
            circuit_button.isEnabled = (data?.value === 1 ||  data?.label === 'Firewall') && isEditData && selecedCircuit?.circuits?.length ? true : false;
            setFilterParameter(formParameters?.filter(i => i.isEnabled));
            //  await getCircuitData();
           

            setFilterParameter(formParameters?.filter(i => i.isEnabled));
        }else {
            if(id === "site_name"){
                setIsSiteNamesLoading(true);
                // setSelectedSite(data);
                selectedSite.current = data;
                if (data) {
                    await getCircuitData(data);
                }
                setIsSiteNamesLoading(false);
            }
            await setFormValues((prevValues) => ({
                ...prevValues,
                [id]: data,
            }));
        }
    };

    const handleSelectStatus = (data) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            state: data,
        }));
    };

    const handleSelectmanufacturer = (data) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            manufacturer: data,
        }));
    };

    const handleOnfocus = (e, id) => {
        setFormValues((prevValue) => ({ ...prevValue, [id]: e.target.value }));
    };

    const handleOpendeviceType = () => {
            setOpenDevicetype(true);
        
    }

    const handleCircuitsSelect = async(index, portType) => {
        let circuitids = [];
        if (selecedCircuit && Array.isArray(selecedCircuit.circuits)) {
            circuitids = selecedCircuit.circuits
                .filter(element => element?.port === index + 1 && element?.port_type === portType && element?.value !== undefined && element?.netbox_id !== undefined)
                .map(element => ({
                    id: element.value,
                    netboxId: element.netbox_id
                }));
        }
        if (circuitids.length > 0) {
            portType == "WAN"?setWanLoader(prevState => ({
                ...prevState,
                port: index+1,
                loader: true
            })): setInternalLoader(prevState => ({
                ...prevState,
                port: index+1,
                loader: true
            }));
            await handleUnmap(selecedCircuit?.deviceId, circuitids, selecedCircuit?.circuits[0]?.label);
            portType == "WAN"?setWanLoader(prevState => ({
                ...prevState,
                port: index+1,
                loader: false
            })): setInternalLoader(prevState => ({
                ...prevState,
                port: index+1,
                loader: false
            }));
            setFormValues((prevValues) => ({
                ...prevValues,
                circuitlist: prevValues.circuitlist.filter(item => !(item.port === index + 1 && item.port_type === portType)),
            }));
        }
    }

    const handleSelectWanCircuits = (index, data) => {
        setSelectedCircuit(data);
        if (data == null) {
            handleCircuitsSelect(index, "WAN");
        }
        setFormValues((prevValues) => ({
            ...prevValues,
            [index]: {
                ...prevValues[index],
                wanCircuits: data,
            },
        }));
    };

    const handleSelectInternalPortCircuits = (index, data) => {
        setSelectedCircuit(data);
        if(data == null){
            handleCircuitsSelect(index, "Internal port");
        }
     
        setFormValues((prevValues) => ({
            ...prevValues,
            [index]: {
                ...prevValues[index],
                internalPortCircuits: data,
            },
        }));
    };

    const formatData = () => {
        const result = [];
    
        Object.keys(formValues).forEach((key) => {
  
            if (formValues[key]) {
                const { wanCircuits, internalPortCircuits } = formValues[key];
                if (wanCircuits !== null && wanCircuits !== undefined) {
                 
                    result.push({
                        circuitId: wanCircuits,
                        portType: 'WAN',
                        port: parseInt(key) + 1,
                    });
                }
    
                if (internalPortCircuits !== null && internalPortCircuits !== undefined) {
                    result.push({
                        circuitId: internalPortCircuits,
                        portType: 'Internal port',
                        port: parseInt(key) + 1,
                    });
                }
            }
        });
        return result;
    };
    

    const handleSubmitAssociateCircuits = async() =>{
        await dispatch(setLoading(true));
        let resultData = formatData();
      
        let res = null;
                const req = {
                name: formValues?.name,
                device_type: formValues?.device_type?.value,
                device_role: formValues?.device_role?.value,
                tenant: user?.tenant?.id ? user?.tenant?.id : isEditData ?editData?.tenant?.id : formValues?.tenant,
                site: formValues?.site_name?.value,
                status: formValues?.state?.value,
                serial: formValues?.serial,
                custom_fields: {
                    fortinet_public_ip: formValues?.device_role?.label == 'Firewall' ? formValues?.ip_address:'',
                    switch_ip_address: formValues?.device_role?.label == 'Switch' ? formValues?.ip_address : '',
                    is_custom: !editData.custom_fields?.cradlepoint_router_id ? true :false
                }
            }
            if (!isEditData) {
                res = await dispatch(addDeviceAssociateCircuits({ deviceData: req, circuitData: resultData }));
            } else {
                res = await dispatch(updateDeviceAssociateCircuits({ deviceId: formValues?.id, deviceData: req, circuitData: resultData }))
            }
            if (res?.payload) {
                toast.success(`${editData.custom_fields?.cradlepoint_router_id? 'Circuit Associated': 'Device'} ${!editData.custom_fields?.cradlepoint_router_id ? isEditData? 'Updated':'Created' :''}  Successfully`);
                await dispatch(setLoading(false));
                deviceResult.current = [];
                handleClose();
                if (isUpdateList) {
                    await updateData();
                }
              
            }
            await dispatch(setLoading(false));
    }

    const handleDeviceTypeSubmit = async (e) => {
        e.preventDefault();
        if (formValues?.manufacturer && formValues?.model) {
            const req = {
                manufacturer: formValues?.manufacturer?.value,
                model: formValues?.model,
                slug: formValues?.model?.replace(/\s+/g, "_")
            }
            let res = await dispatch(createDeviceType(req));
            if (res?.payload) {
                toast.success(`Device Type Created Successfully`);
                if (formTypes()?.length) {
                    createParameters();
                    setDeviceCheck(true);
                }
                setIsDeviceLoad(false);
                setOpenDevicetype(false);
                setFormValues((val) => ({
                    ...val,
                    manufacturer: "",
                    model: ""
                })
                )
            }

        } else {
            setIsDeviceLoad(false);
            setCreateDeviceError(true);
            setTimeout(() => {
                setCreateDeviceError(false);
            }, 5000);
            toast.error("Please fill all the required details");
        }
    }

    const handleSubmit = async (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        await dispatch(setLoading(true));
        var res = undefined;
        if (Object?.keys(formValues)?.length) {
            const req = {
                name: formValues?.name,
                device_type: formValues?.device_type?.value,
                device_role: formValues?.device_role?.value,
                tenant: user?.tenant?.id ? user?.tenant?.id : isEditData ?editData?.tenant?.id : formValues?.tenant,
                site: formValues?.site_name?.value,
                status: formValues?.state?.value,
                serial: formValues?.serial,
                associated_circuit : formValues?.associate_circuit,
                custom_fields: {
                    fortinet_public_ip: formValues?.device_role?.label == 'Firewall' ? formValues?.ip_address:'',
                    switch_ip_address: formValues?.device_role?.label == 'Switch' ? formValues?.ip_address : '',
                    is_custom:true
                }
            }
            if (formValues?.id && req?.name && req?.device_type && req?.device_role && req?.site && req?.serial) {
                res = await dispatch(updateDevieData({id:formValues?.id, req:req}));
             
            }else if(req?.name && req?.device_type && req?.device_role && req?.site && req?.serial && (req?.custom_fields?.fortinet_public_ip || req?.custom_fields?.switch_ip_address)){
                res = await dispatch(addDeviceData(req));
            }else {
                await dispatch(setLoading(false));
                setMarkError(true);
				setTimeout(() => {
					setMarkError(false);
				}, 5000);
                toast.error("Please fill all the required details");
            }
            deviceResult.current = res?.payload?.data
            if (res?.payload) {
                await dispatch(setLoading(false));
                toast.success(`Device ${formValues?.id ? "updated" : "added"} successfully`);
                setFilterParameter([])
                if (handleDeviceClose.current == false) {
                    handleClose();
                }
                if (isUpdateList && handleDeviceClose.current == false) {
                    await updateData();
                }
                return true;
            }
        } else {
            await dispatch(setLoading(false))
            setMarkError(true);
            setTimeout(() => {
                setMarkError(false);
            }, 5000);
            toast.error("Please fill all the required details");
        }
        await dispatch(setLoading(false))

    }

    const handleUnmap = async(deviceId, circuitIds, name) => {
        let req = {
            deviceId: deviceId,
            circuitIds : JSON.stringify(circuitIds),
            circuitName : name,
            tenantId : user?.tenant?.id ? user?.tenant?.id : isEditData ?editData?.tenant?.id : formValues?.tenant,
        }
        setUnmapLoader(true);
        if(circuitIds?.length > 0) {
        let res = await dispatch(unLinkCircuits(req));
        if (res?.payload) {
            // let parameterform = [...filterParameter]
            // let parameterToEnable = parameterform?.find(i => i.identifier === 'associate_circuit_button');
            // parameterToEnable.title = `${editData?.circuits?.length - circuitIds?.length} Mapped Circuit`;
            let cloneSelCircuit = selecedCircuit;
            let data = cloneSelCircuit?.circuits?.filter(i => !circuitIds.includes(i.value?.toString()));
            // parameterToEnable.circuits = data;
            setSelectedCircuits({ site: editData?.site?.name, circuits: data, deviceId: editData.id});
            getCircuitDataById()
            // setFilterParameter(parameterform);
            toast.success("Circuit UnLinked successfully");
            // setFilterParameter([]);
            await getCircuitData();
            setUnmapLoader(false);
                // handleClose();
                // await updateData();
                
        }
        else {
            setUnmapLoader(false);
            toast.error("Something went wrong");
        }
    }
    else {
        toast.error("Please Select Circuit");
    }
 }

    const totalSteps = () => {
        return steps.length;
    };

    const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

    const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

    const completedSteps = () => {
		return Object.keys(completed)?.filter(item => completed[item] === true).length;
	};

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (activeStep === totalSteps()) {
            const newCompleted = completed;
            newCompleted[activeStep - 1] = false;
            setCompleted(newCompleted);
        } else {
            const updatedCompletedObject = { ...completed };
            const previousStep = activeStep - 1;
            // eslint-disable-next-line no-unused-vars
            const { [previousStep]: removedKey, ...rest } = updatedCompletedObject;
            setCompleted(rest);
        }
    };

    const deleteKeyFromObject = (obj, stepToRemove) =>{
		// eslint-disable-next-line no-unused-vars
		let {[stepToRemove]: omit, ...res} = obj;
		setCompleted(res)
		return res
	}

    const handleNext = async () => {
        
        let valid = !validateDeviceDetails();
        if (valid) {
            deleteKeyFromObject(completed, activeStep)
            return
        }
        handleDeviceClose.current = false;
        let newActiveStep = activeStep + 1;
        if (isLastStep() && !allStepsCompleted()) {
            newActiveStep = steps.findIndex((step, i) => !(i in completed));
        }
        setActiveStep(newActiveStep);
    };

    const handleComplete = () => {
        handleDeviceClose.current = true
		const newCompleted = completed;
		newCompleted[activeStep] = true;
		setCompleted(newCompleted);
		handleNext();
		if (completedSteps() === totalSteps()) {
			close();
			setActiveStep(0);
			setCompleted({});
		}
	};




const validateDeviceDetails = () => {
    if (!(formValues?.name && formValues?.site_name && formValues?.device_type?.value 
        && formValues?.device_role?.value && formValues?.state?.value 
        && formValues?.ip_address)) {
        toast.error("Please enter the required fields and submit");
        setMarkError(true);
        setTimeout(() => {
            setMarkError(false);
        }, 5000);
        return false;
    }else if(formValues?.device_role?.label === 'Switch'){
        return false
    }else{
        return true;
    }
 
};
const handleStep = (step) => () => {

    const isKeyPresent = Object.keys(completed)?.find(item => item == step);
    if (isKeyPresent) {
        const newObj = removeItemsAfterKey(completed, isKeyPresent);
        setCompleted(newObj)
    } else {
        switch (step) {
            case 1:
                if (!validateDeviceDetails()) {
                    return
                } else {
                    setCompleted({ 0: true })
                }
                break;
                default:
					break;
            }
        }
    
    setActiveStep(step)
};

    return (
        <div  ref={elementRef} className="create__site__popup__wrap">
            <Header
                text={editData.custom_fields?.cradlepoint_router_id? "Associate Circuits" : isEditData ? "Update Device" : "Create Device"}
                close={closeDevice}
            />{!editData.custom_fields?.cradlepoint_router_id ?
                <Stepper alternativeLabel nonLinear activeStep={activeStep} className="steps_wrap" connector={<QontoConnector />}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]} >
                            <StepLabel onClick={handleStep(index)} StepIconComponent={(props) => <ColorlibStepIcon {...props} showDeviceIcon={true} />}>{label}</StepLabel>

                        </Step>
                    ))}
                </Stepper> :
                <Stepper alternativeLabel nonLinear activeStep={activeStep} className="steps_wrap" connector={<QontoConnector />}>
                    {["Associate Circuits"].map((label, index) => (
                        <Step key={label} completed={completed[index]} >
                            <StepLabel onClick={handleStep(index)} StepIconComponent={(props) => <ColorlibStepIcon {...props} showDeviceIcon={false} showCircuitIcon={true} />}>{label}</StepLabel>

                        </Step>
                    ))}
                </Stepper>
            }
            <div>
				<div className="stepper__content">
            {
                activeStep === 0 && !editData.custom_fields?.cradlepoint_router_id? (
                    isLoading ? (
                        <ProgressLoading />
                    ) : (
                    <Box className="formWrapper site_formWrap">
                        {!isEditData && (
                            <Alert variant="outlined" severity="error" style={{ marginBottom: '22px' }}>
                                Only switches and Fortinet devices can be added manually. For other service devices, please use the device mapping page to map them to their corresponding sites.
                            </Alert>
                        )}
                        <FormLayout
                            parameters={filterParameter}
                            formValues={formValues}
                            markError={markError}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            handleInputChangeSide={handleInputChangeSide}
                            handleSelectStatus={handleSelectStatus}
                            handleOnfocus={handleOnfocus}
                            handleClose={handleClose}
                            handleClick={handleOpendeviceType}
                            isSiteNamesLoading={isSiteNamesLoading}
                            menuPlace={"top"}
                            nameErrorMsg={nameError}
                            isError={isError}
                        />
                    </Box>)
                ) : activeStep === 1 || editData.custom_fields?.cradlepoint_router_id ? (
                   
                    <AssociateCircuitsPopup  circuitList={circuitList}
                     handleSelectWanCircuits = {handleSelectWanCircuits} 
                     handleSelectInternalPortCircuits = {handleSelectInternalPortCircuits}
                     isEdit = {isEditData}
                     formValues={formValues}
                     isunmapLoading  = {unmapLoader}
                     isWanLoading = {wanLoader}
                     isInternalLoading = {internalLoader}/>
                ): null
            }
            </div>
            {!editData.custom_fields?.cradlepoint_router_id?
            <Box className="justify-between buttonWrap">
					<MuiThemedButton
					variant="outlined"
					className={user?.themePreference === 'light'?"button-outlined" :''}
						disabled={activeStep === 0}
						onClick={handleBack}
						sx={{ mr: 1 }}
					>
						Back
					</MuiThemedButton>
					{activeStep !== steps.length &&			
                        <ButtonGroup className="flex" gap={"12px"}>

                            {completedSteps() === totalSteps() - 1 ? "" :
                                <MuiThemedButton disable={!(formValues?.device_role?.label === 'Firewall')} variant="contained" style={{ marginRight: '12px', borderRadius: '3px' }} onClick={handleComplete} >Next</MuiThemedButton>
                            }
                            {
                                <MuiThemedButton
                                    showLoader={user?.isLoading}
                                    disable={(activeStep == 0 && formValues?.device_role?.label === 'Firewall') || unmapLoader}
                                    className={user?.themePreference === 'light' ? "button-outlined" : ''} variant="outlined"
                                    style={{ borderRadius: '3px' }}
                                    onClick={activeStep == 0 ? handleSubmit : handleSubmitAssociateCircuits}>
                                    Save and Exit
                                </MuiThemedButton>
                            }

                        </ButtonGroup>
						}
				</Box>:   <Box className="buttonWrap" justifyContent={'flex-end'}>
                <MuiThemedButton
                                    showLoader={user?.isLoading}
                                    className={user?.themePreference === 'light' ? "button-outlined" : ''} variant="outlined"
                                    style={{ borderRadius: '3px' }}
                                    onClick={!selectCircuit || Object.keys(selectCircuit).length === 0 ?  handleClose : handleSubmitAssociateCircuits}>
                                    { !selectCircuit ||  Object.keys(selectCircuit).length === 0 || selectCircuit == null ? 'Close' : 'Save'}
                                </MuiThemedButton>
                </Box>
}
                </div>
            <Suspense fallback={<div></div>}>
                {openDeviceType &&
                    <FormPopup open={openDeviceType} size={"deviceModal "} className={"device_modal"}>
                        <div className="create__site__popup__wrap">
                            <Header
                                text={"Create Device Type"}
                                close={closeDeviceType}
                            />
                            <Grid container marginLeft={'20px'} item xs={6} marginTop={'20px'}>
                                <FormGroup className="form_group_wrap">
                                    <InputLabel className="input_label" disabled={false}>
                                        Manufacturer <span className="astrick">*</span>
                                    </InputLabel>
                                    <ThemedReactSelect
                                        classNamePrefix={`${user?.themePreference === 'dark' ? '' : 'react-select '} ${(!formValues.manufacturer || Object.keys(formValues)?.length === 0) && createDeviceError ? ' react__select__required' : ''}`}
                                        isClearable
                                        onChange={handleSelectmanufacturer}
                                        name={"tenant"}
                                        placeholder="Select"
                                        options={manufacturerList.map((data) => {
                                            return {
                                                value: data.id,
                                                label: data.name,
                                            }
                                        })}
                                        value = {formValues.manufacturer}
                                        isDisabled={false}

                                    />
                                    <br />
                                    <InputGroup
                                        label={"Model"}
                                        classname={`input_field 
                                            ${(formValues?.model?.length === 0  || Object.keys(formValues)?.length === 0 || 
                                                !('model' in formValues)) && createDeviceError ? ' input__required' : ''}`}
                                        value={formValues['model']}
                                        disable={false}
                                     
                                        isRequired={true}
                                        setValues={(e) => handleInputChange(e, 'model')}
                                        handleOnBlur={() => { }}
                                        placeholder={`Enter Model`}
                                        maxChar={20}
                                        helperText={nameError["msg"]}
										error={isError}
                                    />

                                </FormGroup>
                            </Grid>
                            <br />
                            <Footer isLoading={isDeviceLoad} text={"Save"} submit={handleDeviceTypeSubmit} />
                        </div>
                    </FormPopup>}
            </Suspense>

        </div>
    );
};

export default CreateDevice;
