import React from 'react'
import { MuiThemedCircularProgress } from './MuiThemedComponents';

export default function LogoutLoader() {
    
  return (
      <div className="loader-container" >
          <div className='progress' style={{ color: 'black' }}>
              <MuiThemedCircularProgress />
              Logging off securely. Please wait...
          </div>
      </div>
  )
}
