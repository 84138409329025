import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import axios from "services/Axios";

let base_url = process.env.REACT_APP_BASE_URL;

export const fetchRoles = createAsyncThunk(
    "appReports/fetchRoles",
    async (data) => {
        const { tenantId, tenantName, userId} = data;
		let url = "";
        if (tenantName !== "Sangoma" && tenantId) {
			url = `${base_url}/roles/byTenantId/${tenantId}?userId=${userId}`;
		} else {
			url = `${base_url}/roles`;
		}
        const response = await axios.get(url);
        return response.data;
    }
);

export const deleteRole = createAsyncThunk(
    "appReports/deleteRole",
    async (id) => {
        const response = await axios.delete(`${base_url}/roles/${id}`);
        return response.data;
    }
);

export const updateRole = createAsyncThunk(
    "appReports/updateRole",
    async (formData) => {
        const response = await axios.put(`${base_url}/roles/${formData.id}`, formData);
        return response.data;
    }
);

export const createRole = createAsyncThunk(
    "appReports/createRole",
    async (formData) => {
        const response = await axios.post(`${base_url}/roles`, formData);
        return response.data;
    }
);

export const fetchUsers = createAsyncThunk(
    "appReports/fetchUsers",
    async (data) => {
        const { tenantId, tenantName, ...req } = data;
		let url = "";
        if (tenantName !== "Sangoma" && tenantId) {
			url = `${base_url}/users/byTenantId/${tenantId}`;
		} else {
			url = `${base_url}/users`;
		}
        const response = await axios.get(url, {params: {...req}});
        return response.data;
    }
);

export const deleteUsers = createAsyncThunk(
    "appReports/deleteUsers",
    async (id) => {
        const response = await axios.delete(`${base_url}/users/${id}`);
        return response.data;
    }
);

export const resetPasswordUsers = createAsyncThunk(
    "appReports/resetPasswordUsers",
    async (data) => {
        const response = await axios.post(`${base_url}/users/resetpassword`, data);
        return response.data;
    }
);

export const updateUsers = createAsyncThunk(
    "appReports/updateUsers",
    async (formData) => {
        const response = await axios.put(`${base_url}/users/${formData.id}`, formData.data);
        return response.data;
    }
);


export const updateUserThemePreference = createAsyncThunk(
    "appReports/updateTheme",
    async (data) => {
        const response = await axios.put(`${base_url}/users/updateTheme/${data?.id}`, data);
        return response.data;
    }
);

export const createUsers = createAsyncThunk(
    "appReports/createUsers",
    async (formData) => {
        const response = await axios.post(`${base_url}/users`, formData);
        return response.data;
    }
);

export const getSiteGroupByTenantId = createAsyncThunk("appReports/site_groups",
    async (req) => {
        const response = await axios.get(`${base_url}/site-groups/byTenantIds`, {params : req});
        return response.data;
    });

export const fetchUserRoles = createAsyncThunk(
    "appReports/fetchUserRoles",
    async () => {
        const response = await axios.get(`${base_url}/users/roles`);
        return response.data;
    }
);

export const fetchRolePermissions = createAsyncThunk(
    "appReports/fetchRolePermissions",
    async (req) => {
        const response = await axios.get(`${base_url}/role-permissions`, {params: {...req}});
        return response.data;
    }
);

export const upsertRolePermission = createAsyncThunk(
    "appReports/upsertRolePermission",
    async (formData) => {
        const response = await axios.post(`${base_url}/role-permissions`, formData);
        return response.data;
    }
);

export const fetchExisitingResources = createAsyncThunk(
    "appReports/fetchExisitingResources",
    async (req) => {
        const response = await axios.get(`${base_url}/role-permissions/resources`, {params: {...req}});
        return response.data;
    }
);

export const fetchTenantsData = createAsyncThunk(
    "nfstatus/fetchTenantsData",
    async () => {
        const response = await axios.get(`${base_url}/role-permissions/tenants`);
        return response.data;
    }
);

export const fetchTenants = createAsyncThunk(
    "nfstatus/fetchTenants",
    async () => {
        const response = await axios.get(`${base_url}/users/all/tenants`);
        return response.data;
    }
);

export const UserManagementSlice = createSlice({
    name: 'user-management',
    initialState: {},
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return UserManagementSlice.getInitialState();
        });
    },

})

export const { setLoading, setTenant, setUser } = UserManagementSlice.actions;
export default UserManagementSlice.reducer
