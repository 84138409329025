import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useCanAccess } from 'services/canACLAccess';
import { useSelector } from 'react-redux';


const PrivateRoutes = () => {
    const navigate = useNavigate();
    const canAccess = useCanAccess();
    const user = useSelector((state) => state.user);


    const checkPermissionAndRedirect = (path, module) => {
        const isAllowed = Array.isArray(module)
            ? module.some(mod => canAccess('read', mod))
            : canAccess('read', module);
        if (window.location.pathname.includes(path) && !isAllowed && user?.status === 'logged-in') {
            navigate('/nf-status/access-denied');
        }
    };

    const routesConfig = [
        { path: '/tenants', modulE: 'tenants' },
        { path: '/site', modulE: 'sites' },
        { path: '/binding', modulE: 'binding' },
        { path: '/sync-inventory', modulE: 'sync-inventory' },
        { path: '/inventory', modulE: 'devices' },
        { path: '/service', modulE: 'services' },
        { path: '/circuit-link-monitoring', modulE: 'circuit-monitoring' },
        { path: '/link-monitoring', modulE: 'link-monitoring' },
        { path: '/gateway-monitoring', modulE: 'link-monitoring' },
        { path: '/change-logs', modulE: 'changelogs' },
        { path: '/user-management', modulE: ['roles', 'users', 'role-permissions'] },
        { path: '/flapping-logs', modulE: ['eventlogs'] },
        { path: '/tickets', modulE: ['tickets'] },
        { path: '/ticket', modulE: ['tickets'] },
    ];

    useEffect(() =>{
        routesConfig.forEach(({ path, modulE }) => checkPermissionAndRedirect(path, modulE));
    },[location.pathname])

    return <Outlet />;
};

export default PrivateRoutes;