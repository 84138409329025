import { useState } from 'react';
import { MuiThemedButton } from './MuiThemedComponents';
import { Popper, ClickAwayListener } from "@mui/material";
import TooltipLabel from './TooltipLabel/TooltipLabel';
import { components } from "react-select";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomClearText = ({ onChange, defaultFilter, menuIsOpen }) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null)

    const onClickCrossIcon = (e) => {
        if (defaultFilter) {
            setAnchorEl(e?.currentTarget);
            setOpenPopup(true);
        }
        else {
            onChange([], 'remove_all');
        }
    }

    const handleClosePopup = () => {
        setAnchorEl(null);
        setOpenPopup(false);
    }

    const onFilterClick = (e, action) => {
        const value = action === 'remove_all' ? [] : defaultFilter;
        e.preventDefault();
        onChange(value, action);
        handleClosePopup();
    }

    return (
        <div>
            <ClickAwayListener onClickAway={() => {
                if (openPopup) {
                    handleClosePopup();
                }
            }}>
                <FontAwesomeIcon icon="fa-solid fa-xmark" onClick={(e) => !menuIsOpen && onClickCrossIcon(e)} style={{ cursor: 'pointer', opacity: '0.4' }} />
            </ClickAwayListener>
            {openPopup && <Popper
                anchorEl={anchorEl}
                open={true}
                placement="bottom-end"
                disablePortal={false}>

                <div className={'custom_remove_wrap'}>
                    <div onClick={(e) => { e.stopPropagation(); onFilterClick(e, 'remove_all') }} className='remove_options'>Remove All</div>
                    <div onClick={(e) => { e.stopPropagation(); onFilterClick(e, 'default_filter') }} className='remove_options'>Set Default Filter</div>
                </div>
            </Popper>}
        </div>
    )
};

const ClearIndicator = (props) => {
    const { onChange, defaultFilter, menuIsOpen } = props.selectProps;
    const {
        children = <CustomClearText onChange={onChange} defaultFilter={defaultFilter} menuIsOpen={menuIsOpen} />,
        innerProps: { ref, ...restInnerProps },
    } = props;


    return (

        !menuIsOpen && (
            <div {...restInnerProps} ref={ref}>
                <div style={{ padding: '0px 5px' }}>
                    {children}
                </div>
            </div>
        )

    );
};



const Value = ({ index, getValue, tooltipPlacement }) => {
    const selectedOptions = getValue();
    const allSelected = selectedOptions && selectedOptions?.length && selectedOptions?.filter(i => typeof i.value === 'string' && i?.value?.toLowerCase() === 'all')
    const displayValue = allSelected?.length > 0 ? allSelected?.[0]?.label
        : selectedOptions?.map(item => item?.label)?.join(", ")
    return (!index &&
        <TooltipLabel tooltipPlacement={tooltipPlacement} removeDataTag={true} width={'85%'} text={`${displayValue}`} />);
}

const Menu = ({ children, ...props }) => {
    const { onApplyClick, placeholder, disableApply } = props.selectProps
    return (
        <components.Menu {...props}>
            <>
                {children}
                {onApplyClick && <div className='multi-select-btn'>
                    <MuiThemedButton onClick={() => onApplyClick(placeholder)} className="import_btn btn" variant="contained" size="small" color="primary" disable={disableApply}>
                        APPLY
                    </MuiThemedButton>
                </div>}
            </>
        </components.Menu>
    );
};


const Control = ({ children, ...props }) => {
    const { hideFilterIcon } = props.selectProps;
    const user = useSelector(state => state?.user)
    return (
        <components.Control style={{ width: "auto" }} {...props}>
            {!hideFilterIcon && <div
                style={{
                    paddingLeft: "10px",
                    color: user?.themePreference === "dark" ? "#ede5e5" : "rgba(0, 0, 0, 0.54)",
                }}>
                <FontAwesomeIcon icon="fa-solid fa-filter" />
            </div>}
            {children}
        </components.Control>
    )
};

const InputOption = ({
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex "
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };
    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" checked={isSelected} style={{ marginRight: '6px' }} readOnly />
            {children}
        </components.Option>
    );
};

const customStyle = (containerWidth, backgroundColor) => ({
    option: (base) => ({
        ...base,
        textTransform: 'capitalize',
    }),
    valueContainer: (base) => ({
        ...base,
        textTransform: 'capitalize',
    }),
    singleValue : (base, prop) => ({
        ...base,
        color : backgroundColor && !prop.isDisabled ? '#fff' : base.color
    }),
    container: (base) => ({
        ...base,
        width: containerWidth,

    }),
    control: (base, prop) => ({
        ...base,
        '&:hover': {
            border: '1.2px solid hsl(0, 0%, 60%)'
        },
           backgroundColor: backgroundColor && !prop.isDisabled ? backgroundColor : base.backgroundColor,
        }),
    placeholder: (base, prop) => ({
        ...base,
        color : backgroundColor && !prop.isDisabled ? '#fff' : base.color
    })
})

const darkThemeReactSelectStyles = (containerWidth) => ({
    option: (provided, state) => ({
        ...provided,
        textTransform: 'capitalize',
        borderBottom: '1px solid #caced6',
        color: state.isSelected ? '#262a30' : '#fff',
        backgroundColor: state.isSelected ? '#999eaa' : '',
        boxShadow: '0 0 0 1px hsl(0deg 2.98% 58.56% / 10%), 0 4px 11px hsl(0deg 6.08% 86.38% / 10%)',
        '&:active': {
            backgroundColor: '#dee1e7db',
            color: ' #393030'
        },
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        '&:hover': {
            color: 'hsl(0, 0%, 60%)',
        }
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        '&:hover': {
            color: 'hsl(0, 0%, 60%)',
        }
    }),
    control: (provided) => ({
        ...provided,
        boxShadow: '0 0 0 1px #54606d',
        backgroundColor: 'transparent !important',
        borderColor: '#383d43 !important',
        textTransform: 'capitalize',
        '&:hover': {
            border: '1.2px solid hsl(0, 0%, 60%) !important'
        }


    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#fff'
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#31363F !important',
    }),
    container: (base) => ({
        ...base,
        width: containerWidth
    })

})



export {
    darkThemeReactSelectStyles, customStyle, InputOption, Control, Menu, Value, ClearIndicator, 
}