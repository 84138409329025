import { useEffect, useRef, useState } from "react";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

const DateRange = ({ onChange, setFilterDate }) => {
	const user = useSelector((state) => state.user);
	// ** Hooks
	const myRef = useRef();
	const [selectedRange, setSelectionRange] = useState({
		endDate: new moment().endOf("day"),
		startDate: new moment().subtract(1, "week").startOf("day"),
	});

	const [selectedDate, setselectedDate] = useState("");

	useEffect(() => {
		// onChange(selectedRange);
		setFilterDate && setFilterDate(selectedDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRange, selectedDate]);

	const handleSelect = async (startDate, endDate) => {
		// Date range should be within 32 days;
		let dateDiff = moment(endDate).diff(moment(startDate), "days");
		if (dateDiff > 33) {
			myRef.current.setStartDate(selectedRange.startDate);
			myRef.current.setEndDate(selectedRange.endDate);
			toast.error(
				`Error: Selected date range of ${dateDiff} days exceeds the maximum allowable duration of 33 days. Please choose a range within the supported limit.`
			);
		} else {
			await setSelectionRange({ startDate, endDate });
			await setselectedDate(
				`${moment(startDate).format("MM/DD/YYYY h:mm A")} - ${moment(
					endDate
				).format("MM/DD/YYYY h:mm A")}`
			);
		}
	};

	const setDates = (e, { startDate, endDate }) => {
		if(e.type === 'cancel'){
			setselectedDate('')
			return onChange()
		}
		
		onChange({
			startDate: startDate.format("YYYY-MM-DD HH:MM"),
			endDate: endDate.format("YYYY-MM-DD HH:MM")
		});
	};

	return (
		<div  className={user?.themePreference === 'dark' ?"date_ranger_picker_wrapper date_ranger_picker_wrapper__darkMode": "date_ranger_picker_wrapper"}>
			<div className="form-group flex-start">
				<div className=" flex date_filter mb-1 mt-2">
					
					<DateRangePicker
					
						ref={myRef}
						onCallback={handleSelect}
						onCancel = {setDates}
						onApply = {setDates}
						className="filter"

						key={selectedRange}
						initialSettings={{
							autoUpdateInput: false,
							startDate: selectedRange?.startDate,
							endDate: selectedRange?.endDate,
							maxDate: moment().toDate(),
							format: "MM/DD/YYYY h:mm A",
							locale: {
								format: "MM/DD/YYYY h:mm A",
							},
							timePicker: false,
							timePickerIncrement: 1,
							opens: "right",
							ranges: {
								Today: [moment().startOf("day"), moment().endOf("day")],
								Yesterday: [
									moment().subtract(1, "days").startOf("day"),
									moment().subtract(1, "days").endOf("day"),
								],
								"Last 7 Days": [
									moment().subtract(6, "days").startOf("day"),
									moment().endOf("day"),
								],
								"Last 30 Days": [
									moment().subtract(29, "days").startOf("day"),
									moment().endOf("day"),
								],
								"This Month": [
									moment().startOf("month").startOf("day"),
									moment().endOf("month").endOf("day"),
								],
								"Last Month": [
									moment().subtract(1, "month").startOf("month").startOf("day"),
									moment().subtract(1, "month").endOf("month").endOf("day"),
								],
							},
						}}
					>
						<div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
						<FontAwesomeIcon icon="fa-solid fa-filter" style={{marginRight: '5px'}}/>
						<input
							className={user?.themePreference === 'dark' ? "mb-0 date_range_picker form-control col-4 date_range_picker__darkMode" : "mb-0 date_range_picker form-control col-4"}
							type="text"
							name="daterange"
							placeholder="Date filter"
							value={selectedDate}
							defaultValue={""}
							autoComplete="off"
						/>
						</div>
					</DateRangePicker>
				</div>
			</div>
		</div>
	);
};

export default DateRange;
