/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid } from '@mui/material';
import FormPopup from 'components/common/modal/FormPopup';
import Header from 'components/common/modal/Header';
import {MuiTypography } from 'components/common/MuiThemedComponents';
import MultiSelectDropDown from 'components/common/MultiSelectDropDown';
import SelectDropDown from 'components/common/SelectDropdown';
import StatusComponent from 'components/common/statusComponent';
import TableComponent from 'components/common/TableComponent/TableComponent';
import TooltipLabel, { StyledTooltip } from 'components/common/TooltipLabel/TooltipLabel';
import { dateOptions, DurationFormat, statusOptions } from 'components/component/link/LinkHistory';
import Timer from 'components/component/link/Timer';
import { DateTime, Interval } from 'luxon';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSitename } from 'store/devices';
import { fetchPhoneFlappingLogsData, fetchVoiceScoreData } from 'store/phones';
import TabsComponet from 'components/common/TabsComponet/TabsComponet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CopyTextCell from 'components/common/CopyTextCell/CopyTextCell';
import { customSort } from 'utils/Utilities';
import moment from 'moment-timezone';

const PhoneFlappingHistoryPopup = ({ openPopup, data, handleClosePopup }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [flappingHistoryData, setFlappingHistoryData] = useState([]);
    const [voiceScoreData, setVoiceScoreData] = useState([])
    const [isFetchingDetails, setIsFetchingDetails] = useState(false);
    const [dateFilter, setDateFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [phoneReferenceData, setPhoneReferenceData] = useState([]);
    const user = useSelector((state) => state.user);
    const [openEventData, setOpenEventData] = useState(false);
    const [eventData, setEventData] = useState(false);

    const applyFilters = useCallback((statusFilterValue, dateFilterValue) => {
        const today = DateTime.now();
        const startOfWeek = today.startOf('week');
        const endOfWeek = today.endOf('week');
        const startOfMonth = today.startOf('month');
        const endOfMonth = today.endOf('month');

        const stFilter = statusFilterValue?.map((i) => i.value.toLowerCase()) || [];
        const isFilterAll = stFilter.includes('all');
        const hasStatusFilter = stFilter.length > 0 && !isFilterAll;

        const filteredData = phoneReferenceData?.filter((item) => {
            const createdAt = DateTime.fromMillis(parseInt(item?.event_time, 10));
            const status = item?.status?.toLowerCase();

            // Date filter
            if (dateFilterValue?.value === 'today' && !createdAt.hasSame(today, 'day')) return false;
            if (dateFilterValue?.value === 'week' && (createdAt < startOfWeek || createdAt > endOfWeek)) return false;
            if (dateFilterValue?.value === 'month' && (createdAt < startOfMonth || createdAt > endOfMonth)) return false;

            // Status filter
            if (hasStatusFilter && !stFilter.includes(status)) return false;

            return true;
        });

        setFlappingHistoryData(filteredData);
    }, [phoneReferenceData]);

    const onChangeDateFilter = useCallback((option) => {
        setDateFilter(option);
        applyFilters(statusFilter, option);
    }, [applyFilters, statusFilter]);

    

    const onChangeStatusFilter = useCallback((option) => {
        setStatusFilter(option);
        applyFilters(option, dateFilter);

    }, [applyFilters, dateFilter]);


    const fetchPhoneFlappingHistoryData = useCallback(async () => {
        if (!data?.extension_uuid) {
            return
        }
        setIsFetchingDetails(true);
        try {
            const [response, voiceScore] = await Promise.all([
                dispatch(fetchPhoneFlappingLogsData({ extensionId: data?.extension_uuid }))?.unwrap(),
                process.env.REACT_APP_VOICE_SCORE_ENABLED && dispatch(fetchVoiceScoreData({ extensionId: data?.extension_uuid }))?.unwrap()
            ]);

            if (response && response?.length > 0) {
                const updatedData = response?.map((item, index) => {
                    let prevData = response[index - 1]
                    return (
                        {
                            ...item,
                            last_status_time: prevData && index !== 0 ? DateTime.fromMillis(parseInt(prevData?.event_time)) : null,
                        })

                })
                setFlappingHistoryData(updatedData);
                setPhoneReferenceData(updatedData);

            }
            if(voiceScore && voiceScore.length){
                setVoiceScoreData(voiceScore);
            }
        } catch (error) {
            console.error("Error fetching tenants data:", error);
        } finally {
            setIsFetchingDetails(false);
        }

    }, [data?.extension_uuid, dispatch])

    const tableColumns = useMemo(() => {
        return [
            {
                id:"status",
                name: "status",
                title: "status",
                sortable: true,
                wrap: true,
                width: 130,
                cell: (row) => <StatusComponent status={row.status} />,
                isEnabled: true

            },
            {
                cell: (row) => row?.event_time ? DateTime.fromMillis(parseInt(row?.event_time)).toFormat("yyyy-MM-dd h:mm a") : "--",
                name: "DATETIME (UTC)",
                id: "event_time",
                sortable: true,
                wrap: true,
                width: 130,
                isEnabled: true,
                title: "DATETIME (UTC)",

            },
            {
                cell: (row) => {
                    let date = row?.event_time && row?.last_status_time !== null ? Interval.fromDateTimes(DateTime.fromMillis(parseInt(row?.event_time)), row?.last_status_time).toDuration(['years', 'months', 'weeks', 'days',
                        'hours', 'minutes', 'seconds']).toObject()
                        : "--";

                    let format = date === '--' ? <Timer targetDate={row.event_time} /> : DurationFormat(date);
                    return (
                        <span>
                            {format}
                        </span>
                    )
                },

                title: "Duration",
                name: "duration",
                id: "up/down_duration",
                sortable: false,
                wrap: true,
                width: 130,
                isEnabled: true

            },
        ]
    }, [])

    const voiceScoreColumns = useMemo(() => {
        return [
            {
                name: "Call Quality",
                title: "Call Quality",
                sortable: false,
                wrap: true,
                width: 130,
                cell: (row) => {
                    const color = row?.score >= 4? 'green' : row?.score < 4 && row?.score >= 3.1 ? 'orange' : 'red';
                    const text =  row?.score >= 4 ? 'Good' : row?.score < 4 && row?.score >= 3.1 ? 'Fair' : 'Bad';
                    return (
                        <div style={{alignItems: 'center', display: 'flex'}}>
                            <FontAwesomeIcon icon="fa-solid fa-check" color ={color} style={{marginRight: '7px', height: '20px'}}/> 
                            <TooltipLabel text={text}/>
                        </div>
                    )
                },
                isEnabled: true

            },
            {
                cell: (row) => {
                    const eventTime = row?.event_time && !isNaN(Number(row.event_time))
                        ? moment(Number(row.event_time)).utc().format("YYYY-MM-DD h:mm A")
                        : null;

                    return (
                        eventTime ? <TooltipLabel text={eventTime} /> : '--'
                    )
                },
                name: "DATETIME (UTC)",
                sortable: true,
                wrap: true,
                width: 130,
                isEnabled: true,
                title: "DATETIME (UTC)",
                id: "event_time",

            },
            {
                id: "score",
                name: "MOS Score",
                title: "MOS Score",
                sortable: true,
                wrap: true,
                width: 130,
                cell: (row) => <TooltipLabel text={row.score} />,
                isEnabled: true

            },
            {
                id:"call_id",
                name: "Call UUID",
                title: "Call UUID",
                sortable: true,
                wrap: true,
                width: 130,
                cell: (row) => <CopyTextCell text={row.call_id} />,
                isEnabled: false

            },
            {
                id:"caller_id",
                name: "Caller Id",
                title: "Caller Id",
                sortable: true,
                wrap: true,
                width: 130,
                cell: (row) => <CopyTextCell text={row.caller_id} />,
                isEnabled: true
            },
            {
                name: "event data",
                title: "event data",
                sortable: false,
                wrap: true,
                width: 130,
                cell: (row) => row?.raw_data ?   (
                    <span
                        onClick={() => {
                            setOpenEventData(true);
                            setEventData(row?.raw_data);
                        }}
                        style={{
                            color: '#1976d2',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        Details
                    </span>
                ) : (
                    '--'
                ),
                isEnabled: user?.tenant?.name !== "Sangoma"? false : true
            },
        ]
    }, [])

    useEffect(() => {
        fetchPhoneFlappingHistoryData()
    }, [])

    const flappedDataProp = {
        flappingHistoryData : flappingHistoryData,
        isFetchingDetails : isFetchingDetails,
        onChangeDateFilter : onChangeDateFilter,
        onChangeStatusFilter : onChangeStatusFilter,
        statusFilter:statusFilter,
        tableColumns: tableColumns
    }

    const voiceScoreProps = {
        className : `sync__report rdt_table_wrap `,
        columns : voiceScoreColumns,
        data :voiceScoreData,
        isLoading : isFetchingDetails
    }


    const tabOptions = useMemo(() => {return [
        { id: 'Flapping Data', name: 'Flapping Data', component: <FlappingComponent {...flappedDataProp}/> , value: 'mapped' ,isEnabled: true },
        { id: 'Voice Score', name: 'Voice Score', component: <VoiceScoreComponent {...voiceScoreProps}/>, value: 'unMapped', isEnabled: process.env.REACT_APP_VOICE_SCORE_ENABLED }
    ]
    }, [flappedDataProp, voiceScoreProps]);

    const closeEventData = async () => {
        setOpenEventData(false);
        setEventData(null
        )
    };

    return (
        <FormPopup size={"modal_size"} open={openPopup} className={"link_wrapper flapping_history_popup"} >
            <Header text={"Flapping and Voice Score Logs"} close={handleClosePopup} />

            {data?.site_id ?
                <Box className=" site_service_popup flex-start link_heading">
                    <MuiTypography className="head">Site Display Name : </MuiTypography>

                    {(data?.site_display_name || data?.site_name) &&
                        <Button className="text" onClick={() => {
                            navigate('/nf-status/site', { state: { siteName: data?.site_id, siteDisplayName: data?.site_display_name ? data?.site_display_name : data?.site_name } });
                            dispatch(setSitename(data?.site_id))
                        }}>
                            <StyledTooltip placement="right" title={`Site Name: ${data?.site_name}`} >
                                <span className='label__loader'>
                                    {data?.site_display_name ? data?.site_display_name : data?.site_name}
                                </span>
                            </StyledTooltip>
                        </Button>
                    }

                </Box> : null
            }

            <Box className="site_service_popup_name flex-start link_heading ">
                <MuiTypography className="head" >Extension Name</MuiTypography>
                <Button className="text" sx={{pointerEvents: 'none'}} onClick={() => { }}>{data?.name}</Button>
            </Box>
            <Box className="phoneFlappingformWrapper" >
            <TabsComponet tabs={tabOptions.filter(i => i.isEnabled)} variant="fullWidth"/>
            </Box>
            <Suspense fallback={<div></div>}>
                {openEventData &&
                    <FormPopup open={openEventData} size={"deviceModal"} className={"event_modal"} >
                    <div className="create__site__popup__wrap">
                        <Header text={"Event Data"} close={closeEventData} />
                        <Grid 
                            container 
                            item 
                            xs={12}
                            marginTop={'20px'} 
                            style={{
                                maxHeight: '400px',
                                overflow: 'auto', 
                                wordWrap: 'break-word',
                                padding: '10px',
                                borderRadius: '4px',
                            }}
                        >
                            <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                                {JSON.stringify(eventData, null, 2)}
                            </pre>
                        </Grid>
                    </div>
                </FormPopup>
                }
            </Suspense>
        </FormPopup>
    )
}

const FlappingComponent = ({flappingHistoryData, isFetchingDetails, onChangeDateFilter, onChangeStatusFilter, statusFilter, tableColumns}) => {
    const [filteredData, setFilteredData] = useState(flappingHistoryData);
    const getCustomSort = (column, direction) => {      
		const sortedData = customSort(column, direction, filteredData.length> 0 ? filteredData : flappingHistoryData);
			setFilteredData([...sortedData]);
	};

    useEffect(() => {
        setFilteredData(flappingHistoryData);
    }, [flappingHistoryData]);  

    return (
        <Box>
        <Box className="link_heading_wrap">
            <Box className="site_service_popup flex-start link_heading">
                <MuiTypography className="head">Count : </MuiTypography>
                <MuiTypography className="text">{flappingHistoryData?.length}</MuiTypography>
            </Box>
            <Box className="site_service_popup flex-start link_heading">
                <div className='flapping_status_filter'>
                    <MultiSelectDropDown options={statusOptions} containerWidth={'200px'} onChangeValue={onChangeStatusFilter} placeholder={"Status..."} value={statusFilter} />
                </div>
                <SelectDropDown options={dateOptions} onChange={onChangeDateFilter} placeholder={"Date Range..."} />
            </Box>
        </Box>
        <Box className="phoneFlappingformWrapper" >
            <TableComponent
                className={`phoneFlappingWrapper`}
                columns={tableColumns}
                data={filteredData.length> 0 ? filteredData : flappingHistoryData}
                isLoading={isFetchingDetails}
                customSortFunction={getCustomSort}
            />
        </Box>
        </Box>
    )
}

const VoiceScoreComponent = ({columns, data, isLoading}) => {
    const [filteredData, setFilteredData] = useState(data);
    const getCustomSort = (column, direction) => {      
		const sortedData = customSort(column, direction, filteredData.length> 0 ? filteredData : data);
			setFilteredData([...sortedData]);
	};

    return (<Box className="phoneFlappingformWrapper" >
    <TableComponent
                className={`phoneFlappingWrapper`}
                columns={columns}
                data={filteredData.length> 0 ? filteredData : data}
                isLoading={isLoading}
                customSortFunction={getCustomSort}
            />
    </Box>)
}

export default PhoneFlappingHistoryPopup
