import Box from "@mui/material/Box";
import React from "react";
import ButtonLayout from "../Button";

function Footer({ submit, text, isLoading, disabled, ...props }) {
	return (
		<Box className="flex_end buttonWrap" gap={"15px"}>
			{props?.secondaryText && <ButtonLayout
				disabled={disabled}
				handleClick={props?.secondaryHandleClick}
				type="submit"
				buttonStyleType={"outlined"}
				text={props?.secondaryText}
			/>}
			<ButtonLayout
				disabled={disabled}
				handleClick={submit}
				type="submit"
				buttonStyleType={"filled"}
				text={text}
				isLoading={isLoading}
			/>
		</Box>
	);
}

export default Footer;
