import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import CopyTextCell from 'components/common/CopyTextCell/CopyTextCell';
import { MuiTypography } from 'components/common/MuiThemedComponents';
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel';
import { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

const DescriptionComponent = ({ value, redirectToLinkMonitoring, ticketType, redirectToGatewayMgt }) => {
    const user = useSelector(state => state?.user)
    const [showMoreDetails, setShowMoreDetails] = useState(false);

    const transformTitleAndValue = useCallback((title, val) => {
        if (title) {
            title = title && title?.trim();
            const newTitle = title?.toLowerCase() === "device" ? "Device Name" :
                title?.toLowerCase() === "ip" ? "IP Address" :
                    title;

            const newValue = val ? val?.trim()?.toLowerCase() === "down" ? "Down" : val?.trim() : '';

            return {
                title: newTitle,
                value: newValue
            };
        }

    },[])

    const parseStringWithFixedKeys = useCallback((str) => {

        if (str && str?.length > 0) {
            let keyValuePairs = [];
            const keys = ['Linked Device', 'Circuit','Fortinet','Gateway', 'Cradlepoint', 'WiFi', 'Device', 'Vendor', 'Ip', 'Status', 'Service', 'Serial', 'Device status', 'Connection status', 'Registration status' ];

            keys.forEach(key => {

                let regex = new RegExp(`(?:^|\\|)\\s*${key}:\\s*([^|]*)`, 'i');

                let match = str?.match(regex);

                if (match) {
                    let value = match[1]?.trim();
                    if (value?.endsWith('|')) {
                        value = value?.slice(0, -1).trim();

                    }

                    let transformed = transformTitleAndValue(key, value);
                    let res = {
                        title: transformed?.title,
                        value: transformed?.value
                    }
                    keyValuePairs?.push(res);
                }
            });

            return keyValuePairs;
        }
    },[transformTitleAndValue])


    const parsedDeviceInfo = useCallback((showMore) => {
        if (value === '--') return null;
        const descriptionValue = value?.split("\n")
        let res = descriptionValue && descriptionValue?.length > 0 && descriptionValue?.map(value => {
            return parseStringWithFixedKeys(value)
        })
        if (showMore) {
            return res
        } else {
            return res?.slice(0, 2)
        }
    }, [parseStringWithFixedKeys, value]);

    const renderValue = (item, detail) => {
        const ipAddress = detail?.find(i => i?.title?.toLowerCase() === "ip address");
        const value = item?.value || "--";
        switch (item.title.toLowerCase()) {
            case "ip address":
                return <CopyTextCell showWithTooltip={true} text={value} fontStyle={{ fontWeight: "500", fontSize: "12px" }} />;
            case "device name":
            case "circuit":
            case "linked device":
            case 'fortinet':
            case 'cradlepoint':
            case 'wifi':
                return (
                    <Typography
                        component="div"
                        className="description-value device_name"
                        onClick={(e) => { e?.stopPropagation(); redirectToLinkMonitoring(value, ipAddress?.value) }}
                    >
                        <TooltipLabel text={value} />
                    </Typography>
                );
            case 'serial':
                return (
                    <Typography
                        component="div"
                        className="description-value device_name"
                        onClick={(e) => { e?.stopPropagation(); redirectToGatewayMgt(value) }}
                    >
                        <TooltipLabel text={value} />
                    </Typography>
                );
            default:
                return (
                    <Typography
                        component="div"
                        className={`description-value ${value?.toLowerCase()}`}
                    >
                        <TooltipLabel text={value} />
                    </Typography>
                );
        }
    };

    const borderValue = useCallback((idx) => {
        let totalLength = parsedDeviceInfo(true)?.length
        return totalLength > 1 && !(idx === (totalLength - 1) && !showMoreDetails) ? "1px solid #E0E0E0" : "0px"
    }, [parsedDeviceInfo, showMoreDetails])   

    if (ticketType === "external" && value) {
        return <Typography className='external__ticket__description' component={"div"} sx={{ background: user?.themePreference === 'dark' ? '#434f6285' : '#F5F5F5' }}>
            {typeof value === "string"
                ? value.split('\n').map((line, index) => (
                    <Fragment key={index}>
                        {line}
                        <br />
                    </Fragment>
                ))
                : value}
        </Typography>
    } else if (ticketType === "external" && (!value || value?.length == 0)) {
        return (
            <Typography className='external__ticket__description' component={"div"} sx={{ background: user?.themePreference === 'dark' ? '#434f6285' : '#F5F5F5' }}>
                No description found
            </Typography>
        )
    }

    if (!parsedDeviceInfo(showMoreDetails)) return null;

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }} className="description__wrapper">
            {parsedDeviceInfo(showMoreDetails)?.map((item, idx) => {
                const linkedDevice = item?.some(item => item?.title === "Linked Device")
                return (
                    <Box key={idx} sx={{
                        borderBottom: borderValue(idx),
                        padding: "10px 0px 10px 16px",
                        marginLeft: linkedDevice ? '30px' : '',
                        background: user?.themePreference === 'dark' ? '#434f6285' : '#F5F5F5',
                        marginTop: linkedDevice || idx == 0 ? '' : '20px',
                        boxShadow: linkedDevice ?
                            ' rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                            : ' rgba(0, 0, 0, 0.18) 0px 2px 4px;'
                    }} >
                        {linkedDevice &&
                            <Box sx={{ alignItems: 'center', justifyContent: 'flex-start', display: 'flex', marginTop: '-17px', paddingLeft: '30px' }}>
                                <FontAwesomeIcon style={{ fontSize: '16px' }} icon="fa-solid fa-link" />
                            </Box>
                        }
                        {
                            item && item?.length > 0 && item?.map((detail, idx) => {
                                return (
                                    <Box key={idx} sx={{ display: "flex" }} className="description__row">
                                        <MuiTypography component="div" className="description">{detail.title}:</MuiTypography>
                                        {renderValue(detail, item)}
                                    </Box>
                                )
                            })
                        }
                    </Box>
                )
            }
            )}

            {parsedDeviceInfo(true)?.length > 2 && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingTop: "10px" }} onClick={() => setShowMoreDetails(!showMoreDetails)} className='ticket_show_more'>
                {showMoreDetails ? 'Show less' : 'Show more'}
            </Box>
            }
        </Box>
    );
};


export default DescriptionComponent
