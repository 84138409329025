import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import axios from "services/Axios";

let base_url = process.env.REACT_APP_BASE_URL;

export const fetchExport = createAsyncThunk(
	"appReports/fetchExport",
	async (data) => {		
		let url = `${base_url}/exports/export`;
		const response = await axios.get(url, {params :data});
		return response.data;
	}
);

export const ReportsSlice = createSlice({
	name: "exportApi",
	initialState: {},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(PURGE, () => {
			return ReportsSlice.getInitialState();
		});
	},
});

export default ReportsSlice.reducer;
