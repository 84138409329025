/* eslint-disable no-unsafe-optional-chaining */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MinusIcon from "assets/icons/minus.svg";
import PlusIcon from "assets/icons/plus.svg";
import Breadcrumbs from "components/common/Breadcrumps";
import CopyTextCell from "components/common/CopyTextCell/CopyTextCell";
import FilterStatusAndService from "components/common/FilterStatusAndService";
import GlobalExportButton from "components/common/GlobalExportButton";
import { MuiThemedBox,  ReactDataTableStyles } from "components/common/MuiThemedComponents";
import SearchInputField from "components/common/SearchInputField";
import TableComponent from "components/common/TableComponent/TableComponent";
import TooltipLabel from "components/common/TooltipLabel/TooltipLabel";
import  { ServiceFilterList } from "components/common/FilterStatusAndService";
import Timer from "components/component/link/Timer";
import { Suspense, useEffect, useState, lazy, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import  { StyledTooltip } from "components/common/TooltipLabel/TooltipLabel";
import { toast } from "react-toastify";
import { createNotesData, deleteNotesData, fetchLinkMonitorData, fetchSiteLinkData, updateNotesData } from "store/devices";

import DataTable, { createTheme } from "react-data-table-component";
import {fetchLinkMonitorStatusData } from "store/devices";
import useInterval from "components/common/useInterval";
import DeleteConfirmPopup from "components/component/userManagement/DeleteConfirmPopup";
import NotesPopup from "./NotesPopup";
import { MuiThemedCircularProgress} from 'components/common/MuiThemedComponents'
// eslint-disable-next-line no-unused-vars
import DeviceIcon from 'assets/images/linked-device-icon.svg';
import DeviceIconDark from 'assets/images/linked-device-icon-dark.svg';
import './link_monitoring.css';
import TableHeader from "components/common/TableHeader/TableHeader";
import ColumnHeaderWithTooltip from "components/common/TableComponent/ColumnHeaderWithTooltip";
import { useCanAccess } from "services/canACLAccess";
import ServicePopup from "components/component/createSite/ServicePopup";
import { getticketColumn } from "../tickets/TicketStatusComponent";
import StatusComponent from "components/common/statusComponent";
import { filterStatusList } from "../devices/Main";

const LinkHistory = lazy(() => import('components/component/link/LinkHistory'));

const customStyles = {
	tableWrapper: {
		height: "auto",
		color: "#000",
	},
};

const modeFilterOptionsList =[
	{
		value:'primary',
		label:'Primary'
	},
	{
		value:'reserved',
		label:'Reserved'
	},
]

export const StatusCell = ({row}) => {

	const getStatusField = (data) => {
		let up = 0; let down = 0; let maint = 0;
		data?.map(i => {
			if (i?.link_status?.toLowerCase() === 'maint') maint++;
			if (i?.link_status?.toLowerCase() === 'down') down++;
			if (i?.link_status?.toLowerCase() === 'up') up++;
		})
		let result = {status: 'up', class: 'status status_active'}

		if(down > 0) {
			if(down  === data?.length) {
				result.status = 'Down'
			} else if(down > 0 && (up> 0 || maint > 0)) {
				result.status = 'Degraded'
			}
		} else if(up> 0) {
			if(up === data?.length) {
				result.status = 'Up'
			} else  if (up >= down && up >= maint) {
				result.status = 'Up'
			}
		}
		else if(maint  === data?.length) {
			result.status = 'Maint'
		} 

		else if (down >= up && down >= maint) {
			result.status = 'Down'
		}
		else {
			result.status = 'Maint'
		}

		if(down > 0) {
			if(down  === data?.length) {
				result.class = 'status status_down'
			} else if(down > 0 && (up> 0 || maint > 0)) {
				result.class = 'status status_degraded'
			}
		} else if(up> 0) {
			if(up === data?.length) {
				result.class = 'status status_active'
			} else  if (up >= down && up >= maint) {
				result.class = 'status status_active'
			}
		}
		else if(maint  === data?.length) {
			result.class = 'status status_inactive'
		} 

		else if (down >= up && down >= maint) {
			result.class = 'status status_down'
		}
		else {
			result.class = 'status status_inactive'
		}
		return result;
	}

	return (
		<span data-tag="allowRowEvents" className="flex">
			<StatusComponent status={row.link_status} data-tag="allowRowEvents" style={{ borderRadius: row?.circuits ? '30px 0px 0px 30px' : 'none' }}/>
			{row?.circuits && <span data-tag="allowRowEvents" style={{ borderRadius: '0px 30px 30px 0px', borderLeft: '1px solid #fff' }}
				className={getStatusField(row?.circuits)?.class}>{getStatusField(row?.circuits)?.status}</span>}
		</span>
	)
}


export const DeviceCell = ({row}) => {
	const user = useSelector((state) => state.user);
	return (
		<div style={{display:'flex'}}>
			{row?.parent_netbox_id &&
				<Link to="/nf-status/link-monitoring/link-mapping" state={{ netboxId: row?.parent_netbox_id, circuitId : row?.device_id }} className="settings__btn">
					<StyledTooltip title={"View linked device"} >
						{
							user?.themePreference === 'dark' ? <img src={DeviceIconDark} alt="close" className="mapped_devices" />:
							<img src={DeviceIcon} alt="close" className="mapped_devices" />
						}
					</StyledTooltip>
				</Link>
			}
			<div style={{width: '100%'}}><CopyTextCell showWithTooltip={true} text={row?.name} />
			</div>
		</div>
	)
}




export const StatusFilterList = [
	{
		value: "all",
		label:"All",
	},
	{
		value: "up",
		label:  "Up",
	},
	{
		value: "down",
		label: "Down",
	},
	{
		value: 'degraded',
		label: 'Degraded'
	},
	{
		value: "maint",
		label:  "Maintenance",
	},
	{
		value: "N/A",
		label:  "Others",
	},
];

export function removeHtmlTagsBetweenStrings(inputString) {
	const htmlTagPattern = /<[^>]*>/g;
	const stringWithoutHtmlTags = inputString && inputString?.length > 0 && inputString?.replace(htmlTagPattern, '');

	return stringWithoutHtmlTags;
}


export const statusTooltipText = {
	'up': 'The devices are operational, functioning correctly, and actively connected to the network.',

	'down': 'The devices are not functioning correctly, experiencing issues, and unable to connect to the network or perform its intended functions',

	'degraded': 'This status indicates that the device is operational but not performing at its optimal capacity. It may be experiencing issues that affect its performance, resulting in reduced functionality or efficiency.',

	'maint': 'The devices are undergoing maintenance, temporarily out of service, and may be offline or unavailable during this period.',
	
	'N/A':'The devices that do not fit into the predefined statuses such as "up," "down," "maint," or "degraded." It may include custom or less common statuses.'
};

export const columns =  (handleServicePopup) => [
	{
		cell: (row) => { return <>{row.tenant_name} &nbsp; {row.is_vip_tenant ? <FontAwesomeIcon className='vip__icon' icon="fa-solid fa-crown" /> : ""}</> },
		name: "Tenant",
		title: "Tenant",
		sortable: true,
		grow:"0 !important",
		minWidth: 170,
		isEnabled: true,
		sortField: "tenant_name"
	},
	{
		cell: (row) => {
			const siteName = row?.site_display_name ? row?.site_display_name : row?.site_name ? row?.site_name : null
			return (
				<Box sx={{ display: 'flex', alignItems: 'center !important', height: '100%' }} >
					{siteName &&
						<StyledTooltip className='site__display__name__overview__tooltip' placement="bottom" title={"Site Overview"} style={{ top: '10px', left: '4px' }}>
							<FontAwesomeIcon style={{
								fontSize: '14px', paddingRight: '8px'
							}} icon="fa-solid fa-square-poll-horizontal" onClick={(e) => handleServicePopup(e, row, true)} />
						</StyledTooltip>
					}
					<CopyTextCell text={siteName ? siteName : "--"} />
				</Box>
			)
		},
		name: "Site Display Name",
		grow:"0 !important",
		title: "Site Display Name",
		id: "site_display_name",
		sortable: false,
		isEnabled: true,
		minWidth: 280,
	},
	{
		cell: (row) => <CopyTextCell showWithTooltip={true} text = {row?.site_name}/>,
		name: "Site Name",
		grow:"0 !important",
		title: "Site Name",
		id: "site_name",
		sortable: false,
		isEnabled: false,
		minWidth: 280,
	},
	{
		name: "Site Group",
		title: "Site Group",
		wrap: true,
		weight: 2,
		minWidth: 150,
		sortable: false,
		cell: (row) => row.site_group_name ? <CopyTextCell showWithTooltip={true} text={row.site_group_name} /> : '--',
		isEnabled: false,
	},
	{
		cell: (row) => <DeviceCell row = {row}/>,
		name: "Device Name",
		grow:"0 !important",
		title: "Device Name",
		sortable: true,
		sortField: 'name',
		isEnabled: true,
		minWidth: 280,
	},

	{
		cell: (row) => <TooltipLabel text = {row.isp_name}/>,
		name: "Vendor",
		title: "Vendor",
		grow:"0 !important",
		id: "vendor",
		sortField: 'isp_name',
		sortable: true,
		minWidth: 190,
		isEnabled: true,
	},
	{
		cell: (row) => <CopyTextCell showWithTooltip={true} text = {row.ip_address}/>,
		name: "IP",
		title: "IP",
		grow:"0 !important",
		sortable: false,
		isEnabled: true,
		minWidth: 190,
	},
	{
		name: "State",
		title: "State",
		grow:"0 !important",
		sortable: false,
		sortField:'monitor_device_data.inv_status',
		cell: (row) =>  <span
		className={
				(row.inv_status?.toLowerCase() === "decommissioning" || row.inv_status?.toLowerCase() === "offline" ||  row.inv_status?.toLowerCase() === "inventory")
					? "status status_down"
					:row.inv_status?.toLowerCase() === "active"
					? "status status_active"
							: row.inv_status?.toLowerCase() === "reserved"
								? "status status_inactive"
								: "status status_inactive"
			}
	>
		<TooltipLabel text = {row.inv_status}/>

	</span>,
	isEnabled:true,
	},
	{
		name: "Device | Circuit",
		title: "Device | Circuit",
		grow:"0 !important",
		sortable: false,
		isEnabled: true,
		minWidth: 180,
		cell: (row) => <StatusCell row = {row}/>
	},
	{
		cell: (row) => {
			return row.event_time && row.link_status?.toLowerCase() !== "n/a" ? <Timer targetDate={row.event_time} /> : "--";
		},
		name: "Since",
		title: "Since",
		sortable: true,
		grow:"0 !important",
		minWidth: 200,
		isEnabled: true,
		sortField: "event_time"
	},
	{
		cell: (row) => <TooltipLabel text = {row.type ? row.type : "-"}/>,
		name: "Type",
		title: "Type",
		grow:"0 !important",
		sortable: false,
		minWidth: 170,
		isEnabled: true,
	},
	{
		cell: (row) => {
			if (row?.service === "velocloud") {
				return (row?.link_mode === "reserved" ? "Reserved": "Primary")
			}
			else {
				return "--"
			}

		},		
		name: <ColumnHeaderWithTooltip tooltipText={'Only for SDWAN'} columnName={'Mode'} />,
		title: 'Mode',
		grow:"0 !important",
		isEnabled: true,
		minWidth: 190,
		sortable: false,
	},
	{
		cell: (row) => {
			return row?.link_speed_tx && row?.link_speed_rx ? (
				<div className="link_speed">
					{row?.link_speed_tx ? (
						<span className="speed">
							<FontAwesomeIcon icon="fa-solid fa-arrow-up" color="green" />
							{row.link_status?.toLowerCase() === "down" || row.link_status?.toLowerCase() === "maint" ? '0' :
							JSON.parse(row.link_speed_tx)?.value} mb/sec
						</span>
					) : null}
					{row?.link_speed_rx ? (
						<span className="speed">
							<FontAwesomeIcon icon="fa-solid fa-arrow-down" color="red" />
							{row.link_status?.toLowerCase() === "down" || row.link_status?.toLowerCase() === "maint" ? '0' :
							JSON.parse(row.link_speed_rx)?.value} mb/sec
						</span>
					) : null}
				</div>
			) : (
				"--"
			);
		},
		name: <ColumnHeaderWithTooltip tooltipText={'Only for SDWAN'} columnName={'Speed'} />	,
		title: 'Speed',
		grow:"0 !important",
		minWidth: 190,
		sortable: false,
		isEnabled: true,
	},
	{
		cell: (row) => {
			const calculatedLatency=Math.round(row.link_latency * 100) / 100
			const latency =row?.service === "velocloud" && calculatedLatency != 0 ?  `${calculatedLatency} ms`:'--'
			return (
				<span data-tag="allowRowEvents" style={{ textTransform: "lowercase" }}>
					{latency}
				</span>
			)
		},
		name: <ColumnHeaderWithTooltip tooltipText={'Only for SDWAN'} columnName={'Latency'} />	,
		title: 'Latency',
		grow:"0 !important",
		sortable: false,
		isEnabled: true,
		minWidth: 170,
	},
	{
		cell: (row) => {
			return row.service === "velocloud"
				? "SDWAN"
				: row?.service === "meraki"
					? "WiFi"
					: row?.service === "wug"
						? "Circuits"
						: row?.service === "cradlepoint"
							? "Cradlepoint"
							: row?.service === "fortinet"
								? "Fortinet" : "--";
		},
		title: 'Service',
		name: "Service",
		sortable: false,
		minWidth: 170,
		grow:"0 !important",
		isEnabled: true,
	},


];

export const ExpandedComponent = ({ data, tableColumns, conditionalRowStyles, onRowClicked }) => {
    createTheme("table__dark__theme", ReactDataTableStyles);
	const user = useSelector((state) => state.user);
    return (
        <div className="link__monitoring subComponent">
            <DataTable
                theme={user?.themePreference === "dark" ? "table__dark__theme" : null}
                className={`  ${user?.themePreference === "dark" ? "rdt__table__darkMode" : ""}`}
                columns={tableColumns}
                onRowClicked={onRowClicked}
                data={
                    data?.circuits?.map((i) => ({
                        callingFrom: "circuits",
                        ...i,
                    })) || []
                }
                conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    );
};

const LinkMonitoring = () => {
	const [tableColumns, setTableColumns] = useState([]);
	const [linkMonitoringData, setLinkMonitoringData] = useState([]);
	const user = useSelector((state) => state.user);
	const devices = useSelector((state) => state.devices)
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [openLinkStatus, setOpenLinkStatus] = useState(false);
	const [selectedRowData, setselectedRowData] = useState([]);
	const [tenantOptionsList, setTenantOptionsList] = useState([]);
	const [statusFilter, setstatusFilter] = useState([]);
	const [serviceFilter, setserviceFilter] = useState([]);
	const [tenantFilter, setTenantFilter] = useState([]);
	const [modeFilterValue, setModeFilterValue] = useState([]);
    const [serachInputText, setSearchText] = useState();
	const [refreshKey, setRefreshKey] = useState(new Date() + Math.random());
	const [tableRefreshKey, setTableRefreshKey] = useState(null);
	const [tenantHeaderData,setTenantHeaderData] = useState([]);
	const [vendorHeaderData, setVendorHeaderData] = useState([])
	const [isLoading, setIsLoading] = useState(false);
	const [isNotesLoading, setIsNotesLoading] = useState(false);
	const [openNotesDeleteConfirmation, setOpenNotesDeleteConfirmation] = useState(false);
	const [filter, setFilter] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [openAddNotesPopup, setOpenAddNotesPopup] = useState(false);
	const [isEditingNotes, setIsEditingNotes] = useState(false);
	const [notes, setNotes] = useState();
	const [nestedColumns, setNestedColumns] = useState([]);
	const [statusData, setStatusData] = useState({});
	const [selectedLink, setSelectedLink] = useState();
	const [shouldHeaderLoader, setShouldHeaderLoader] = useState(true)
	const [paginationState, setPaginationState] = useState({
	offset: 1, limit: 20, sortField: 'event_time', sortOrder: 'desc'
	})
	const [circuitFilterState, setCircuitFilterState] = useState(false);
	const [statusOptionsList, setStatusOptionsList] = useState([]);
	const [highlightFilter, setHighLightFilter] = useState(false);
	const [openServicePopup, setOpenServicePopup] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [filterText, setFilterText] = useState(null);
	const [stateFilter, setstateFilter] = useState([]);
	const statusDefaultFilter = [
		{
			value: "up",
			label: "Up",
		},
		{
			value: "down",
			label: "Down",
		},
		{
			value: 'degraded',
			label: 'Degraded'
		},
		{
			value: "N/A",
			label:  "Others",
		},
		...(user?.tenant?.name !== 'Sangoma' ? [{
			value: "all",
			label: "All"
		}] : [])
	]

	const modeDefaultFilter = [{
		value: 'primary',
		label: 'Primary'
	}]
	
	let filterStatusListData = user?.tenant?.name !== "Sangoma"? filterStatusList.filter(data => data?.value !== 'decommissioning'): filterStatusList;

	const canAccess = useCanAccess();

	const handleServicePopup = (e, data, open) =>{
		e?.stopPropagation();
		setselectedRowData(data); 
		setOpenServicePopup(open);
		setAnchorEl(e?.currentTarget)
	}

	const handleNotesChange = (value) => {
		setNotes(value);
	};

	const setPaginationData = (newState) => {
		setPaginationState(prevState => ({ ...prevState, ...newState }))
	}

	const enableNotesColumn = () =>{
		return canAccess( 'create', 'notes') || canAccess( 'delete', 'notes') || canAccess( 'update', 'notes')
	}

	const linkMonitoringColumns = [
		...columns(handleServicePopup),
		...getticketColumn("Link Monitor", "link-monitoring"),
		{
			name: "Notes",
			title:'Notes',
			grow:"0 !important",
			sortable: false,
			wrap: true,
			minWidth: 80,
			maxWidth: '80px !important',
			isEnabled: enableNotesColumn(),
			selector: (row) => {
				return (
					<div className='add__notes__row__wrapper'>
						<div className='icon__wrapper'>
							<IconButton sx={{ padding: 0 }} disableFocusRipple={true} disableTouchRipple={true} disableRipple={true} aria-label="add-note" onClick={(e) => {
								e?.stopPropagation(); setOpenAddNotesPopup(true);
								setSelectedLink(row)
							}}>
								{row?.notes && (user?.tenant?.id === row?.tenant_id || user?.role?.toLowerCase() === 'admin') && canAccess( 'update', 'notes') ?
									<Tooltip title='Edit Note'>
										<FontAwesomeIcon onClick={() => {
											if (row?.notes) {
												setNotes(row?.notes);
												setIsEditingNotes(true)
											}
										}} icon="fa-solid fa-file-pen" className="edit__note__icon" />
									</Tooltip>
									:
								(!row.notes &&	canAccess( 'create', 'notes') &&
									<Tooltip title='Add Note'>
										<FontAwesomeIcon className="add__note__icon" icon="fa-solid fa-file-medical"/>
									</Tooltip>)}
								
							</IconButton>
						</div>

						<div className='icon__wrapper'>
							{row?.notes_id && row?.notes && (user?.tenant?.id === row?.tenant_id || user?.role?.toLowerCase() === 'admin') && canAccess( 'delete', 'notes') &&
								<Tooltip title='Delete Note'>
									<FontAwesomeIcon onClick={(e) => {
										e?.stopPropagation()
										setSelectedLink(row);
										setOpenNotesDeleteConfirmation(true)

									}} className="delete__note__icon" icon="fa-solid fa-trash " />
								</Tooltip>
							}
						</div>
					</div>
				)
			},
		},
	]

	useEffect(() => {
		if (user?.tenant?.name !== 'Sangoma') {
			let filteredCol = linkMonitoringColumns.filter(data => {
				return data.name !== 'Tenant'
			});
			setTableRefreshKey(new Date().getTime());
			setTableColumns(filteredCol);
			const nestedColumns = getNestedColumns(filteredCol)
			setNestedColumns(nestedColumns);
			setStatusOptionsList(StatusFilterList?.filter(item => item.value !== 'maint'))
		} else {
			setTableRefreshKey(new Date().getTime());
			setTableColumns(linkMonitoringColumns);
			const nestedColumns = getNestedColumns(linkMonitoringColumns)
			setNestedColumns(nestedColumns);
			setStatusOptionsList(StatusFilterList)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		setSearchText(null)
		setserviceFilter([]);
		setstatusFilter([]);
		setTenantFilter([]);
		setModeFilterValue([]);
		setFilter([]);
		setstateFilter([]);
		setSelectedLink()
		handleServicePopup(null, null, false)
		let locationFilters = []
		if (!user.tenant) {
			return;
		}
		if (location?.state?.callingFrom === 'sitePopup') {
			setFilterText(location?.state?.siteName);
			getSiteLinkData();
		}
		else {
			const { state } = location || {};
			if (state && state.monitorData === undefined && state.callingFrom !== 'sitePopup') {
				const { status, serviceName, inv_status } = state;
				if (status) {
				let statusValue = status==="n/a" ||  status==="N/A" ? "N/A": status?.toLowerCase()
					setstatusFilter([{ value: statusValue, label: status==="n/a" ||  status==="N/A" ? "others" :status }]);
					locationFilters = [
						...locationFilters,
						{ key: 'link_status', value: [statusValue] },
					];
				}
				if (serviceName) {
					const servicename = serviceName?.toLowerCase() || 'all';
					const service = ServiceFilterList.find(i => i.value === serviceName)
					setserviceFilter([service]);			
						locationFilters = [
							...locationFilters,
							{ key: 'monitor_device_data.service', value: [servicename] }
						];
					

				}

				if (state?.ticketId && state?.deviceName && state?.ipAddress) {
					locationFilters = [
						...locationFilters,
						{ key: 'netbox_device_data.name', value: [state?.deviceName] },
						{ key: 'monitor_device_data.ip_address', value: [state?.ipAddress] }
					];



				}
				if(inv_status){
					setstateFilter([{ value: inv_status, label: inv_status }]);
						locationFilters = [
							...locationFilters,
							{ key: 'monitor_device_data.inv_status', value: [inv_status] }
						];
				}

			} else {
				setstatusFilter(statusDefaultFilter)
				locationFilters = [
					...locationFilters,
					{ key: 'link_status', value: ["up", "down", "degraded", "N/A"] },
				];

				if (user?.tenant?.name === 'Sangoma') {
					setModeFilterValue(modeDefaultFilter)
					locationFilters = [
						...locationFilters,
						{ key: 'monitor_device_data.link_mode', value: ["primary"] },
					];
				}
			}
			if (locationFilters && locationFilters?.length > 0) {
				setFilter((val)=>[...val,...locationFilters])
			}
			if(state?.circuitFilter) setCircuitFilterState(true);
			getLinkData(null, '', locationFilters ? JSON.stringify(locationFilters) : null, null ,null, location.state?.serviceName?.toLowerCase() === "wug" || state?.circuitFilter ? true: false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.tenant, filterText]);

	useEffect(() => {
		let tenantsList = devices?.tenants?.filter?.(i => (i.custom_fields?.meraki_org_id ||
			i.custom_fields?.vco_enterprise_id || i.custom_fields?.cradlepoint_id ||i.custom_fields?.fortinet_adom_oid||
			i.custom_fields?.wug_device_group_id))?.map((tenant) => {
			return {
				value: tenant.id,
				label: tenant.name,
			}
		})
		tenantsList = tenantsList?.filter(item => item?.label?.toLowerCase() !== 'sangoma')
		let allTenantOption = {
			label: 'All', value: 'all'
		};
		if (tenantsList && tenantsList?.length > 0) {
			const updatedTenantArray = [allTenantOption, ...tenantsList];
			setTenantOptionsList(updatedTenantArray);
		}

	}, [devices])


	const getSiteLinkData = async () => {
		setIsLoading(true);
		try {
			let result = await dispatch(fetchSiteLinkData({
				tenantId: location?.state?.tenantId,
				siteId: location?.state?.siteId
			}));
			
			let finalResult = user?.tenant?.name !== 'Sangoma' ? result?.payload.filter(data => data?.link_status !== "maint") : result?.payload;
			
			setLinkMonitoringData(finalResult);
			let linkStatus = await dispatch(fetchLinkMonitorStatusData({
				tenantId: location?.state?.tenantId,
				tenantName: user?.tenant?.name,
				params: {},
				siteId : location?.state?.siteId
			}));		
			setStatusData(linkStatus?.payload);
			setIsLoading(false);
			setRefreshKey(new Date() + Math.random());
		}
		catch {
			toast.error('Something went wrong');
			setIsLoading(false);
		}

	}

	function replaceHistory() {
		navigate(location.pathname, { state: null })
	}

	const getFilterRequest = useCallback((pagination, searchText, state, addCircuitFilter, searchState, filters) =>{		
		let circuitFilter = ((serviceFilter?.filter( i => i.value === 'wug')?.length > 0 && state !== 'clearCircuit') || addCircuitFilter || circuitFilterState) && !searchText  ? true : false;
		let req = pagination ? 
		{ ...pagination, 
			searchText:searchState === "clearSearch" || filters ? null: searchText ===''? searchText : searchText ? searchText : serachInputText?.length > 0 ? serachInputText : null, 
			circuitFilter: circuitFilter 
		} : 
		{ ...paginationState, 
			searchText:searchState === "clearSearch" || filters ? null: searchText ===''? searchText  : searchText ? searchText:  serachInputText?.length > 0 ? serachInputText : null, 
			circuitFilter: circuitFilter
		}

		const request = {
			tenantId: user?.tenant?.id,
			tenantName: user?.tenant?.name,
			params: { ...req, filter: filters ? filters : serachInputText || searchText? [] :filter ? JSON.stringify(filter) : null},

		}
		return request || {}

	},[user.tenant, serachInputText, serviceFilter, filter, paginationState])


	const getLinkData = async (pagination, searchText, filters, state, searchState, addCircuitFilter, pageChange) => {	
		if(openServicePopup) {
			handleServicePopup(null, null, false)
		}
		if (pagination) {
			setPaginationData({
				offset: pagination?.offset, limit: pagination?.limit, sortField: pagination?.sortField,
				sortOrder: pagination?.sortOrder
			})
		}
		setShouldHeaderLoader(!pageChange)
		setIsLoading(true);

		const req = getFilterRequest(pagination, searchText, state, addCircuitFilter, searchState, filters);
		try { 
			const [response, statusLinkData] = location?.state?.monitorData == undefined ? await  Promise.all([dispatch(fetchLinkMonitorData(req)).unwrap(),
			!pageChange && dispatch(
				fetchLinkMonitorStatusData(req)
			).unwrap()]) : null;	
		if (response) {
			!pageChange && setStatusData(statusLinkData)
				const { data, count, tenantData, vendorData } = response;
				
					setTotalCount(count || 0);		
					setLinkMonitoringData(data || []);
					setRefreshKey(new Date() + Math.random());
					!pageChange && setTenantHeaderData(tenantData || []);
					!pageChange && setVendorHeaderData(vendorData || [])
			
		}
	} catch (error) {
		toast.error("Oops! Something went wrong");
		console.error("Error fetching tenants data:", error);
		// Handle the error appropriately, such as showing an error message to the user
	} finally {
		setIsLoading(false);
		setShouldHeaderLoader(false) // End loading state
	}
		

	}

	const handleSearch = async (e) => {
		setTableRefreshKey(new Date().getTime())
		setSearchText(e.target.value);

		await getLinkData({
			offset: 1, limit: 20, sortField: 'event_time', sortOrder: 'desc'
			}, e.target.value, null, null, !e?.target?.value ? "clearSearch" : null, false, true);
	}

	const conditionalRowStyles = [
		{
			when: row => row.is_bounced,
			style: {
				backgroundColor: 'rgba(255, 0, 0, 0.2)',
				fontWeight: '500',
				'&:hover': {
					backgroundColor: 'rgba(255, 0, 0, 0.2)',
				},
			}
		},
		{
			when: row => row.is_circuit_bounced,
			style: {
				backgroundColor: 'rgba(251, 189, 0, 0.2)',
				fontWeight: '500',
				'&:hover': {
					backgroundColor: 'rgba(251, 189, 0, 0.2)',
				},
			}
		}
	];

	const handleSelectRow = (row) => {
		if(openServicePopup) {
			handleServicePopup(null, null, false)
		}
		setOpenLinkStatus(true);
		setselectedRowData(row);
	};

	const handleClosePopup = () => {
		setOpenLinkStatus(false);
	};

	// Filter functions
	const handleStatusFilter = async (e, action) => {
		setstatusFilter(e);
		setHighLightFilter(false)

		if (action === 'clear') {
			// onApplyClick('Status...', e);
			updateFilter('link_status', e, statusFilter, null, true);
		}
	};

	const handleStateFilter = async (e, action) => {
		setstateFilter(e);
		if(action === 'clear'){
			onApplyClick('State...', e);
		}
	};

	const handleServiceFilter = async (e, action) => {
		setserviceFilter(e);
		if (action === 'clear') {
			onApplyClick('Service...', e, 'clearCircuit');
		}
	};

	const handleModeFilter = async (e, action) => {
		setModeFilterValue(e);
		if (action === 'clear') {
			// onApplyClick('Mode...', e);
			updateFilter('monitor_device_data.link_mode', e, modeFilterValue, null, true);

		}
	};

	const handleTenantFilter = async (e, action) => {
		setTenantFilter(e);
		if (action === 'clear') {
			onApplyClick('Tenant...', e);
		}
	};


	const updateFilter = (key, val, filterType, state, addValue) => {
		// TODO: Clear the filters in location state that are applied on redirection when the filters are changed
		if (location.state) {
			replaceHistory()
		}
		const filterValue = val ? addValue ? val:[] : [...filterType];
		const value = filterValue.some(obj => obj.value?.toString()?.toLowerCase() === ('all')) ? ['all'] : filterValue?.map(i => i.value);
		let cloneFilter = [...filter];

		cloneFilter = cloneFilter?.filter(i => i.key !== key);
		if (value?.length > 0) {
			cloneFilter.push({ value: value, key: key });
		}
		setFilter(cloneFilter);

		const filterReq = cloneFilter?.length > 0 ? JSON.stringify(cloneFilter) : [];
		getLinkData({
			offset: 1, limit: 20, sortField: 'event_time', sortOrder: 'desc'
			}, null, filterReq, state);
	};

	const onApplyClick = (key, val, state) => {
		if (serachInputText) {
			setSearchText('');
		}
		setTableRefreshKey(new Date().getTime())
		switch (key) {
			case 'Status...':
				updateFilter('link_status', val, statusFilter,);
				break;
			case 'State...':
				updateFilter('monitor_device_data.inv_status', val, stateFilter,);
				break;
			case 'Service...':
				updateFilter('monitor_device_data.service', val, serviceFilter, state);
				break;
			case 'Tenant...':
				updateFilter('netbox_device_data.tenant_id', val, tenantFilter,);
				break;
			case 'Mode...':
				updateFilter('monitor_device_data.link_mode', val, modeFilterValue);
				break;
			default:
				break
		}
	};

	const handleNotes = useCallback(async () => {
		setIsNotesLoading(true);
		const value = removeHtmlTagsBetweenStrings(notes)?.length === 0
		if (value || !notes) {
			toast.error("Please add note");
			setIsNotesLoading(false);
			return;
		}

		if (notes) {
			const lines = notes?.split(/<p>|<\/p>|<br>|<br \/>/);
			if (lines.length > 100) {
				setIsNotesLoading(false);
				toast.error('Ensure your text does not exceed 100 lines.');
				return;
			}
		}

		const adddata = {
			notes,
			user_id: user?.id || 202,
			feature: 'link-monitor',
			feature_id: selectedLink?.link_id,
			tenant_id: user?.tenant?.name === 'Sangoma' ? selectedLink?.tenant_id : user?.tenant?.id
		};
		const editData = {
			notes,
			feature: 'link-monitor',
			feature_id: selectedLink?.link_id,

		}

		try {
			const response = isEditingNotes
				? await dispatch(updateNotesData(editData))
				: await dispatch(createNotesData(adddata));

			if (response) {
				toast.success(response.payload);
				await getLinkData();
				setOpenAddNotesPopup(false);
			}
		} catch (error) {
			toast.error("Something went wrong");
		} finally {
			setIsNotesLoading(false);
			setNotes()
			setSelectedLink();
			if (isEditingNotes) {
				setIsEditingNotes(false);
			}
		}
	}, [notes, user, selectedLink, isEditingNotes, dispatch, getLinkData]);

	const deleteNotes = useCallback(async () => {
		setIsNotesLoading(true);

		const data = {
			featureId: selectedLink?.link_id,
			feature: 'link-monitor',
		};

		try {
			const response = await dispatch(deleteNotesData(data));

			if (response) {
				toast.success(response.payload);
				setOpenNotesDeleteConfirmation(false);
				await getLinkData();
			}
		} catch (error) {
			toast.error("Something went wrong");
		} finally {
			setIsNotesLoading(false);
			setNotes();
			setSelectedLink();
		}
	}, [selectedLink, dispatch, getLinkData]);

	const resetNotes = () => {
		setSelectedLink(); setIsEditingNotes(false); setOpenAddNotesPopup(false); setNotes(false)
	}

	const onColumnChange= (col) =>{
		let updateColumns = col?.filter(i => i?.isEnabled);
		const columns = getNestedColumns(updateColumns)
		setNestedColumns(columns)

	}

	const handleCardClick = (e, item) => {
		setSearchText('');
		setTableRefreshKey(new Date().getTime())
		const selectedItem = [{ value: item?.id, label: item?.text }]
		setHighLightFilter(true)
		setstatusFilter(selectedItem);
		updateFilter('link_status', selectedItem, statusFilter, null, true);
	}

	const onPageChange = (pagination) => {
		getLinkData(pagination, null, null, null,null, false, true);
	}

	const linkDetails = useMemo(() => {
		
		const getdetail = (id) => statusData && statusData?.length > 0 && statusData?.find(item => item?.link_status === id);
		const isEnabled = (val) => {return  statusFilter.length === 0 || statusFilter?.filter(i => i.value === val).length > 0}
		return [
			{
				id: 'up',
				text: 'Up',
				count: getdetail('up')?.total_count || 0,
				percentage: getdetail('up')?.percentage || 0,
				className: 'link__monitor__card__up',
				isEnabled: isEnabled('up'),
				disableClick: location?.state?.siteId !== undefined,
				tooltipText:statusTooltipText['up']
			},
			{
				id: 'down',
				text: 'Down',
				count: getdetail('down')?.total_count || 0,
				percentage: getdetail('down')?.percentage || 0,
				className: 'link__monitor__card__down',
				isEnabled:isEnabled('down'),
				disableClick: location?.state?.siteId !== undefined,
				tooltipText:statusTooltipText['down']
			}, 
			{
				id: 'degraded',
				text: 'Degraded',
				count: getdetail('degraded')?.total_count || 0,
				percentage: getdetail('degraded')?.percentage || 0,
				className: 'link__monitor__card__degraded',
				isEnabled:isEnabled('degraded'),
				disableClick: location?.state?.siteId !== undefined,
				tooltipText:statusTooltipText['degraded']
				
			},
			{
				id: 'maint',
				text: 'Maint',
				count: getdetail('maint')?.total_count || 0,
				percentage: getdetail('maint')?.percentage || 0,
				className: 'link__monitor__card__maintenance',
				isEnabled: user?.tenant?.name === 'Sangoma' && isEnabled('maint'),
				disableClick: location?.state?.siteId !== undefined,
				tooltipText:statusTooltipText['maint']
			},
			{
				id: 'N/A',
				text: 'Others',
				count: getdetail('N/A')?.total_count || 0,
				percentage: getdetail('N/A')?.percentage || 0,
				className: 'link__monitor__card__na',
				isEnabled:isEnabled('N/A'),
				disableClick: location?.state?.siteId !== undefined,
				tooltipText:statusTooltipText['N/A']	
			},
		]
	}, [user?.tenant, statusData]);

	const linkTenantData = useMemo(() => {
		return [
			{
				id: 'tenant',
				text: 'Maint',
				bgColor: '#8E1B5E',
				className: 'link__monitor__card__tenant',
				isEnabled: user?.tenant?.name === 'Sangoma',
				data:tenantHeaderData
			},
			{
				id: 'vendor',
				bgColor: '#1B5E8E',
				text: 'Maint',
				className: 'link__monitor__card__vendor',
				isEnabled:true,
				data: vendorHeaderData
			}
			]
	}, [user?.tenant, vendorHeaderData, tenantHeaderData])

	const filterConfigurations = useMemo(() => [
		{
			key: "statusFilter",
			isEnabled: true,
			placeholder: "Status...",
			options: statusOptionsList,
			value: statusFilter,
			handler: handleStatusFilter,
			highlightFilter : highlightFilter,
			disableApply: location?.state?.callingFrom !== 'sitePopup' && !isLoading && statusFilter.length > 0 ? false : true,
			defaultFilter: statusDefaultFilter
		},
		{
			key: "stateFilter",
			isEnabled: true,
			placeholder: "State...",
			options: [...filterStatusListData, {
				value: "reserved",
				label: "Reserved"
			},
			{
				value: "deprecated",
				label: "Deprecated",
			}
			],
			value: stateFilter,
			handler: handleStateFilter,
			disableApply:  location?.state?.callingFrom !== 'sitePopup' && !isLoading && stateFilter.length > 0 ? false : true,
		},
		{
			key: "serviceFilter",
			isEnabled: true, 
			placeholder: "Service...",
			options: ServiceFilterList,
			value: serviceFilter, 
			handler: handleServiceFilter,
			disableApply: location?.state?.callingFrom !== 'sitePopup' && !isLoading && serviceFilter.length > 0 ? false : true,
		},
		{
			key: "tenantFilter",
			isEnabled: user?.tenant?.name === "Sangoma", 
			placeholder: "Tenant...",
			options: tenantOptionsList,
			value: tenantFilter,
			handler: handleTenantFilter,
			disableApply: location?.state?.callingFrom !== 'sitePopup' && !isLoading && tenantFilter.length > 0 ? false : true,
		},
		{
			key: "modeFilter",
			isEnabled: user?.tenant?.name === "Sangoma", 
			placeholder: "Mode...",
			options: modeFilterOptionsList, 
			value: modeFilterValue, 
			handler: handleModeFilter, 
			disableApply: location?.state?.callingFrom !== 'sitePopup' && !isLoading && modeFilterValue.length > 0 ? false : true,
			defaultFilter:modeDefaultFilter
		},
	], [
		user?.tenant?.name,
		statusOptionsList,
		statusFilter,
		stateFilter,
		serviceFilter,
		tenantOptionsList,
		tenantFilter,
		modeFilterValue,
		isLoading,
	]);


	const getNestedColumns = (columns) =>{
		let formatedCol = [...columns];
	
		formatedCol.unshift({
		selector: () => <FontAwesomeIcon icon="fa-solid fa-chevron-right" />,
		name: "arrow",
		sortable: false,
		wrap: true,
		isEnabled: true,
			style: {
				minWidth: "38px !important",
				maxWidth: "38px !important",
				marginLeft: '10px'
			},
		})

		return formatedCol

	}


	const ExpandedComponent = ({ data, ...props }) => {
		createTheme('table__dark__theme',
			ReactDataTableStyles
		);
		return (
			<div className="link__monitoring subComponent">
				<DataTable
					theme={user?.themePreference === 'dark' ? "table__dark__theme" : null}
					className={`  ${user?.themePreference === 'dark' ? 'rdt__table__darkMode' : ''}`}
					columns={props?.tableColumns.map(column => ({
						...column,
					}))}
					onRowClicked={props?.onRowClicked}
					data={data = data?.circuits?.map(i => {
						return {
							callingFrom: 'circuits',
							...i
						}
					})}
					noHeader={true}
					conditionalRowStyles={props?.conditionalRowStyles}
				/>
			</div>
		);
	};


	if (location?.state?.callingFrom === 'sitePopup') {
		useInterval(getSiteLinkData, 60000);
	} else {
		useInterval(getLinkData, 60000);
	}

	
	
	return (
		<MuiThemedBox className="main_inner_wrap link__monitoring__dashboard">
			<Breadcrumbs text={"Links"}  filterValue = {filterText} onRemoveFilter={() => {setFilterText(null)}}/>
			<Grid container className="flex_wrap justify-between">
				<Grid item md={3} className="filter_wrap">
					<SearchInputField debounceProp={filter} key={filter + user?.tenant?.id + statusFilter?.length} handleClick={handleSearch} value={serachInputText} userKey={'specialChar'} />

				</Grid>
				<Grid item md={9} className="flex flex_Wrap">
					<FilterStatusAndService
						style={{ width: "94%", justifyContent: "right" }}
						filterConfigurations={filterConfigurations}
						onApplyClick={onApplyClick} 
						totalFilterCount={user?.tenant?.name === "Sangoma" ? 5 : 3} 

					/>
					<GlobalExportButton
						enableFilteredDataExportButton={filter?.length > 0 || serachInputText?.length > 0 || location?.state?.callingFrom === 'sitePopup'}
						exportApiRequest={
							{
								tenantId: user.tenant?.name !== "Sangoma" ? user.tenant?.id : null,
								filetype: 'csv',
								dataOf: 'linkmonitoring',
								siteId:location?.state?.callingFrom === 'sitePopup' ?  location?.state?.siteId : null,
								tenantName:location?.state?.callingFrom === 'sitePopup' ? user?.tenant?.name : null,
								...getFilterRequest({
									offset: 1,
									limit: totalCount,
									sortField: paginationState?.sortField,
									sortOrder: paginationState?.sortOrder
								})


							}
						}
						heading={`Link Monitoring`}
						text={"Are you sure you want to download link monitoring list ?"}
						fileName={"links"}
					/>
				</Grid>
			</Grid>
			<Grid container>
			<Grid lg = {8.5}>
			<TableHeader tableHeaderCardDetails={linkDetails} isLoading={isLoading && shouldHeaderLoader} md={user?.tenant?.name === 'Sangoma' ? 2.4 :3} lg={user?.tenant?.name === 'Sangoma' ? 2.4 :3} handleCardClick={handleCardClick}/>
			</Grid>
			<Grid md={0.05}></Grid>
				<Grid lg={3.4} container className='ticket_upper_card ticket__card__wrap'>
					{linkTenantData && linkTenantData?.length > 0 && linkTenantData?.filter(i => i.isEnabled)?.map((item, index) => {
					return (<>{
						<Grid className={item?.className} sx={{ flexGrow: 1, }} key={item?.id} item xs={12} md={user?.tenant?.name === 'Sangoma' ? 6 : 12} lg={user?.tenant?.name === 'Sangoma' ? 6 : 12}>
						<div className="circuit__box">
						{isLoading && shouldHeaderLoader ? <MuiThemedCircularProgress  />:
									<div className={`link_monitor_tenant_header`} style={{background: item?.bgColor}}>
									{item?.data?.length ? item?.data?.map(i => {
										// eslint-disable-next-line react/jsx-key
										return (<div className="link_montoring_tenant_label" style={{ width: (window.innerWidth  == 1280 && index == 1) ? '75%' : '100%' }}>
											<TooltipLabel text = {i.label} fontSize={'0.83rem'}/>
											<div>{i.value}</div>
										</div>)
									}): "No Data Found"}
									
		
						</div>}
					</div>
				</Grid>
					}</>)
				})}
				</Grid>
			</Grid>
			<Box>
				<TableComponent
					className="rdt_table_wrap hover_table ticket_table_wrap link_monitoring_table"
					columns={tableColumns}
					data={linkMonitoringData}
					expandableRows={tableColumns.find((o) => o.name === "Device Name")}
					expandableRowDisabled={(row) => {
						return row?.circuits ? false : true;
					}}
					onColumnChange={onColumnChange}
					defaultSortOrder={'desc'}
					defaultSortField={'event_time'}
					expandableRowsComponent={ExpandedComponent}
					expandableRowsComponentProps={{onRowClicked:handleSelectRow, conditionalRowStyles, tableColumns :nestedColumns }}
					expandableIcon={{
						collapsed: (
							<img src={PlusIcon} alt="open" className="expand__icon" />
						),
						expanded: (
							<img src={MinusIcon} alt="close" className="expand__icon" />
						),
					}}
					pagination={location?.state?.callingFrom === 'sitePopup' ? false : true}
					conditionalRowStyles={conditionalRowStyles}
					onPageChange={onPageChange}
					customStyles={customStyles}
					isLoading={isLoading ? true : false}
					onRowClicked={handleSelectRow}
					keyId={refreshKey}
					key={tableRefreshKey}
					totalRows={totalCount}
					columnPreferenceStyles={
						{
							position: "sticky",
							float: "right",
							marginLeft: "auto"
						}
					}
					disbaleColumnPreferenceMaxWidth={true}
				/>
			</Box>

			<Suspense fallback={<div></div>}>
				{openLinkStatus ? (
					<LinkHistory
						open={openLinkStatus}
						rowData={selectedRowData}
						handleClosePopup={handleClosePopup}
					/>
				) : null}
			</Suspense>
			<NotesPopup
				openAddNotesPopup={openAddNotesPopup}
				resetNotes={resetNotes}
				isEditingNotes={isEditingNotes}
				notes={notes}
				handleNotesChange={handleNotesChange}
				setNotes={setNotes}
				isNotesLoading={isNotesLoading}
				handleNotes={handleNotes}
			/>


			{openNotesDeleteConfirmation &&
			<DeleteConfirmPopup
				open={openNotesDeleteConfirmation}
				close={() =>{
					setOpenNotesDeleteConfirmation(false);
					setSelectedLink()
				}}
				deleteBtnClick={() => deleteNotes()}
				deleteData={selectedLink}						
				heading={`Notes for Device: ${selectedLink?.name} ?`}
				text={"Are you sure you want to delete this Notes ?"}
			/>
		}

			<Suspense fallback={<div></div>}>
				{openServicePopup &&
					<ServicePopup
						key={anchorEl}
						callingFrom={'link-monitor'}
						rowData={{
							id: selectedRowData?.site_id,
							site_display_name: selectedRowData?.site_display_name,
							name: selectedRowData?.site_name,
							tenant: {
								id: selectedRowData?.tenant_id
							}
						}}
						handleClosePopup={()=> handleServicePopup(null, null, false)}
						open={openServicePopup}
						gateWayEnable={false}
						renderPopper={true}
						anchorEl={anchorEl}
					/>
				}
			</Suspense>
		</MuiThemedBox>
	);
};


export default LinkMonitoring;
