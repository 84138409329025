
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from "@mui/material";


const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
                top: 10,
                left: 'calc(-50% + 16px)',
                right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
                [`& .${stepConnectorClasses.line}`]: {
                        borderColor: '#244C91',
                },
        },
        [`&.${stepConnectorClasses.completed}`]: {
                [`& .${stepConnectorClasses.line}`]: {
                        borderColor: '#244C91',
                },
        },
        [`& .${stepConnectorClasses.line}`]: {
                borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
                borderTopWidth: 3,
                borderRadius: 1,
        },
}));


const removeItemsAfterKey = (obj, keyToRemove) => {
        const newObj = {};
        let foundKey = false;
    
        for (const key in obj) {
          if (key === keyToRemove) {
            foundKey = true;
          }
          if (!foundKey) {
            newObj[key] = obj[key];
          }
        }
        return newObj;
      };
    


export {
        QontoConnector, removeItemsAfterKey
}
