export function formatDate(date) {
  const now = new Date();
  const givenDate = new Date(date);
  const diff = Math.abs(now - givenDate);

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  // const hours = Math.floor(minutes / 60);
  // const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return `${seconds} sec${seconds !== 1 ? 's' : ''} ago`;
  } else if (minutes < 60) {
    return `${minutes} min${minutes !== 1 ? 's' : ''} ago`;
  }

 
  // else if (hours < 24) {
  //   return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
  // } else if (days === 1) {
  //   return 'yesterday';
  // } else if (days < 7) {
  //   return `${days} day${days !== 1 ? 's' : ''} ago`;
  // } 
  else {
    const options = {
      year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric',
      minute: 'numeric',
    };
    return givenDate.toLocaleDateString(undefined, options);
  }
}


export function removeTrailingZeros(num) {
  const val = num?.toString().replace(/\.00$/, '');
  return val;
}


export function isGatewayEnabled(user){
  const customfields = user?.tenant?.custom_fields;

    if(user?.tenant?.name === 'Sangoma') return true;

    else if(customfields?.gateway_enabled) return true;
    else return false;

}

export function capitalizeFirstLetter(string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}
