import { Box } from '@mui/material';
import { MuiTypography } from 'components/common/MuiThemedComponents';
import Highcharts from 'highcharts';
import { camelCase, snakeCase, startCase } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useCanAccess } from 'services/canACLAccess';
import { ReactComponent as ShareIcon } from "../../../netopsStyles/assets/share.svg";
import { highchartColors, highchartDarkColors } from 'components/common/NetopsHighCharts';
import NetopsDashboardGraph, { ChartLoader, ChartNoData, SdwanGraph } from './NetopsDashboardGraph';
import { screenResolution } from './NetopsDashboard';

const NetopsDashboardChart = ({ text, statusData, isLoading, navUrl, redirectUrl, service, chartViewPreference,isInventoryGraph, ...props }) => {

	const canAccess = useCanAccess();
	const user = useSelector((state) => state.user);
	const navigate = useNavigate();
	const [chartData, setChartData] = useState(statusData);
	const [legendData, setLegendData] = useState([]);
	const [sdwanLegendData, setSdwanLegendData] = useState([]);
	const [sdwanChartData, setsdwanChartData] = useState(props?.sdwanDevicestatus);
	const colorCache = useRef({});
    let colorIndex = useRef(0);

	const serviceNameMappings = useMemo(() => ({
		sdwan: 'velocloud',
		wifi: 'meraki',
		circuits: 'wug',
		cradlepoint: 'cradlepoint',
		fortinet: 'fortinet'
	}), [])

	const handleRedirect = useCallback((data, isDeviceRedirect) => {

		if (isDeviceRedirect && canAccess('read', 'devices')) {
			navigate("/nf-status/inventory", {
				state: {
					device_status: data,
					service: 'velocloud'
				},
			})
			return
		}
		if (navUrl && canAccess('read', 'link-monitoring') && text !== 'Circuits') {

			navigate(navUrl, {
				state: {
					status: data,
					serviceName: serviceNameMappings[text?.toLowerCase()]
				},
			})
		} else if (text === "Inventory data") {
			if(data === 'phones'){
				navigate("/nf-status/phones")
			}
			if(data === 'gateways' && canAccess('read', 'gateways')){
				navigate("/nf-status/gateway")
			}
			if (data === "devices" && canAccess('read', 'devices')) {

				navigate("/nf-status/inventory",
					{
						state: {
							service: service
						}
					}
				)
			}
			if (data === "tenants" && canAccess('read', 'tenants')) {
				navigate("/nf-status/tenants", {
					state: {
						service: service
					}
				})
			}
			if (data === "sites" && canAccess('read', 'sites')) {
				navigate("/nf-status/site", {
					state: {
						service: service
					}
				})
			}
			if (data === "links" && canAccess('read', 'link-monitoring')) {
				navigate("/nf-status/link-monitoring",
					{
						state: {
							serviceName: service
						}
					}
				)
			}
		} else if (text === "Devices Status" && canAccess('read', 'devices')) {
			navigate("/nf-status/inventory", {
				state: {
					inv_status: data,
					service: service
				}
			})
		} else if (text === "Unmapped Devices") {
			navigate("/nf-status/binding", {
				state: {
					activeTab: "unMapped",
					service: serviceNameMappings[data?.toLowerCase()]
				}
			})
		}
		else if (text === "Monitoring Links" && canAccess('read', 'link-monitoring')) {
			navigate("/nf-status/link-monitoring", {
				state: {
					inv_status: data,
					serviceName: service
				}
			})
		} else if (text === 'Circuits' && canAccess('read', 'circuit-monitoring')) {
			navigate("/nf-status/circuit-link-monitoring", {
				state: {
					status: data,
					// serviceName: serviceNameMappings[text?.toLowerCase()]
				},
			})
		}
		else if(text === "Endpoint State"){
			navigate("/nf-status/phones", {
				state: {
					stateValue: data,
				},
			})
		}
		else if(text === "Gateway State" || text === 'Firmware Tag'){
			navigate("/nf-status/gateway", {
				state: {
					filter: text === 'Firmware Tag' ? 'firmware_tag_id' : 'state',
					stateValue: data,
				},
			})
		}

	}, [canAccess, navUrl, text, navigate, serviceNameMappings, service])

	const createPieChartData = useCallback((labels, values) => {
		return labels.map((label, i) => ({
			name: label === "n/a" ? "N/A" : startCase(camelCase(label)),
			y: Number(values[i]),
			metaData: label === "n/a" ? "N/A" : startCase(camelCase(label)),
		}));
	},[])

	// Dynamic color genanrator
	const generateColor = () => {
        const hue = (colorIndex.current * 137) % 360;
        colorIndex.current++;
        return `hsl(${hue}, 70%, 50%)`;
    };

    const getColor = useCallback((label) => {
        const updatedLabel = label?.toLowerCase();
        const themePreference = user?.themePreference;

        if (text === "CFG Group") {
            if (!colorCache.current[updatedLabel]) {
                colorCache.current[updatedLabel] = generateColor();
            }
            return colorCache.current[updatedLabel];
        } else {
            return themePreference === "dark"
                ? highchartDarkColors[updatedLabel]
                : highchartColors[updatedLabel];
        }
    }, [user?.themePreference]);

	const createLegendData = useCallback((labels, values) => {		
		return labels.map((label, i) => {			
			return{
			name: label === "n/a" ? "N/A" : startCase(camelCase(label)),
			color: getColor(label, i),
			y: Number(values[i]),
			isActive: true
		}});
	},[getColor])

	const columnChartOptions = useCallback((data, isDeviceRedirect) => {
		const labels = Object.keys(data || {});
		const values = Object.values(data || {});

		const columnData = [];
		const categories = [];
		const selectedColors = [];

		labels.forEach((label, index) => {
			const selectedCategory = label === "n/a" ? "N/A" : startCase(camelCase(label));
			columnData.push(Number(values[index]));
			categories.push(selectedCategory);
			selectedColors.push(getColor(label, index));
		});

		return {
			chart: {
				type: 'column',
				animation: {
					duration: 1500,
				}
			},
			title: {
				text: null,

			},
			xAxis: {
				type: 'category',
				categories: categories,
				labels: {
					rotation: undefined,
					autoRotation: undefined,
					autoRotationLimit: 10,
					style: {
					color: "#93A2B1",
						fontFamily: "Poppins",
						fontSize: "12px",
						fontStyle: "normal",
						fontWeight: "400",
						lineHeight: "20px",
						letterSpacing: "0.25px",
					},
				},
				gridLineColor:"#93A2B1",
				lineColor: '#A3B6D7',

			},
			yAxis: {
				min: 0,
				title: {
					enabled: false
				},
				gridLineColor:"#A3B6D7",
				lineColor:"#A3B6D7",
				labels: {
					style: {
						color: "#93A2B1",
						fontFamily: "Poppins",
						fontSize: "12px",
						fontStyle: "normal",
						fontWeight: "400",
						lineHeight: "20px",
						letterSpacing: "0.25px",

					},
				},
			},
			plotOptions: {
				column: {
					colors: selectedColors,
				},
				series: {
					pointWidth: 15,
					dataLabels: {
						enabled: true,
						crop: false,
						overflow: 'none',
						style: {
							textOutline: false,
							fontSize: '14px',
							fontWeight: 700
						}
					},
				}
			},
			tooltip: {

				backgroundColor: user?.themePreference === 'dark' ? '#4C5863' : '#001221',
                style:{
                    fontSize:'12px',
                    fontFamily:'Open Sans',
                    fontWeight:'400',
                    color: user?.themePreference === 'dark' ? '#E5E6E8' : '#fff',
                },
				formatter: function () {
					return `<b>${this?.x}</b>: <span>${this.y}</span>`;
				},
			},
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},

			series: [
				{
					name: '',
					data: columnData,
					borderWidth: 0,
					colorByPoint: true,
					type: 'column',
					cursor: 'pointer',
					pointWidth: 15,
					dataLabels: {
						enabled: false
					},
					point: {
						events: {
							click: function (event) {
								let category = event?.point?.category?.toLowerCase();
								handleRedirect(category, isDeviceRedirect)
							}
						}
					}
				},

			]
		}
	}, [getColor, handleRedirect]);  
	
	const pieChartOptions = useCallback((data, isDeviceRedirect) => {
		const labels = Object.keys(data || {});
		const values = Object.values(data || {});
		const pieChartData = createPieChartData(labels, values);
		return {
			chart: {
				type: 'pie',
			},
			credits: {
				enabled: false,
			},
			dataLabels: [{
				enabled: false,
			}],
			tooltip: {
				backgroundColor: user?.themePreference === 'dark' ? '#4C5863' : '#001221',
                style:{
                    fontSize:'12px',
                    fontFamily:'Open Sans',
                    fontWeight:'400',
                    color: user?.themePreference === 'dark' ? '#E5E6E8' : '#fff',
                },
				formatter: function () {
					return `<b>${this?.point?.name}</b>: <span>${this.y}</span>`;
				},
			},
			title: {
				text: null,
			},
			plotOptions: {
				pie: {
					colors: pieChartData.map((_, i) => getColor(labels[i], i)),
					allowPointSelect: true,
					cursor: (text === 'Endpoint Brands')
						? 'default'
						: 'pointer',
					dataLabels: {
						enabled: false,
					},
					point: {
						events: {
							click: function (event) {
								const category = event?.point?.metaData?.toLowerCase();
								handleRedirect(category, isDeviceRedirect);
							},
						},
					},
					showInLegend: true,
				},
			},
			legend: {
				enabled: false,
				alignColumns: false,
				layout: "horizontal",
				verticalAlign: 'bottom',
				itemStyle: {
					color: '#6B7280',
					fontSize: '13px',
					fontWeight: '400',
					fontFamily: 'Open Sans',
				},
			},
			series: [
				{
					name: '',
					data: pieChartData,
					colorByPoint: true,
					innerSize: '82%',
					borderRadius: 0,
					borderWidth: 2,
					borderColor: user?.themePreference === 'dark' ? "#202020" : "#fff",
					type: 'pie',
					dataLabels: {
						color: 'white',
						distance: -25,
						style: {
							textOutline: false,
							fontSize: '14px',
							fontWeight: 700,
						},
						formatter: function () {
							return this.percentage >= 2 ? this.y : null;
						},
					},
					animation: {
						duration: 1500,
					},
				},
			],
		};
	}, [createPieChartData, user?.themePreference, user?.tenant?.name, text, getColor, handleRedirect]);

	const renderChart = useCallback((containerId, data, isDeviceRedirect = false, val) => {
		if (!containerId || !data) return;

		const container = document.getElementById(containerId);
		if (container) {
			const options =
				val === "donut"
					? pieChartOptions(data, isDeviceRedirect ? 'device_redirect' : null)
					: columnChartOptions(data, isDeviceRedirect ? 'device_redirect' : null);

			Highcharts.chart(containerId, options);
		}
	},[columnChartOptions, pieChartOptions])

	const handleLegendData = useCallback((data, setLegendFn) => {
		if (data) {
			const legendLabels = Object.keys(data);
			const legendValues = Object.values(data);
			const updatedLegendData = createLegendData(legendLabels, legendValues);
			setLegendFn(updatedLegendData);
		}
	},[createLegendData])

	const generateChart = useCallback((val, newData, sdwanGraph) => {
		if (!sdwanGraph) {
			const chartDataToUse = newData || statusData;	
			if (!newData) {
				handleLegendData(statusData, setLegendData);
			}	
			renderChart(snakeCase(text), chartDataToUse, false, val);
		} else {
			const sdwanData = newData || props.sdwanDevicestatus;	
			if (!newData) {
				handleLegendData(props.sdwanDevicestatus, setSdwanLegendData);
			}	
			renderChart(props.sdwanDeviceGraphId, sdwanData, true, val);
		}
	}, [statusData, renderChart, text, handleLegendData, props.sdwanDevicestatus, props.sdwanDeviceGraphId]);
	
	
	const checkNoData = useCallback((data) => {
		if (!data || (data && !Object.keys(data).length) || (Object.keys(data).every((k) => Number(data[k]) === 0))) {
			return true
		}
		return false
	}, [])

	const handleLegendClick = useCallback((item, text, sdwanGraph) => {
		const category = item?.name?.toLowerCase();
		const isSdwan = Boolean(sdwanGraph);
	
		const updatedChartData = isSdwan ? { ...sdwanChartData } : { ...chartData };
		const sourceData = isSdwan ? { ...props?.sdwanDevicestatus } : { ...statusData };
		const uLegendData = isSdwan ? [...sdwanLegendData] : [...legendData];
	
		const updatedLegendData = uLegendData?.map((legendItem) => {
			if (legendItem?.name?.toLowerCase() === category) {
				const isActive = !legendItem.isActive;
				legendItem.isActive = isActive;
	
				if (isActive) {
					updatedChartData[category] = sourceData[category];
				} else {
					delete updatedChartData[category];
				}
			}
			return legendItem;
		});
	
		if (isSdwan) {
			setSdwanLegendData(updatedLegendData);
			setsdwanChartData(updatedChartData);
		} else {
			setLegendData(updatedLegendData);
			setChartData(updatedChartData);
		}		
	
		generateChart(chartViewPreference, updatedChartData, isSdwan);
	}, [chartData, chartViewPreference, generateChart, legendData, props?.sdwanDevicestatus, sdwanChartData, sdwanLegendData, statusData]);
	

	const resolution = useMemo(() =>{
		return screenResolution()
	},[])
	


	useEffect(() => {
		if (!isLoading && chartViewPreference && statusData && Object.keys(statusData) && !Object.values(statusData)?.includes(undefined)) {
			generateChart(chartViewPreference);
			setChartData(statusData)		
		} 
		if(text === "SDWAN" && props?.sdwanDevicestatus && Object.keys(props?.sdwanDevicestatus) && !Object.values(props?.sdwanDevicestatus)?.includes(undefined)) {
			generateChart(chartViewPreference, null, true)
			setsdwanChartData(props?.sdwanDevicestatus)
		}	
	}, [statusData, chartViewPreference, isLoading, text]);


	return (
		<Box  sx={{gridColumn: text === "SDWAN" ? "span 2" : "span 1"}} className="dashboard__graph__wrapper">
			<Box className="chart__heading__container">
				<MuiTypography variant="p" className="chart__heading">{text}</MuiTypography>
				<MuiTypography variant="p" className='dashboard__chart__icon__wrapper'>
					{(redirectUrl && user?.tenant?.name === 'Sangoma') &&
						<a className='chart__redirect__url' rel="noreferrer" href={redirectUrl} target='_blank'>
							<ShareIcon style={{ width: "18px", height: "18px" }} />
						</a>
					}
				</MuiTypography>
			</Box>
			{isLoading ?
				<ChartLoader />
				:
				<Box className={`${user?.themePreference === 'dark' ? "dark__mode " : ""} dashboard__chart__graph__card__container  ${text==="SDWAN" ? "sdwan__container" :""}  `}>
					{
						text === 'SDWAN' ?
							!isLoading && checkNoData(statusData) && checkNoData(props?.sdwanDevicestatus) ?
								<ChartNoData noDataText={props?.noDataText} />
								:
								<Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1px 1fr', columnGap: "24px", width: "100%", justifyContent: "space-between" }}>
									<SdwanGraph  title={'Links Status'} isLoading={isLoading} text={text} chartViewPreference={chartViewPreference} statusData={statusData} checkNoData={checkNoData} noDataText={props?.noDataText} legendDetails={legendData} handleLegendClick={(item, text) => handleLegendClick(item, text, false)}/>
										<Box className="divider">
											</Box>
									<SdwanGraph graphId={props?.sdwanDeviceGraphId} title={'Devices Status'} isLoading={isLoading} text={text} chartViewPreference={chartViewPreference} statusData={props?.sdwanDevicestatus} checkNoData={checkNoData} noDataText={props?.noDataText} legendDetails={sdwanLegendData} handleLegendClick={(item, text) => handleLegendClick(item, text, true)}/>
								</Box>

							:
							<NetopsDashboardGraph customWidth={((resolution === "desktop")  && isInventoryGraph && chartViewPreference == 'bar') ? user?.isSidebarOpen ? "280px" : (resolution === "desktop" && !isInventoryGraph && chartViewPreference === "donut" && user?.isSidebarOpen )? "350px" : "300px" :( resolution === "laptop" && user?.isSidebarOpen) ? "250px" :"200px"} isLoading={isLoading} text={text} chartViewPreference={chartViewPreference} noDataText={props?.noDataText} noData={checkNoData(statusData)} legendDetails={legendData} handleLegendClick={handleLegendClick} />
					}
				</Box>
			}


			{props?.footer &&
				<Box className="chart__footer__container" sx={{ borderTop: (text === "SDWAN" && !(checkNoData(statusData) && checkNoData(props?.sdwanDevicestatus))) ? "0px !important" : "" }}>
					<a style={{color: user?.themePreference === 'dark' ? "#fff" :""}} rel="noreferrer" target='_blank'  href={props?.footer}>{`Learn more about this service`}</a>
				</Box>}
		</Box>
	);
};
export default NetopsDashboardChart;
