import Box from "@mui/material/Box";
import FormLayout from "components/common/FormLayout";
import ProgressLoading from "components/common/ProgressLoading";
import Footer from "components/common/modal/Footer";
import Header from "components/common/modal/Header";
import { formTypes } from "constant/Formtypes";
import moment from "moment-timezone";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createSiteBinding, createSiteData, fetchUserTenantsData, updateSiteData } from "store/devices";
import { setLoading } from "store/user";
import {fetchSiteGroupData } from 'store/devices';

const CreateSite = ({
	handleClose,
	isCreateSite,
	isUpdateList,
	editData,
	updateData,
	cloudSitetoMap,
	siteIndex,
	tabName,
	service
}) => {
	const [formValues, setFormValues] = useState({
		velocloud: true,
		meraki: true,
		wug: true,
		cradlepoint: true,
		fortinet: true
	});
	const [parameters, setParameters] = useState([]);
	const [tenantsList, setTenantList] = useState([]);
	const [nameError, setNameError] = useState([]);
	const [isError, setIsError] = useState(false);
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	let timeZoneList = moment.tz.names();
	const isEditData = editData ? Object?.keys(editData)?.length: 0
	const [formTenantData, setFormTenantData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [markError, setMarkError] = useState(false);
	const [siteGroupOptionDetails, setSiteGroupOptionDetails] = useState({
		isLoading: true, 
		options:[],
		isDisabled: true,
		value: null,
	});

	const handleInputChange = async (e, id) => {
		
		if(id == "name" || id == "displayname"){
			const regex = /^[a-zA-Z0-9-_ ]*$/;
			if (regex.test(e.target.value)) {
				setNameError("");
				setIsError(false);
				await setFormValues((prevValue) => ({
					...prevValue,
					[id]: e.target.value,
				}));
			} else {
				setNameError((prevValue) => ({...prevValue, msg: "Only alphanumeric characters, hyphen, underscore, and spaces are allowed.", id: id}));
				setIsError(true);
			}
		} else if (id === "latitude" || id === "longitude") {
			const partialRegex =  id === "latitude"
			? /^-?\d{0,2}(\.\d{0,6})?$/
			: /^-?\d{0,3}(\.\d{0,6})?$/;
			const finalRegex = id === "latitude"
			? /^-?\d{1,2}(\.\d{1,6})?$/
			: /^-?\d{1,3}(\.\d{1,6})?$/;
			
			if (partialRegex.test(e.target.value.trim())) {
				setNameError("");
				setIsError(false);
				await setFormValues((prevValue) => ({
					...prevValue,
					[id]: e.target.value.trim(),
				}));
			} else if (e.target.value.trim() === "") {
				await setFormValues((prevValue) => ({
					...prevValue,
					[id]: "",
				}));
			} else {
				if (!finalRegex.test(e.target.value.trim())) {
					setNameError({ msg: `${id} coordinate in decimal format (xx.yyyyyy)`, id });
					setIsError(true);
				}
			}
		} else {
			await setFormValues((prevValue) => ({
				...prevValue,
				[id]: e.target.value,
			}));
		}
		
	};

	const handleOnfocus = (e, id) => {
		if (formValues?.name && id === "slug") {
			setFormValues((prevValue) => ({
				...prevValue,
				[id]: formValues?.name?.toLowerCase(),
			}));
		}
	};

	const handleSelectChange = async (data, id) => {
		if (id === "tenant") {
			setFormTenantData(data);
			const siteGroup = await fetchSitesGroupList(data?.value);
			const newParameter = parameters;
			let params = newParameter?.find(i => i.identifier === 'group');
			params.optionList = siteGroup

			setParameters(newParameter);

		}
		if (id === "tenant" ) {
			await setFormValues((prevValues) => ({
				...prevValues,
				[id]: data ? data.value : "",
				group: null
			}));
		}  else if( id === "time_zone" ) {
			await setFormValues((prevValues) => ({
				...prevValues,
				[id]: data ? data.value : "",
			}));
		}
		else if(id === "group"){
			setFormValues((prevValues) => ({
				...prevValues,
				[id]: data,
			}));
		}
		else {
			await setFormValues((prevValues) => ({
				...prevValues,
				[data.target?.name]: data.target.value,
			}));
		}
	};

	
	const fetchSitesGroupList = useCallback(async (selectedTenantId) => {
				setSiteGroupOptionDetails(prevState => ({ ...prevState, isLoading: true }));
				try {
					const tenantId = selectedTenantId
						? selectedTenantId
						: user?.tenant?.name === 'Sangoma'
							? editData?.tenant?.id
							: user?.tenant?.id;
	
	
					if (tenantId) {
						const request = {tenantId};
						const res = await dispatch(fetchSiteGroupData(request)).unwrap();
						
						let newResults = res?.data?.map(i => ({
							value: i.id,
							label: i.custom_fields?.display_name ? i.custom_fields.display_name : i.name
						}));

						setSiteGroupOptionDetails(prevState => {
							return {
								...prevState,
								options: newResults,
								
							}
						});
						
						return newResults;

					}
				} catch (error) {
					console.error("Error fetching sites data:", error);
				} finally {
					setSiteGroupOptionDetails(prevState => ({ ...prevState, isLoading: false }));
				}

			},
			[dispatch, editData?.tenant?.id, user?.tenant?.id, user?.tenant?.name]
		);

	const handleSelectStatus = (data) => {
		setFormValues((prevValues) => ({
			...prevValues,
			status: data,
		}));
	};

	const getTenantList = async () => {
		return await dispatch(fetchUserTenantsData(user));
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const createParameters = async (dropdownList) => {
		const siteGroup = await fetchSitesGroupList();

		let dropdownOption = dropdownList;
		for (let i = 0; i < dropdownOption?.length; i++) {
		
			let params = dropdownOption[i];
			if (isEditData &&
				params?.identifier == 'name' &&
				user?.tenant?.name !== 'Sangoma') {
				params.isDisable = true;
			} else {
				params.isDisable = false;
			}
			if (params?.parameterType === "ListSingle") {
				if (params?.identifier === "time_zone") {
					params.optionList = timeZoneList;
				}
			}
			if (params?.parameterType === "Autocomplete") {
				if (params?.identifier === "tenant") {
					let tenantsData = await getTenantList();
					tenantsData = tenantsData?.payload?.filter(data => data?.name !== "Sangoma" );
					await setTenantList(tenantsData);
					params.optionList = tenantsData;
				}
			}
			if(params?.identifier === "group"){
				params.optionList = siteGroup
				params.isLoading = siteGroupOptionDetails?.isLoading;
				params.validationRules = user?.site_group_ids?.length > 0 && [ 'required']
			}
		}

	
		setIsLoading(false);
		setParameters(dropdownOption);
	};

	const closeSite = async () => {
		handleClose();
		setFormValues([]);
		await dispatch(setLoading(false))
	};

	useEffect(() => {
		getFormData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	// eslint-disable-next-line no-unused-vars
	const getFormData = useCallback(async() => 
	{
		if (editData) {
			if (Object?.keys(editData)?.length) {
				let data = {
					...editData,
					velocloud: editData.custom_fields.velocloud ? editData.custom_fields.velocloud : false,
					meraki: editData.custom_fields.meraki ? editData.custom_fields.meraki : false,
					wug: editData.custom_fields.wug ? editData.custom_fields.wug : false,
					cradlepoint: editData.custom_fields.cradlepoint ? editData.custom_fields.cradlepoint : false,
					fortinet: editData.custom_fields.fortinet ? editData.custom_fields.fortinet : false,
					displayname : editData.custom_fields.display_name? editData.custom_fields.display_name : editData.name,
					group:editData?.group ? {value: editData?.group?.id, label: editData?.group?.display} : null
				};
				setFormValues(data);
			} else {
				setFormValues({
					velocloud: true,
					meraki: true,
					wug: true,
					cradlepoint: true,
					fortinet: true
				});
			}
		}

		if (cloudSitetoMap?.name && siteIndex === 2) {
			setFormValues((prevValue) => ({
				...prevValue,
				remote_site: cloudSitetoMap,
			}));
		}

		if (formTypes()?.length && isCreateSite) {
			setIsLoading(true);
			let fieldList = formTypes()?.find((obj) => obj?.category === "site");
			let params = fieldList?.formParameters?.filter((para) =>
				user?.tenant?.name !== "Sangoma" ? para?.identifier !== "tenant" : para
			);
			let filterAddSiteParams = params?.filter((param) =>
				editData && !Object?.keys(editData)?.length
					? param?.identifier !== "status" &&
					param?.identifier !== "time_zone" &&
					param?.identifier !== "longitude" &&
					param?.identifier !== "latitude"
					: param
			);
			let FilterCloudSiteField = filterAddSiteParams?.filter((param) =>
				siteIndex !== 2 ? param?.identifier !== "remote_site" : param
			);
			createParameters(FilterCloudSiteField);
		}
	},
 [fetchSitesGroupList, editData, cloudSitetoMap, siteIndex, isCreateSite, createParameters, user?.tenant?.name])

	const handleSubmit = async (e) => {
		e.preventDefault();
		await dispatch(setLoading(true))
		var res = undefined;
		if (Object?.keys(formValues)?.length) {
			const status = formValues.status ? formValues?.status?.value : "";
			const group = formValues.group ? formValues?.group?.value : null;
			let isTenantObj = formValues?.tenant ? Object?.keys(formValues?.tenant)?.length : {};
			const tenant =
				user?.tenant?.name === "Sangoma"
					? isTenantObj
						? formValues?.tenant?.id
						: formValues?.tenant
					: user?.tenant?.id;
			let { remote_site, ...otherFormValues } = formValues;
			let newValues = { ...otherFormValues, status, tenant, group };

			const isGroupRequired = user?.site_group_ids?.length ? formValues?.group : true;
			const hasValidGroup = user?.site_group_ids?.length ? formValues?.group?.value : true;
			
			if (formValues?.id && formValues?.name && formValues?.status && formValues?.tenant && isGroupRequired && hasValidGroup) {
				res = await dispatch(updateSiteData({ id: formValues?.id, params: newValues }));
			} else if (formValues?.name && newValues?.tenant &&  isGroupRequired && hasValidGroup) {
				res = await dispatch(createSiteData(newValues));
			}else {
                await dispatch(setLoading(false));
				setMarkError(true);
				setTimeout(() => {
					setMarkError(false);
				}, 5000);
                toast.error("Please fill all the required details");
				
            }
			if (res?.payload && cloudSitetoMap?.name && siteIndex === 2) {
				let isVIPTenant = tenantsList.filter(tnt => tnt.id === tenant);
				isVIPTenant = isVIPTenant && isVIPTenant.length ? isVIPTenant[0].custom_fields?.marked_vip : isVIPTenant.custom_fields?.marked_vip;
				let obj = [{
					netbox_site: {
						id: res?.payload?.[0]?.id,
						tenant_id: tenant,
						tenant_name: formTenantData?.label ? formTenantData?.label : user?.tenant?.name,
						is_vip:isVIPTenant
					},
					remote_site: service === "wug" ? [remote_site] : remote_site
				}];


				let mapsiteRes = await dispatch(createSiteBinding({ data: obj, service: service }));
				if (mapsiteRes?.payload) {
					toast.success(`Site Created and Mapped Successfully`);
					await dispatch(setLoading(false))
					closeSite();
					await updateData();
				}
			}
			if (res?.payload && !cloudSitetoMap?.name && siteIndex !== 2) {
				toast.success(`Site ${formValues?.id ? "updated" : "added"} successfully`);
				await dispatch(setLoading(false))
				closeSite();
				if (isUpdateList) {
					await updateData();
				}
			}
		} else {
			await dispatch(setLoading(false))
			setMarkError(true);
			setTimeout(() => {
				setMarkError(false);
			}, 5000);
			toast.error("Please fill all the required details");
		}
		await dispatch(setLoading(false))
	};

	const handleCheckboxChange = async (e, identifier) => {
		await setFormValues((prevValue) => ({
			...prevValue,
			[identifier]: e.target.checked ? true : false,
		}));
	}

	return (
		<div className="create__site__popup__wrap">
			<Header
				text={isEditData ? "Update Site" : "Create Site"}
				close={closeSite}
			/>{isLoading ? <div><ProgressLoading /> </div>:
			<Box className="formWrapper site_formWrap">
				<FormLayout
					parameters={parameters}
					formValues={formValues}
					handleInputChange={handleInputChange}
					handleSelectChange={handleSelectChange}
					handleSelectStatus={handleSelectStatus}
					handleCheckboxChange={handleCheckboxChange}
					handleOnfocus={handleOnfocus}
					cloudSitetoMap={cloudSitetoMap?.name?cloudSitetoMap?.name:cloudSitetoMap?.sdisplayname}
					tabName={tabName}
					nameErrorMsg = {nameError}
					isError = {isError}
					markError={markError}
					singleSelectLoading={siteGroupOptionDetails?.isLoading}
				/>
			</Box>
			}
			<Footer isLoading={user?.isLoading} text={"Save"} submit={handleSubmit} />
		</div>
	);
};

export default CreateSite;
