import React, {useRef, useState} from 'react'
import Select from "react-select";
import { useSelector } from 'react-redux';
import {ClearIndicator, Control, customStyle, darkThemeReactSelectStyles, InputOption, Menu, Value } from './selectDropdownUtils'
import TooltipLabel from './TooltipLabel/TooltipLabel';


export default function MultiSelectDropDown({onChangeValue, value, options, placeholder, containerWidth, styles, className,onApplyClick, disableApply, hideFilterIcon, menuPlacement,highlightFilter,disabled,  ...props}) {
    const valueRef = useRef(value);
    valueRef.current = value;
	const user = useSelector((state) => state.user);
	const [searchText, setSearchText] = useState('')

	const isSelectAllSelected = () => valueRef.current.length === options.length;

	const formatOptionLabel = (option) => (
		<TooltipLabel  text={option?.label} />
	);
    const onChange = (newValue, actionMeta) => {
		const cloneSearchText = searchText;

		if(actionMeta === 'remove_all' || actionMeta === 'default_filter'){
			onChangeValue(newValue, "clear");
			setSearchText(cloneSearchText);
			return;
		}
		const { action, option, removedValue } = actionMeta;
		if (action === "select-option" && option.value === 'all') {
			onChangeValue(options, action);
		} else if ((action === "deselect-option" && option.value === 'all') || (action === "remove-value" && removedValue.value === 'all')) {
			onChangeValue([], action); 
		} else if (actionMeta.action === "deselect-option" && isSelectAllSelected()) {
			onChangeValue(newValue.filter(({ value }) => value !== 'all'), action);
		} else if (actionMeta.action === "select-option" && newValue.length === options.filter(i => i.value !== 'all').length){
			onChangeValue(options, action);
		}
		else if(actionMeta.action === "clear"){
			// onChangeValue(options, action);
		}
		else {
			onChangeValue(newValue, action);
		}
		setSearchText(cloneSearchText);
	};
  const selectStyle = user?.themePreference === 'dark' ? darkThemeReactSelectStyles(containerWidth) : customStyle(containerWidth);

  return (
    <Select 
    styles={{...selectStyle, ...styles}}
    onChange={onChange}
    value={value}
	onApplyClick = {onApplyClick}
	disableApply = {disableApply}
    isMulti
	inputValue = {searchText}
	onInputChange = {val => setSearchText(val)}
	formatOptionLabel = {formatOptionLabel}
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    options={options}
	defaultFilter = {props?.defaultFilter}
    placeholder={placeholder || '-Select-'}
	classNamePrefix={`${user?.themePreference === 'dark'? "select-dropdown react__select__dark__mode":"select-dropdown"}  `}
    components={{ 
        Control, 
        MultiValue : Value,
        Option: InputOption,
		Menu: Menu,
		ClearIndicator : ClearIndicator
    }}
    className={`service_filter ${className} ${highlightFilter ? ' highlighted__filter':''}`}
	hideFilterIcon = {hideFilterIcon}
	menuPlacement = {menuPlacement || 'auto'}
	isLoading = {props?.isLoading}
	isDisabled={disabled}
/>
  )
}


