// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket_alert_wrap{
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 306px);
    margin: 10px 0px;
    padding: 25px;
}

`, "",{"version":3,"sources":["webpack://./src/components/component/ticketAlert/ticketAlert.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,+BAA+B;IAC/B,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":[".ticket_alert_wrap{\n    height: 100%;\n    width: 100%;\n    max-height: calc(100vh - 306px);\n    margin: 10px 0px;\n    padding: 25px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
