import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import axios from "services/Axios";

let base_url = process.env.REACT_APP_BASE_URL;


export const fetchAllTickets = createAsyncThunk(
	"tickets/fetchAllTickets",
	async (data, { rejectWithValue }) => {
		let url = `${base_url}/tickets`
		if (data?.tenantId !== "sangoma") {
			url += `/${data?.tenantId}`
		}
		try {
			const response = await axios.get(url, { params: data?.params });
			return {response: response.data, params: data?.params};
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const saveTicketAlert = createAsyncThunk("tickets/saveTicketAlert",
	async (data, { rejectWithValue }) => {
	let url = `${base_url}/tickets/save-ticket-alert`;			
	try {
		const response = await axios.post(url, data);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}

})

export const getTicketAlert = createAsyncThunk("tickets/getTicketAlert",
	async (data, { rejectWithValue }) => {
		let url = `${base_url}/tickets/fetch-ticket-alert`
		try {
			const response = await axios.get(url, { params: data });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}

	})


export const fetchTicketById = createAsyncThunk("tickets/fetchTicketById",
	async (data, { rejectWithValue }) => {
		let url = `${base_url}/tickets/getData/${data?.ticketId}`
		try {
			const response = await axios.get(url, { params: data?.params });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}

	})


export const addTicketComments = createAsyncThunk("tickets/addTicketComments",
	async (data, { rejectWithValue }) => {
		let url = `${base_url}/tickets/addComment`
		try {
			const response = await axios.put(url, data,  {
        headers: {
            'Content-Type': 'application/json',
        }
    });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}

	})


export const updateTicketComments = createAsyncThunk("tickets/updateTicketComments",
	async (data, { rejectWithValue }) => {
		let url = `${base_url}/tickets/updateComment`
		try {
			const response = await axios.put(url, data,  {
        headers: {
            'Content-Type': 'application/json',
        }
    });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}

	})

export const fetchTicketingSystems = createAsyncThunk("tickets/fetchTicketingSystems",
	async ( data, { rejectWithValue }) => {

		let url = `${base_url}/tickets/ticketing-systems`
		try {
			const response = await axios.get(url, {params: data});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}

	})


export const fetchStatusReferenceData = createAsyncThunk("tickets/fetchStatusReferenceData",
	async (data, { rejectWithValue }) => {
		let url = `${base_url}/tickets/status/all`
		try {
			const response = await axios.get(url, {params: data});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	})

export const fetchAllSalesForceTicketEmails = createAsyncThunk("tickets/fetchAllSalesForceTicketEmails",
	async (data, { rejectWithValue }) => {
		let url = `${base_url}/tickets/emails/byTicketId/`
		try {
			const response = await axios.get(`${url}${data?.ticketId}`, { params: {id: data?.ticketId} });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	})

export const fetchSalesForceTicketEmailDataById = createAsyncThunk("tickets/fetchSalesForceTicketEmailDataById",
	async (data, { rejectWithValue }) => {
		let url = `${base_url}/tickets/emails/data/`
		try {
			const response = await axios.get(`${url}${data?.emailId}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	})

export const fetchDashboardTicketStatusData = createAsyncThunk("tickets/fetchDashboardTicketStatusData",
		async (data, { rejectWithValue }) => {
			let url = `${base_url}/tickets/dashboard/status`
			try {
				const response = await axios.get(url, { params: data });
				return response?.data;
			} catch (error) {
				return rejectWithValue(error.response.data);
			}
		})

export const fetchServiceNowConfigurationDetails = createAsyncThunk("tickets/fetchServiceNowConfigurationDetails",
			async (data, { rejectWithValue }) => {
				let url = `${base_url}/tickets/servicenow/config`
				try {
					const response = await axios.get(url, { params: data });
					return response?.data;
				} catch (error) {
					return rejectWithValue(error.response.data);
				}
			})

export const updateServiceNowConfigurationDetails = createAsyncThunk("tickets/updateServiceNowConfigurationDetails",
				async (data, { rejectWithValue }) => {
					let url = `${base_url}/tickets/servicenow/config/update`;
					try {
						const response = await axios.put(url, data);
						return response.data;
					} catch (error) {
						return rejectWithValue(error.response.data);
					}
			
		})

export const addServiceNowConfigurationDetails = createAsyncThunk("tickets/addServiceNowConfigurationDetails",
			async (data, { rejectWithValue }) => {
			let url = `${base_url}/tickets/servicenow/config/add`;			
			try {
				const response = await axios.post(url, data);
				return response.data;
			} catch (error) {
				return rejectWithValue(error.response.data);
			}
		
	})


export const fetchServiceNowAssignmentGroups = createAsyncThunk("tickets/fetchServiceNowAssignmentGroups",
	async (data, { rejectWithValue }) => {		
		let url = `${base_url}/tickets/servicenow/assignmentGroups/${data?.tenant_id}`
		try {
			const response = await axios.get(url, {params: data});
			return response?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	})


export const setServiceNowAssignmentGroups = createAsyncThunk("tickets/setServiceNowAssignmentGroups",
	async (data, { rejectWithValue }) => {
		let url = `${base_url}/tickets/servicenow/setAssignmentGroup/${data?.tenantId}`;
		try {
			const response = await axios.post(url, data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}

	})


export const TicketsSlice = createSlice({
	name: "tickets",
	initialState: {
		allTickets: [],
		totalTicketCount: 0,
		ticketStatus:null,
		loading: true,
		error: null,
		ticketingSystems: [],
		ticketDetails: null,
		ticketEmails: null
	},
	reducers: {
		resetTickets: (state) => {
			state.allTickets = [];
			state.totalTicketCount = 0;
			state.ticketStatus=null,
			state.loading = true;
			state.error = null;
			state.ticketingSystems =[];
		},
		setTicketDetails: (state, action) => {
			state.ticketDetails = action.payload
		},

	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllTickets.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchAllTickets.fulfilled, (state, action) => {
				state.loading = false;
				state.allTickets = action.payload.response.tickets;
				if(action.payload.params.searchText === null || action.payload.params.searchText === ''){
					state.ticketStatus = action.payload.response.ticketStatus?.reduce((acc, item) => {
						if (item?.status) {
							acc[item.status.toLowerCase()] = {
								total_count: item.total_count,
								percentage: item.percentage
							};
						}
						return acc;
					}, {}) || {};
				}
				
				state.totalTicketCount = Number(action.payload.response.totalTicketCount);
			})
			.addCase(fetchAllTickets.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
				state.allTickets = [];
				state.totalTicketCount = 0;
				state.ticketStatus=null
			}).addCase(fetchTicketingSystems.fulfilled, (state, action) => {
				state.ticketingSystems = action.payload;
			});

		builder.addCase(PURGE, () => {
			return TicketsSlice.getInitialState();
		});
	}
});

export const { resetTickets, setTicketDetails } = TicketsSlice.actions;

export default TicketsSlice.reducer;