import React, { useCallback, useEffect, useRef, useState } from 'react'
import Select from "react-select";
import { useSelector } from 'react-redux';
import { Control, customStyle, darkThemeReactSelectStyles } from './selectDropdownUtils'
import TooltipLabel from './TooltipLabel/TooltipLabel';

export default function AsyncSelectDropDown({ onChangeValue, value, defaultOptions, placeholder, containerWidth, styles, className,highlightFilter,asyncClassNamePrefix,loadOptions,onMenuScrollToBottom, ...props }) {
    const valueRef = useRef(value);
    valueRef.current = value;
    const user = useSelector((state) => state.user);
    const [searchText, setSearchText] = useState('')
    const [options, setOptions] = useState(defaultOptions);


    const selectStyle = user?.themePreference === 'dark' ? darkThemeReactSelectStyles(containerWidth) : customStyle(containerWidth, props?.backgroundColor);

    const formatOptionLabel = (option) => (
        <TooltipLabel tooltipPlacement={"top"} text={option?.label} />
    );
    
    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };

    const fetchOptions = useCallback(async (input) => {
        if (loadOptions) {
            try {
                const newOptions = await loadOptions(input);                
                setOptions(newOptions);
            } catch (error) {
                console.error("Error fetching options:", error);
            }
        }
    }, [loadOptions]);

    const debouncedFetchOptions = useCallback(debounce(fetchOptions, 300), [fetchOptions]);

    const onInputChange = (inputValue, event) => {
        if (event.action === 'input-change') {
            setSearchText(inputValue);
            debouncedFetchOptions(inputValue);
        }
    };

    useEffect(() => {
        setOptions(defaultOptions)
    }, [defaultOptions])
    

    return (
        <Select
            styles={{ ...selectStyle, ...styles }}
            onChange={val => onChangeValue(val)}
            value={value}
            isClearable={props?.isClearable}
            isDisabled = {props?.isDisabled}
            inputValue={searchText}
            onInputChange={onInputChange}
            formatOptionLabel={formatOptionLabel}
            closeMenuOnSelect={true}
            hideSelectedOptions={true}
            options={options}            
            placeholder={placeholder || '-Select-'}
            onMenuScrollToBottom={onMenuScrollToBottom}
            classNamePrefix={`${asyncClassNamePrefix} ${user?.themePreference === 'dark' ? "select-dropdown react__select__dark__mode" : "select-dropdown"} ${props?.isRequired ? "required " : "" } `}
            components={{Control}}
            menuPortalTarget={document.body}
            className={`service_filter ${className} ${highlightFilter ? ' highlighted__filter' : ''}`}
            hideFilterIcon={true}
            menuPlacement={'auto'}
            isLoading={props?.isLoading}

        />
    )
}


